.card {
    position: relative;
    z-index: 10;
    padding: 0;
    max-width: 100%;
    overflow: hidden;

    &.featured {
        background-color: $bisque;
    }

    .card-img-wrap {
        &.image-type-normal {
            transition: all 500ms ease;
            img {
                transition: transform 500ms;
            }
        }
        .card-img-top {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(255, 255, 255, 0.2);
            opacity: 0;
            transition: opacity 500ms;
        }
        img {
            width: 100%;
        }
    }

    &.card-layout-wide {
        flex-direction: row;
        @include media-breakpoint-down(lg) {
            flex-direction: column;
        }
        .card-img-wrap {
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
        }

        .card-body.highlighted {
            @include media-breakpoint-up(md) {
                padding-left: 3rem;
                margin-left: 0 !important;
            }
            background: linear-gradient(
                180deg,
                #9090e8 -40.09%,
                #5644d8 89.63%
            );
            .card-text,
            .card-title,
            .btn,
            .small {
                color: #fff !important;
            }
        }
    }

    &.linkable:hover {
        .card-img-wrap {
            img {
                transform: scale(1.05);
            }
            &:after {
                opacity: 1;
            }
        }
    }
}

.logo-card {
    position: relative;
    z-index: 1;
    .card-title {
        position: relative;
        display: inline;
        .underline {
            position: absolute;
            bottom: -13px;
            left: 50%;
            overflow: hidden;
            width: calc(100%);
            height: 4px;
            transform: translate(-50%, 0);
            svg {
                position: absolute;
                top: 50%;
                left: 0px;
                height: 4px;
                width: auto;
                transform: translate(0, -50%);
                // path {
                //     stroke-dasharray: 1294;
                //     stroke-dashoffset: 1294;
                //     transition: stroke-dashoffset 300ms ease;
                // }
            }
        }
    }
    .card-logo {
        height: 200px;
        @include media-breakpoint-down(md) {
            height: 80px;
        }
        .card-img {
            max-height: 80%;
            width: auto;
            @include media-breakpoint-down(md) {
                min-height: 100%;
            }
        }
    }
    .btn {
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 10;
        padding-right: 0 !important;
    }
    .hover-overlay {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-color: $purple;
        color: $white;
        opacity: 0;
        transition: 500ms all ease;
        z-index: 9;
    }
    .stretched-link {
        z-index: 10;
    }
    @include media-breakpoint-up(md) {
        &:hover {
            .btn {
                color: $white !important;
            }
            .hover-overlay {
                opacity: 1;
            }
        }
    }
}

.integration-card {
    position: relative;
    .card-category {
        position: absolute;
        top: 30px;
        right: 30px;
        .underline {
            position: absolute;
            bottom: -13px;
            left: 50%;
            overflow: hidden;
            width: calc(100%);
            height: 4px;
            transform: translate(-50%, 0);
            svg {
                position: absolute;
                top: 50%;
                left: 0px;
                height: 4px;
                width: auto;
                transform: translate(0, -50%);
                // path {
                //     stroke-dasharray: 1294;
                //     stroke-dashoffset: 1294;
                //     transition: stroke-dashoffset 300ms ease;
                // }
            }
        }
    }
    .card-logo {
        height: 150px;
        @include media-breakpoint-down(md) {
            height: 80px;
        }
        .card-img {
            max-height: 80%;
            width: auto;
            @include media-breakpoint-down(md) {
                min-height: 100%;
            }
        }
    }

    .btn {
        padding-left: 0px !important;
        transition: 0ms;
    }
    @include media-breakpoint-up(md) {
        &:hover {
            background-color: $purple;
            color: $white;
            .btn {
                .text {
                    color: $white;
                }
                svg {
                    path {
                        stroke: $white;
                    }
                }
            }
            .card-title {
                color: $white !important;
            }
        }
    }
}

.pricing-card {
    box-shadow: 0px 0px 24px rgba(200, 200, 200, 0.5);
    .features {
        .icon {
            width: 24px;
            height: 24px;
            svg {
                width: 24px;
                height: 24px;
            }
        }
    }
}
