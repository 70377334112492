section.block-three-col-icon {
    .icon {
        svg {
            height: 100%;
            width: auto;
        }
    }

    .card-icon-holder {
        width: 70px;
        height: 70px;
        border-radius: 35px;
        margin: auto;
    
        .icon, .icon svg {
            width: 32px;
            height: 32px;
        }
    }
}
