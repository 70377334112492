.section {
    position: relative;
    padding: 90px 50px 70px;
    @media screen and (max-width: 991px) {
        padding: 60px 35px;
    }
    @media screen and (max-width: 767px) {
        padding: 40px 30px;
    }
    @media screen and (max-width: 479px) {
        padding: 40px 25px;
    }
    &.s-footer {
        padding-top: 90px;
        padding-bottom: 50px;
        background-color: $black;
        color: $white;
        @media screen and (max-width: 991px) {
            padding-top: 40px;
            padding-bottom: 40px;
        }
        @media screen and (max-width: 479px) {
            padding-bottom: 25px;
        }
    }
    &.s-lilac-f0f0ff {
        background-color: #f0f0ff;
        @media screen and (max-width: 767px) {
        }
        &.intelligent-call {
            padding-top: 140px;
            padding-bottom: 170px;
            @media screen and (max-width: 991px) {
                padding-top: 154px;
                padding-bottom: 80px;
            }
            @media screen and (max-width: 767px) {
                padding-top: 150px;
            }
            @media screen and (max-width: 479px) {
                padding-top: 174px;
                padding-bottom: 47px;
            }
        }
        &.s-discover {
            margin-bottom: 143px;
            padding-top: 1px;
            padding-bottom: 0;
            @media screen and (max-width: 991px) {
                margin-bottom: 30px;
                padding-top: 60px;
                padding-bottom: 60px;
            }
        }
        &.s-other-features {
            padding-top: 120px;
            padding-bottom: 180px;
            @media screen and (max-width: 991px) {
                padding-top: 80px;
                padding-bottom: 80px;
            }
            @media screen and (max-width: 767px) {
                padding-top: 60px;
                padding-bottom: 60px;
            }
            @media screen and (max-width: 479px) {
                padding-top: 50px;
                padding-bottom: 50px;
            }
        }
        &.pricing-ratings {
            padding-top: 260px;
            padding-bottom: 260px;
            @media screen and (max-width: 991px) {
                padding-top: 80px;
                padding-bottom: 80px;
            }
            @media screen and (max-width: 479px) {
                padding-top: 48px;
                padding-bottom: 48px;
            }
            @media screen and (min-width: 992px) {
                padding-top: 90px;
                padding-bottom: 90px;
            }
        }
        &.s-posts-quotes {
            padding-top: 130px;
            padding-bottom: 130px;
            @media screen and (max-width: 991px) {
                padding-top: 60px;
                padding-bottom: 60px;
            }
            @media screen and (max-width: 479px) {
                padding-top: 40px;
                padding-bottom: 40px;
            }
        }
    }
    &.s-purple-5644d8 {
        background-color: #5644d8;
        color: $white;
        @media screen and (max-width: 991px) {
            padding-bottom: 80px;
        }
        @media screen and (max-width: 479px) {
            padding-bottom: 45px;
        }
        &.conversations {
            padding-top: 120px;
            padding-bottom: 120px;
            @media screen and (max-width: 991px) {
                padding-top: 80px;
                padding-bottom: 40px;
            }
            @media screen and (max-width: 767px) {
                padding-bottom: 60px;
            }
            @media screen and (max-width: 479px) {
                padding-top: 50px;
            }
            &.bg-unset .section-h2-90 {
                color: $black;
                font-size: 34px;
            }
        }
        &.s-story-quotes {
            padding-top: 160px;
            padding-bottom: 160px;
            @media screen and (max-width: 991px) {
                padding-top: 80px;
                padding-bottom: 80px;
            }
            @media screen and (max-width: 479px) {
                padding-top: 40px;
                padding-bottom: 40px;
            }
        }
    }
    &.s-demo {
        padding: 0;
        @media screen and (max-width: 767px) {
            padding-top: 10px;
        }
    }
    &.s-why-dixa-s2 {
        @media screen and (max-width: 767px) {
            padding-top: 140px;
        }
        @media screen and (max-width: 479px) {
            padding-top: 70px;
        }
    }
    &.s-left-content {
        padding-top: 30px;
        @media screen and (max-width: 479px) {
            padding-top: 10px;
        }
        &.top-row {
            margin-top: -50px;
            padding-top: 0;
            @media screen and (max-width: 991px) {
                margin-top: 0;
            }
        }
        &.last {
            padding-bottom: 30px;
        }
    }
}
