.sub-footer {
    border-top: 1px solid rgba(255,255,255,.15);
    .sub-menu {
        line-height: 2;
    }
    .sub-footer-left {
        min-width: 120px;
        max-width: 160px;
    }
}

#site-footer {
    h4, h5, .h4, .h5 {
        opacity: .5;
    }

    .container-fluid {
        max-width: 2000px;
    }
}