$white-filter: invert(100%) sepia(95%) saturate(21%) hue-rotate(285deg) brightness(106%) contrast(106%);
$black-filter: invert(0%) sepia(99%) saturate(0%) hue-rotate(285deg) brightness(0%) contrast(106%);

.customer-quote {
    margin-top: 40px;
    margin-bottom: 40px;
    
    figure {
        border-radius: 2rem;
        //background: $gradient-blue-white;
        //color: $midnight;
        padding: 90px 30px 50px;

        &.bg- {
            background: $gradient-blue-white!important;
            color: $midnight!important;
        }

        @include media-breakpoint-up(lg){
            padding: 120px 80px 80px;
        }

        blockquote {
            position: relative;
            padding: 0;
            border: none;

            svg {
                display: block;
                width: 40px;
                position: absolute;
                top: -50px;
                left: 0;
            }
        }

        figcaption {

            span {
                display: block;
            }
        }
    }

}