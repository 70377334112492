.s-image-block {
    .p-16.subtitle {
        @media screen and (max-width: 1279px) and (min-width: 992px) {
            max-width: 330px;
            margin-left: auto;
            margin-right: auto;
        }
        @media screen and (max-width: 991px) and (min-width: 767px) {
            max-width: 330px;
            margin-left: auto;
            margin-right: auto;
        }
        @media screen and (max-width: 772px) {
            max-width: 230px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}