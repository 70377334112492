// H1
h1 {
    &.large {
        font-size: 5rem;
        @include media-breakpoint-down(md) {
            font-size: calc(1.525rem + 3.3vw);
        }
    }
}
.h1 {
    @extend h1;
}

// H2
h2 {
}
.h2 {
    @extend h2;
}

// H3
h3 {
}
.h3 {
    @extend h3;
}

// H4
h4 {
}
.h4 {
    @extend h4;
}

// H5
h5 {
}
.h5 {
    @extend h5;
}

// H6
h6 {
}
.h6 {
    @extend h6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    word-wrap: break-word;
    margin-block-end: 1rem;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3 {
    line-height: 125% !important;
}

.wp-block-column {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 3rem;
        margin-block-end: 1rem;
        @include media-breakpoint-down(md) {
            margin-top: 1rem;
        }
    }

    :first-child {
        margin-top: 0;
    }
    ol.ol-list,
    ol.wp-block-list,
    ol.wp-block-list ol {
        counter-reset: section;
        list-style-type: none;
        padding-left: 35px;
        @include media-breakpoint-down(md) {
            padding-left: 25px;
        }
        li {
            position: relative;
            padding-left: 0;
            line-height: 2;
        }
        li:before {
            position: absolute;
            top: 0;
            left: -70px;
            text-align: right;
            counter-increment: section;
            content: counters(section, ".");
            width: 60px;
            font-weight: bold;
        }
        li::marker {
            display: none;
        }
    }
    & > ol.ol-list,
    ol.wp-block-list {
        padding-left: 15px;
    }
}

p {
    line-height: 2rem;
}

// Fix for Gutenberg new use of Logical CSS margin
// https://github.com/WordPress/gutenberg/pull/38816
div[class*="wp-container"] > p {
    margin-block-end: 1rem;
}

.font-md-p {
    @include media-breakpoint-down(md) {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        font-family: $font-family-base;
    }
}

blockquote {
    margin-bottom: 10px;
    padding: 10px 20px;
    border-left: 5px solid #e2e2e2;
    font-size: 16px;
    line-height: 1.3;
}

.badge {
    font-size: 0.875em !important;
}

.is-black {
    font-weight: 900!important;
}

.semi-bold {
    font-weight: 600;
}
