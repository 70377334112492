$pagination-border-width: 0px;

.pagination {
    li {
        a,
        span {
            color: $black;
            font-weight: bold;
            &.current {
                color: $purple;
            }
            &.prev {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }
}
