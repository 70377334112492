.featured-customer {
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: $midnight;
    color: $white;

    .inner {
        padding: 60px 32px 32px;

        @include media-breakpoint-up(lg){
            padding: 120px 80px 80px;
        }
    }

    figure {

        blockquote {
            position: relative;
            padding: 0;
            border: none;

            svg {
                display: block;
                width: 40px;
                position: absolute;
                top: -50px;
                left: 0;
            }
        }

        figcaption {
            span {
                display: block;
            }
        }
    }

    .logo {
        filter: grayscale(0%) invert(100%) brightness(2);
        display: block;
        margin: 0 auto;
        max-width: 100%;
    }
}