@import "~bootstrap/scss/utilities";

$utilities: map-merge(
    $utilities,
    (
        "rounded":
            map-merge(
                map-get($utilities, "rounded"),
                (
                    values:
                        map-merge(
                            map-get(map-get($utilities, "rounded"), "values"),
                            (
                                single: 1rem,
                                double: 2rem,
                            )
                        ),
                )
            ),
        "width":
            map-merge(
                map-get($utilities, "width"),
                (
                    values:
                        map-merge(
                            map-get(map-get($utilities, "width"), "values"),
                            (
                                10: 10%,
                                20: 20%,
                                30: 30%,
                                40: 40%,
                                60: 60%,
                                70: 70%,
                                80: 80%,
                                90: 90%,
                            )
                        ),
                )
            ),
    )
);

@import "~bootstrap/scss/utilities/api";

@each $size, $value in $spacers {
    .size-#{$size} {
        width: $value !important;
        height: $value !important;
    }
    .size-#{$size}-w-svg {
        svg {
            width: $value !important;
            height: $value !important;
        }
    }
    .size-#{$size}-auto {
        width: $value !important;
        height: auto !important;
    }
    .size-#{$size}-w-svg-auto {
        svg {
            width: $value !important;
            height: auto !important;
        }
    }
}

@each $size, $value in $border-widths {
    .border-top-#{$size} {
        border-top-width: $value !important;
    }
    .border-bottom-#{$size} {
        border-top-width: $value !important;
    }
}

@each $size, $value in $grid-breakpoints {
    .w-#{$size}-auto {
        @include media-breakpoint-up(#{$size}) {
            width: auto !important;
        }
    }
    .w-#{$size}-100 {
        @include media-breakpoint-up(#{$size}) {
            width: 100% !important;
        }
    }

    .w-#{$size}-75 {
        @include media-breakpoint-up(#{$size}) {
            width: 75% !important;
        }
    }

    .w-#{$size}-50 {
        @include media-breakpoint-up(#{$size}) {
            width: 50% !important;
        }
    }

    .w-#{$size}-25 {
        @include media-breakpoint-up(#{$size}) {
            width: 25% !important;
        }
    }
}

@each $name, $theme-color in $theme-colors {
    .has-#{$name}-color {
        color: $theme-color !important;
    }
    .has-#{$name}-background-color {
        background-color: $theme-color !important;
    }
    .icon-#{$name} {
        path {
            stroke: $theme-color !important;
        }
    }
    .bg-#{$name} {
        transition: background-color 300ms ease;
    }
    .bg-#{$name}-hover {
        &:hover {
            transition: background-color 300ms ease;
            background-color: $theme-color !important;
        }
    }
    .stroke-#{$name} {
        stroke: $theme-color !important;
    }
    .fill-#{$name} {
        fill: $theme-color !important;
    }
}

.border-top-transparent {
    border-top-color: transparent !important;
}
.border-bottom-transparent {
    border-bottom-color: transparent !important;
}
