section.block-postsummary {
    .container {
        .icons {
            .icon {
                width: 60px;
                height: 60px;
                background-color: $black;
                display: block;
                position: relative;
                transition: 100ms background-color ease;
                border: 2px solid $black;
                svg {
                    width: 50%;
                    height: auto;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    path,
                    circle {
                        transition: 100ms background-color ease;
                    }
                }
                &:hover {
                    background-color: $white;
                    svg {
                        path,
                        circle {
                            fill: $black;
                        }
                    }
                }
            }
        }
    }
}
