$border-value: 1px solid rgba(0,0,0,.10);

.block-logogrid {
    .logo-items {
        .logo-item {
            border-left: $border-value;
        }
        .logo-item:nth-of-type(6), 
        .logo-item:nth-of-type(12), 
        .logo-item:nth-of-type(18) {
            border-right: $border-value;
        }
        .logo-item:nth-of-type(7), 
        .logo-item:nth-of-type(8), 
        .logo-item:nth-of-type(9),
        .logo-item:nth-of-type(10),
        .logo-item:nth-of-type(11),
        .logo-item:nth-of-type(12) {
            border-top: $border-value;
            border-bottom: $border-value;
        }
    }

    @media screen and (max-width: 991px) {
        .logo-items {
            .logo-item {
                border-left: $border-value;
                border-bottom: $border-value;
                padding: 2rem !important;
            }
            .logo-item:nth-of-type(6), 
            .logo-item:nth-of-type(12), 
            .logo-item:nth-of-type(18) {
                border-right: 0;
            }
            .logo-item:nth-of-type(7), 
            .logo-item:nth-of-type(8), 
            .logo-item:nth-of-type(9),
            .logo-item:nth-of-type(10),
            .logo-item:nth-of-type(11),
            .logo-item:nth-of-type(12) {
                border-top: 0;
            }
            .logo-item:nth-of-type(3), 
            .logo-item:nth-of-type(6), 
            .logo-item:nth-of-type(9),
            .logo-item:nth-of-type(12) {
                border-right: $border-value;
            }
            .logo-item:nth-of-type(18), 
            .logo-item:nth-of-type(17), 
            .logo-item:nth-of-type(16),
            .logo-item:nth-of-type(15),
            .logo-item:nth-of-type(14),
            .logo-item:nth-of-type(13) {
                display: none;
            }
            .logo-item:nth-of-type(12), 
            .logo-item:nth-of-type(11), 
            .logo-item:nth-of-type(10) {
                border-bottom: 0;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .logo-items {
            .logo-item {
                padding: 1.5rem !important;
            }
        }
    }
    @media screen and (max-width: 376px) {
        .logo-items {
            .logo-item {
                padding: 1rem !important;
            }
        }
    }
}