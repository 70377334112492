.post-quotes-inner {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: 479px) {
        display: block;
        max-width: 110%;
    }
}

.post-quote-mark {
    width: 52px;
    margin-right: 30px;
    flex: 0 0 auto;
    @media screen and (max-width: 767px) {
        width: 40px;
        margin-right: 15px;
    }
    @media screen and (max-width: 479px) {
        margin-top: 4px;
        float: left;
    }
}