.tiles {
    .tile {
        position: relative;
        .text {
            order: 1;
        }
        .video {
            order: 2;
            .video-wrapper {
                position: relative;
                top: -30px;
                @include media-breakpoint-down(lg) {
                    top: 0;
                }
                img {
                    position: absolute;
                    bottom: -100px;
                    left: -50px;
                    max-width: 350px;
                    @include media-breakpoint-down(xl) {
                        max-width: 250px;
                    }
                    @include media-breakpoint-down(lg) {
                        display: none;
                    }
                }
            }
        }
        .tile-arrow {
            position: absolute;
            left: 50%;
            bottom: -50px;
            transform: translate(-50%, 0%) scaleX(-1);
            width: 100px;
            z-index: 100;
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }
    .tile:nth-child(odd) {
        .text {
            order: 2;
            @include media-breakpoint-down(lg) {
                order: 1;
            }
        }
        .video {
            order: 1;
            @include media-breakpoint-down(lg) {
                order: 2;
            }
            img {
                right: -50px;
                left: auto;
            }
        }
        .tile-arrow {
            transform: translate(-50%, 0%) scaleX(1);
        }
    }

    .tile:last-of-type {
        .tile-arrow {
            display: none;
        }
    }
}
