@import "~bootstrap/scss/maps";

$black: #1d1d1b;
$white: #ffffff;
$border-grey: #566069;

// Colors - Stone
$grey: #D6CCC2;
$light-grey: #EFEBE7;
$ultra-light-grey: lighten($light-grey, 50%);

// Colors - Purple
$midnight: #2A2069;
$purple: #5644d8;
$light-purple: #E1DBF7; 
$ultra-light-purple: #F3F1FC;

// Colors - Red
$red: #F25F5C;
$pink: #F3C1C0;
$light-pink: #FAE6E6;

// Colors - Yellow
$amber: #FFB840;
$bisque: #FFE6C1;
$light-bisque: #FFF5E6;


//Re-map old Colors
//$peach: $amber;
//$light-peach: $bisque;
//$fade-yellow: $light-bisque;
//$salmon: $pink;
//$light-salmon: $pink-light;
//$fade-red: $pink-light;
//$liliac: $light-purple; 
//$light-liliac: lighten($light-purple, 25%); 
//$light-purple: $light-purple;
//$light-purple: lighten($light-purple, 25%);


// Colors - Green
$accent: $purple; // $green

// Gradients
$gradient-blue-white: linear-gradient(180deg, #F3F1FC 0%, #B19FF3 100%);

$theme-colors: (
    "black": $black,
    "white": $white,
    "border-grey": $border-grey,

    "grey": $grey,
    "light-grey": $light-grey,
    "ultra-light-grey": $ultra-light-grey,
    
    "midnight": $midnight,
    "purple": $purple,
    "light-purple": $light-purple,
    "ultra-light-purple": $ultra-light-purple,

    "red": $red,
    "pink": $pink,
    "light-pink": $light-pink,

    "amber": $amber,
    "bisque": $bisque,
    "light-bisque": $light-bisque,
);

$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");

$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop(
        $utilities-colors,
        rgba-css-var,
        "$key",
        "text"
);
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");
$utilities-border-colors: map-loop($utilities-colors, rgba-css-var, "$key", "border");

$font: "Athletics", sans-serif;

// scss-docs-start theme-color-variables
$primary: $purple;
$secondary: $midnight;
$success: $purple;
$info: $grey;
$warning: $amber;
$danger: $red;
$light: $light-grey;
$dark: $midnight;
// scss-docs-end theme-color-variables

// Body
//
// Settings for the `<body>` element.
$body-bg: $white;
$body-color: $black;

$headings-font-family: "Athletics", sans-serif;
$font-family-base: "Eina02", sans-serif;

$h1-font-size: 64px;
$h2-font-size: 48px;
$h3-font-size: 32px;
$h4-font-size: 24px;
$h5-font-size: 18px;
$h6-font-size: 16px;

$headings-font-weight: 700;
$headings-line-height: calc(1em + 0.5rem);
$headings-margin-bottom: 1rem;

$font-sizes: (
    1: $h1-font-size,
    2: $h2-font-size,
    3: $h3-font-size,
    4: $h4-font-size,
    5: $h5-font-size,
    6: $h6-font-size,
);

$spacer: 1rem;

$extra-spacers: (
    6: $spacer * 6,
    7: $spacer * 7,
    8: $spacer * 8,
    9: $spacer * 9,
    10: $spacer * 10,
    single: $spacer * 1,
    double: $spacer * 2,
);

$spacers: map-merge($spacers, $extra-spacers);

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1500px,
);

$grid-gutter-width: 1.5rem;
$container-padding-x: $grid-gutter-width;
$border-color: $midnight;

$border-radius-lg: 1rem;
$box-shadow: 0px 0.2rem 0.3rem rgba(0, 0, 0, 0.10);
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075);
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175);
$box-shadow-inset: inset 0 1px 2px rgba($black, 0.075);

// Badges
$badge-font-size: 1rem;
$badge-font-weight: $font-weight-bold;
$badge-color: $white;
$badge-padding-y: 0.375rem;
$badge-padding-x: 1rem;
$badge-border-radius: 0.5rem;

// Tooltip
$tooltip-padding-y: 0.75rem;
$tooltip-padding-x: 0.75rem;
$tooltip-border-radius: 0.5rem;
$tooltip-max-width: 300px;
$tooltip-bg: $midnight;