.quote {
    margin-bottom: 45px;
    font-family: Athletics, sans-serif;
    font-size: 68px;
    line-height: 1;
    font-weight: 700;

    @media screen and (max-width: 991px) {
        margin-bottom: 21px;
        font-size: 45px;
        line-height: 1.2;
    }
    @media screen and (max-width: 767px) {
        margin-bottom: 14px;
        font-size: 30px;
    }
}

.quote-credit {
    font-family: Athletics, sans-serif;
    color: #5644d8;
    font-size: 26px;
    font-weight: 700;
    @media screen and (max-width: 991px) {
        font-size: 20px;
    }
    @media screen and (max-width: 767px) {
        font-size: 17px;
    }
    @media screen and (max-width: 479px) {
        font-size: 14px;
    }
    &.white {
        color: $white;
    }
}

.testimonials-button-holder {
    display: flex;
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 50px;
    @media screen and (max-width: 991px) {
        padding-top: 40px;
    }
    @media screen and (max-width: 767px) {
        padding-top: 30px;
    }
    @media screen and (max-width: 479px) {
        max-width: 100%;
    }
}
