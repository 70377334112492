.s-image-bg-block {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.mid-page-inner {
    padding: 40px 20px;

    @include media-breakpoint-up(xl) {
        padding: 80px 80px;
    }
}