.alignwide,
.wp-block-columns {
    @extend .container;
}

@media (min-width: 600px) and (max-width: 781px) {
    .wp-block-column:not(:only-child) {
        flex-basis: 100% !important;
    }
}

.wp-block.editor-post-title {
    @extend .container;
}

.wp-block {
    width: 100%;
    max-width: 100%;
}

.wp-block[data-align="wide"] {
    @extend .container;
}

.wp-block[data-align="full"] {
    @extend .container-fluid;
}

.wp-block-group {
    padding-top: 4rem;
    padding-bottom: 4rem;
    @include media-breakpoint-down(md) {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .wp-block-columns {
        &:first-of-type {
            margin-top: 2rem;
            @include media-breakpoint-down(md) {
                margin-top: 0rem;
            }
        }
        margin-bottom: 2rem;

        @include media-breakpoint-down(md) {
            padding-top: 2rem;
            padding-bottom: 0rem;
            margin-bottom: 0;
        }
        .wp-block-column {
            margin-bottom: 1rem !important;
            @include media-breakpoint-down(md) {
                margin-bottom: 1.5rem;
                &:empty {
                    display: none;
                }
            }
            &:nth-child(even) {
                @include media-breakpoint-down(lg) {
                    margin-left: 0px;
                }
            }
            &:last-of-type {
                margin-bottom: 0px;
            }

            &:first-of-type {
                margin-bottom: 1rem !important;
            }

            > :last-child:not(:first-child) {
                margin-bottom: 1rem !important;
            }
            &:not(:first-of-type) {
                //margin-left: 1.5rem;
                @include media-breakpoint-down(md) {
                    margin-left: 0;
                }
            }
        }
        .wp-block-columns {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &.cut-to-bottom {
        .wp-block-columns {
            &:last-of-type {
                padding-bottom: 0 !important;
                margin-bottom: 0 !important;
                .wp-block-column {
                    &:last-of-type {
                        padding-bottom: 0 !important;
                        margin-bottom: 0 !important;
                        .wp-block-image {
                            &:last-of-type {
                                padding-bottom: 0 !important;
                                margin-bottom: 0 !important;
                                figure {
                                    padding-bottom: 0 !important;
                                    margin-bottom: 0 !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.wp-block-spacer {
    @include media-breakpoint-down(md) {
        height: 0px !important;
    }
}
