.features-slider {
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: $white;
    overflow: hidden;

    .inner, .highlights {
        padding: 32px;

        @include media-breakpoint-up(xl) {
            padding: 0 40px 0 20px;
        }
    }

    .feature-item {
        overflow: hidden;

        picture {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            bottom: -17px;
            left: -40px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: bottom;
            }
        }

        a {
            transition: all 0.3s ease-in-out;
            &:hover {
                color: $midnight;
            }
        }
    }

    .glide {

        &__bullet {
            position: relative;
            border: 0;
            overflow: hidden;
            color: $midnight;
            background-color: $light-bisque;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                display: block;
                opacity: 0;
                z-index: 0;
                transform: translateX(-100%);
                background-color: $bisque;
                transition: transform 5.4s linear, opacity 0.2s linear;
            }

            span {
                position: relative;
                z-index: 1;

                @include media-breakpoint-up(md) {
                    font-size: $h6-font-size;
                    font-weight: bold;
                }
            }
        }

        &__bullet--active {
            &::before {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }

}