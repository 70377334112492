section.two-column-sticky {
    display: flex;
    flex-direction: row;

    div.left-side, div.right-side {
        display: flex;
        width: 50%;
    }

    div.left-side {
        height: 100vh;
        background: linear-gradient(180deg, #FFF5E6 0%, rgba(255, 245, 230, 0.00) 100%);
        padding: 7rem 3.5rem;
        justify-content: flex-end;
        position: sticky;
        position: -webkit-sticky;
        top: 0;

        .text-left {
            padding-right: .5rem;
        }

        div.left-inner {
            width: 100%;
            max-width: 591px;
        }
    }

    &:nth-child(even) {
        div.left-side {
            background: linear-gradient(180deg, #F3F1FC 0%, rgba(243, 241, 252, 0.00) 100%);
        }
    }

    div.no-bg {
        background: transparent!important;
    }

    div.right-side {
        padding: 7rem 3.5rem;

        div.right-inner {
            width: 100%;
            max-width: 591px;
        }
    }

    @media (max-width: 1199px) {
        div.left-side {
            position: relative;
        }
    }

    @media (max-width: 1000px) {
        flex-direction: column;

        div.left-side, div.right-side {
            width: 100%;
        }

        div.left-side {
            padding: 3rem 8rem;
            height: auto;

            div.left-inner {
                width: 100%;
                max-width: 100%;
            }
        }

        div.right-side {
            padding: 3rem 8rem;
            height: auto;
    
            div.right-inner {
                width: 100%;
                max-width: 100%;
            }
        }
    }

    @media (max-width: 767px) {
        div.left-side {
            padding: 3rem .75rem;
            height: auto;
        }

        div.right-side {
            padding: 3rem .75rem;
        }
    }
}