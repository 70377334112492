// New buttons
$btn-line-height: 1;
$btn-font-weight: 700;
$btn-box-shadow: inset 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba($black, 0.075);
$btn-border-radius: 1rem;
$btn-border-width: 0px;
$btn-link-color: $body-color;
$btn-link-hover-color: $body-color;
$btn-link-disabled-color: $gray-600;

// Large
$btn-padding-y-lg: 17px 24px;
$btn-padding-x-lg: 17px 24px;
$btn-border-radius-lg: $btn-border-radius;
$btn-font-size-lg: 1.125rem;

// Medium
$btn-font-size: 1rem;
$btn-padding-y: 14px 20px;
$btn-padding-x: 13px 20px;

// Small
$btn-padding-y-sm: 11px 1rem;
$btn-padding-x-sm: 11px 1rem 10px;
$btn-font-size-sm: 0.875rem;
$btn-border-radius-sm: $btn-border-radius;

.buttons {
    @include media-breakpoint-down(sm) {
        width: 100%;
    }
    .btn {
        margin: 0 1rem 1rem 0;
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.navbar {
    #button_book-a-demo {
        background: linear-gradient(-45deg, $midnight, $purple, $red);
        background-size: 400% 400%;
        animation: gradient 8s ease infinite;        
    }
}

.btn {
    font-family: $headings-font-family;
    margin: 0;
    text-align: left !important;
    z-index: 1;

    @include media-breakpoint-down(sm) {

        &.btn-type-button {
            width: auto;
            //text-align: center !important;
        }
        &.btn-naked {
            justify-content: start !important;
        }
        &.no-w-100 {
            width: auto;
        }
    }
    // .text {
    //     position: relative;
    //     z-index: 10;
    // }
    .icon {
        margin-left: 1rem;
        .overlap {
            position: absolute;
            top: 0;
            left: 0;
        }
        svg {
            position: relative;
            opacity: 1;
            z-index: 10;
            width: 18px;
            height: 18px;
            &:not(.bend) {
                path {
                    stroke: currentColor;
                }
            }
            &.bend {
                opacity: 0;
                path {
                    stroke: currentColor;
                }
            }
            &:not(.btn-arrow) {
                width: 20px;
                height: 20px;
            }
        }
        &.rotate-0 {
            transform: translate(0, 0) rotate(0deg);
        }

        &.rotate-90 {
            transform: translate(0, 0) rotate(90deg);
        }

        &.rotate-180 {
            transform: translate(0, 0) rotate(180deg);
        }

        &.rotate-270 {
            transform: translate(0, 0) rotate(270deg);
        }
    }

    &.btn-sm {
        padding: 11px 1rem;
        svg {
            width: 16px;
            height: 16px;
            &:not(.btn-arrow) {
                width: 18px;
                height: 18px;
            }
        }
    }
    &.btn-lg {
        svg {
            width: 20px;
            height: 20px;
            &:not(.btn-arrow) {
                width: 24px;
                height: 24px;
            }
        }
    }
    &[class*="btn-outline"] {
        border-width: 2px;
    }
    &.btn-type-label_button {
        border-radius: 50px;
        padding-right: 42px;
        padding-left: 42px;
        svg.btn-arrow {
            display: none;
        }
        &.btn-sm {
            padding-right: 32px;
            padding-left: 32px;
        }
        &.btn-lg {
            padding-right: 48px;
            padding-left: 48px;
        }
    }
    &.btn-naked {
        padding: 0 !important;

        @include media-breakpoint-up(xl) {
            padding: 0 55px 0 0 !important;
        }
        &.btn-type-label_button {
            padding: 17px 20px 17px 0px !important;
        }
    }
    &.btn-no-arrow {
        &.btn-sm {
            padding: $btn-padding-x-sm !important;
        }
        &.btn-md {
            padding: $btn-padding-x !important;
        }
        &.btn-lg {
            padding: $btn-padding-x-lg !important;
        }
        .icon {
            display: none;
        }
    }
    &.btn-bubble {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 0px;
            width: 0;
            height: 0;
            border-top: 25px solid $black;
            border-right: 40px solid transparent;
        }
    }
    &.btn-icon {
        padding: 14px;
        border-radius: 50px;
        .icon {
            margin-left: 0;
        }
        svg {
            path {
                stroke: currentColor;
                fill: currentColor;
            }
        }
        &.btn-sm {
            padding: 13px;
        }
        &.btn-lg {
            padding: 17px;
        }
        @include media-breakpoint-down(md) {
            width: auto;
        }
    }
    &.btn-no-link {
        &:hover {
            cursor: default;
        }
    }
    &:hover,
    &.active,
    .card:hover & {
        .btn-arrow {
            opacity: 0;
            &.bend {
                opacity: 1;
            }
            transition: 200ms all ease;
        } 
    }
    &:hover:not(.btn-naked, .btn-) {
        transform: scale(0.97);
        transition: 200ms all ease;
    }
    &:active:not(.btn-no-link) {
        transform: translate(0%, 3px);
    }
    &:focus:not(.btn-no-link) {
        box-shadow: unset !important;
    }
    &.btn-black {
        &:hover {
            background-color: tint-color($black, 20%);
            border-color: tint-color($black, 20%);
        }
    }
}

@each $color_slug, $theme-color in $theme-colors {
    $background: $theme-color;
    $border: $theme-color;
    $color: color-contrast($background);
    $hover-background: if(
        $color == $color-contrast-light,
        shade-color($background, $btn-hover-bg-shade-amount),
        tint-color($background, $btn-hover-bg-tint-amount)
    );

    .btn-#{$color_slug}.bubble {
        &:before {
            border-top-color: $theme-color;
            transition: $btn-transition;
        }
        &:hover {
            &::before {
                border-top-color: $hover-background;
                transition: $btn-transition;
            }
            &.btn-black {
                &::before {
                    border-top-color: tint-color($black, 20%);
                    transition: $btn-transition;
                }
            }
        }
    }

    .btn-naked-#{$color_slug} {
        color: $theme-color !important;
    }

    .btn-outline-#{$color_slug} {
        .btn-arrow.straight {
            path {
                stroke: $theme-color !important;
            }
        }
        .btn-arrow:not(.bend) {
            path {
                stroke: currentcolor;
            }
        }
        .btn-arrow {
            &.bend {
                path {
                    stroke: currentcolor;
                }
            }
        }
    }
}
