.conversations-grid {
    display: grid;
    padding-top: 120px;
    justify-content: space-between;
    align-items: start;
    grid-auto-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 16px;
    grid-template-columns: 1fr minmax(min-content, max-content) 1fr;
    grid-template-rows: auto;
    @media screen and (max-width: 991px) {
        padding-top: 80px;
    }
    @media screen and (max-width: 767px) {
        padding-top: 40px;
        grid-row-gap: 50px;
        grid-template-columns: 1fr;
    }
    &.why {
        padding-top: 0;
    }
}

.conversations-card {
    position: relative;
    max-width: 585px;
    &.card-3 {
        position: relative;
        left: 4vw;
        @media screen and (max-width: 991px) {
            left: 0;
            top: -101px;
            margin-bottom: -80px;
        }
        @media screen and (max-width: 767px) {
            top: 0;
            margin-bottom: 0;
            padding-top: 50px;
        }
    }
    >iframe {
        width: 100%;
    }
}

.conversations-image {
    width: 100%;
    margin-bottom: 35px;
}

.conversations-button-holder {
    display: flex;
    padding-top: 30px;
    margin: 5px 10px;
    grid-auto-columns: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    margin-left: 0 !important;
    @media screen and (max-width: 991px) {
        display: flex;
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media screen and (max-width: 767px) {
        grid-row-gap: 10px;
        grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 479px) {
        padding-top: 17px;
        grid-template-columns: 1fr;
        display: block;
    }
}

.conversations-card-spacer {
    height: 337px;
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.conversations-arrow-2 {
    position: absolute;
    width: 213px;
    margin-top: 7vw;
    margin-left: 7vw;
    @media screen and (max-width: 991px) {
        width: 180px;
        margin-left: 0;
    }
    @media screen and (max-width: 767px) {
        left: auto;
        top: auto;
        right: 9%;
        bottom: -5%;
        width: 140px;
        margin-top: 0;
    }
    @media screen and (max-width: 479px) {
        bottom: -15%;
        width: 90px;
    }
}

.conversations-arrow-1 {
    position: absolute;
    left: auto;
    top: auto;
    right: -9vw;
    bottom: -137px;
    width: 183px;
    max-width: 40vw;
    //Instead of the line below you could use @include transform-style($style)
    transform-style: preserve-3d;
    @media screen and (max-width: 991px) {
        right: -7.1vw;
        width: 164px;
    }
    @media screen and (max-width: 767px) {
        left: auto;
        top: auto;
        right: 9%;
        bottom: -9%;
        width: 130px;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: rotateX(0deg) rotateY(180deg) rotateZ(0deg);
    }
    @media screen and (max-width: 479px) {
        bottom: -15%;
        width: 90px;
    }
}

.conversations-grid-divider {
    width: 10vw;
    min-width: 50px;
}