.block-herocenter {
    position: relative;
    overflow: hidden;
    padding-bottom: 0!important;

    @include media-breakpoint-up(lg) {
        height: fit-content;
    }

    video.cover {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        object-fit: cover;
        background-color: $grey;
        background-size: cover;
        transition: all 500ms ease;
    }
    .container {
        position: relative;
    }

    &__subtitle {
        @extend .h6;
        opacity: .7;

        @include media-breakpoint-up(md) {
            font-size: $h6-font-size !important;
        }

    }

    .media-side-holder {
        width: 80%;
    }

    @media (max-width: 1199px) {
        h2.h1 {
            br {
                display: none 
            }
        }

        .buttons {
            width: auto;

            .btn {
                margin: auto;
            }
        }

        .media-side-holder {
            width: 100%;

            .media-side {
                background-color: transparent;
                border-radius: 0;
                overflow: hidden;
                padding-bottom: 0;
            }
        }
    }
}

.hero-banner {
    min-height: 156px;
    background-repeat: no-repeat;
    border-radius: 2rem;
    overflow: hidden;
    background-color: $white;
    color: $black;

    .image-holder {
        min-height: 156px;
        background-repeat: no-repeat;
        background-position-y: bottom;
        background-position-x: center;
    }

    @include media-breakpoint-down(md) {
        background: rgb(242,95,92);
        background: linear-gradient(263deg, rgba(242,95,92,1) 0%, rgba(86,68,216,1) 100%);
        color: $white;

        .btn-naked-purple {
            color: $white!important;
        }
    }
} 

@include media-breakpoint-down(lg) {
    .media-side:has(img) {
        background-color: rgba(0,0,0,.05);
        border-radius: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        overflow: hidden;
    }
}
