section.block-quoteslider {
    .navigation {
        z-index: 1;
    }
    .logo {
        width: 30%;
        @include media-breakpoint-down(md) {
            width: 50%;
        }
    }
}
