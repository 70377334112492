section.block-large-quote {
    // this prevents the usual sliding transition effect from being visible:
    .glide__slides {
        transition: none !important;
    }

    // and this creates the fade in/out effect of the slides:
    .glide__slides li {
        opacity: 0;
        transition: all 700ms ease-in-out;
    }
    li.glide__slide--active {
        opacity: 1;
    }

    .navigation {
        position: absolute;
        top: 50%;
        &.left {
            left: -100px;
        }
        &.right {
            right: -100px;
        }
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}
