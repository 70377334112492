section.block-comparetable {

    .table-wrapper {
        overflow: clip;
    }

    table {
        border-collapse: collapse;

        .sticky-top {
            top: 80px;
            z-index: 1000;

            &.stuck {
                background: $white;
                box-shadow: 0 0.25rem 1rem rgb(0 0 0 / 5%);

                .package {
                    padding-top: 1rem !important;
                    padding-bottom: 0 !important;
                    transition: all 0.5s;
                }
                .hide-on-stuck {
                    opacity: 0;
                    transition: max-height 0.5s, opacity 0.5s;
                }
                .inner {
                    transition: max-height 0.5s, opacity 0.5s;
                    max-height: 44px;
                }
            }
        }

        thead {
            .package {
                transition: all 0.5s;
            }
            .inner {
                max-height: 200px;
                box-sizing: border-box;
                overflow: hidden;
                transition: max-height 0.5s, opacity 0.5s;

                .hide-on-stuck {
                    opacity: 1;
                    transition: max-height 0.5s, opacity 0.5s;
                }
            }
        }
        tbody {
            tr {
                &.feature-item:nth-child(odd) {
                    background-color: $ultra-light-purple;
                }
            }
        }
    }
    .compare-plans-mobile {
        border-radius: 1.5rem;

        svg {
            width: 16px;
            height: 16px;
        }
    }
}
