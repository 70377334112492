section.one-platform, section.block-three-col-icon {
    .op-separator {
        height: 2px;
        opacity: .5;
        margin: 1.5rem 0 1rem 0;
        border-radius: 1px;
    }
    .icon {
        svg {
            height: 100%;
            width: auto;
        }
    }

    .op-icon-holder {
        width: 50px;
        height: 50px;

        &.rect {
            border-radius: 9px;
        }

        &.circ {
            border-radius: 25px;
        }
    
        .icon, .icon svg {
            width: 32px;
            height: 32px;
        }
    }
}
section.block-three-col-icon {
    .op-icon-holder {
        margin: auto;
    }
}


@media (max-width: 1000px) and (min-width: 769px) {
    section.one-platform {
        .container {
            max-width: 100%!important;
        }
    }
}