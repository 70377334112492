.block-pricingaddoncard {
    hr {
        border-top: 1px dotted #545454!important;
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
    }

    .text-small {
        font-size: 0.75rem;
        color: #ABABAB;
    }

    .pricing-addon-card {
        border: 1px solid rgba(0,0,0,.05) !important;
    }

    .price {
        width: 100%;
        height: 35px;
        overflow: hidden;
        

        h3:first-child {
            transform: translateY(-100%);
            transition: all 0.2s ease-in-out;

            &.active {
                transform: translateY(0);
            }
        }

        h3:nth-child(2) {
            transform: translateY(0);
            transition: all 0.2s ease-in-out;

            &.active {
                transform: translateY(-100%);
            }
        }

        .price-number {
            margin-right: .5rem;
        }
    }
}