section.block-newslettersignup {
    .big-card {
        overflow: hidden;
    }

    .hubspot-form-wrapper {
        border: none !important;
        border-radius: 2rem;
    }
}
