section.block-bigcalltoaction {
    position: relative;
    &.triangle:after {
        content: "";
        width: 0;
        height: 0;
        border-bottom: 70px solid $purple;
        border-right: 100px solid transparent;
        position: absolute;
        left: 0px;
        top: -70px;
        @include media-breakpoint-down(md) {
            border-bottom: 40px solid $purple;
            top: -40px;
            border-right: 80px solid transparent;
        }
    }
}
