video {
    display: inline-block;
    max-width: 100%;
    height: auto;
}

.plyr {
    --plyr-color-main: #5644d8;
    --plyr-control-spacing: 30px;
    --plyr-video-controls-background: linear-gradient(0deg, rgba(42,32,105,1) 0%, rgba(255,255,240,0) 80%);
    --plyr-control-spacing: 10px;
    border-radius: 15px;

    &__video-embed {
        position: relative;
    }

    &__poster {
        background-size: cover;
    }


}