.cta-button-holder {
    display: flex;
    padding-top: 45px;
    justify-content: center;
    @media screen and (max-width: 479px) {
        padding-top: 30px;
    }
}

.know-more-arrow {
    position: absolute;
    left: 0;
    top: -5.5vw;
    right: auto;
    bottom: auto;
    width: 300px;
    @media screen and (max-width: 991px) {
        width: 200px;
    }
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.know-more-cta-inner {
    padding-top: 140px;
    padding-bottom: 140px;
    @media screen and (max-width: 991px) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    @media screen and (max-width: 767px) {
        padding-right: 58px;
        padding-left: 58px;
    }
    @media screen and (max-width: 479px) {
        padding-right: 20px;
        padding-left: 20px;
    }
}

._464 {
    max-width: 464px;
}

.button-holder-flex-45 {
    display: flex;
    padding-top: 45px;
    @media screen and (max-width: 479px) {
        padding-top: 23px;
    }
}
