section.block-pricingcardsnew {
    position: relative;

    &::after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 30vh;
        left: 0;
        top: 0;
        z-index: -1;
        background-color: $light-purple;
    }

    .collapse_button {
        a {
            &.collapsed {
                .hide_features {
                    display: none;
                }
            }
            &:not(.collapsed) {
                .show_features {
                    display: none;
                }
            }
        }
    }

    .package {

        &:not(:first-child) {
            .features {
                > ul > li:first-child {
                    color: $purple;
                }
            }
        }

        .price {
            width: 100%;
            height: 35px;
            overflow: hidden;

            h3:first-child {
                transform: translateY(-100%);
                transition: all 0.4s ease-in-out;

                &.active {
                    transform: translateY(0);
                }
            }

            h3:nth-child(2) {
                transform: translateY(0);
                transition: all 0.4s ease-in-out;

                &.active {
                    transform: translateY(-100%);
                }
            }
        }
    }

    .features {

        ul {
            li {
                font-size: 14px;
            }
        }

        .accordion-body {
            p {
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}
