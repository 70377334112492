.card-icon-holder {
    width: 70px;
    height: 70px;
    border-radius: 35px;

    .icon, .icon svg {
        width: 32px;
        height: 32px;
    }
}
.card-grid {
    .card {
        padding-bottom: 7rem!important;
    }
    
    .card-grid-buttons {
        position: absolute;
        bottom: 3rem;
    }
}
