$legend-margin-bottom: 0.5rem;
$legend-font-size: 1rem;
$legend-font-weight: null;

.posts-cta-curl {
    z-index: 1;
}

.meetings-iframe-container {
    margin-top: 150px;
}

.form-preview {
    color: #1d1d1b;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
    z-index: 100;
    position: relative;
    max-width: 600px;
}

.form-wrapper {
    color: $black;
    background-color: $white;
    z-index: 100;

    @each $name, $theme-color in $theme-colors {
        &.submit-button-color-#{$name} {
            input[type="submit"] {
                @extend .btn-#{$name};
            }
        }
    }

    &.inline {
        form {
            display: flex;
            flex-direction: row;
            label {
                display: none;
            }
            .hs-form-field {
                margin-bottom: 0;
                min-width: 275px;
                margin-right: 1rem;
            }
            input[type="submit"] {
                margin: 0 !important;
            }
        }
        .submitted-message {
            color: inherit;
            @extend .h3;
        }
    }

    &.naked {
        background-color: transparent !important;
        padding: 0;
    }
}

.hs-richtext {
    font-size: .8rem;
    line-height: 1.4;
    color: rgba(0,0,0,.5);
}

form {
    position: relative;
    max-width: 100%;

    input,
    select {
        height: 50px;
    }

    input,
    select,
    textarea {
        border: 1px solid rgba($color: $black, $alpha: 0.4);
        width: 100% !important;
        border-radius: 1rem;
        padding: 10px 10px;
    }

    input[type="checkbox"],
    input[type="radio"] {
        height: 20px;
        width: 20px !important;
    }

    select {
        padding-right: 40px !important;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAh0lEQVQ4T93TMQrCUAzG8V9x8QziiYSuXdzFC7h4AcELOPQAdXYovZCHEATlgQV5GFTe1ozJlz/kS1IpjKqw3wQBVyy++JI0y1GTe7DCBbMAckeNIQKk/BanALBB+16LtnDELoMcsM/BESDlz2heDR3WePwKSLo5eoxz3z6NNcFD+vu3ij14Aqz/DxGbKB7CAAAAAElFTkSuQmCC");
        background-repeat: no-repeat;
        background-position: right 15px center;
        background-color: $white;
    }

    textarea {
        min-height: 150px;
    }

    label {
        color: $midnight;
        text-align: left !important;
        font-size: 1rem;
        padding-bottom: 0.5rem!important;
    }

    .legal-consent-container {
        p {
            margin-bottom: 0!important;
        }
    }

    legend {
        margin-bottom: 5px;
        font-style: italic;
    }

    // HubSpot hacks
    fieldset {
        max-width: 100% !important;
        .field:last-of-type {
            .input {
                margin-right: 0px !important;
            }
        }
        .input {
            margin-right: 15px !important;
            @media screen and (max-width: 480px) {
                margin-right: 0px !important;
            }
        }
    }

    .field {
        margin-bottom: 20px;
        text-align: left !important;
        .inputs-list {
            list-style: none;
            li {
                span {
                    margin: 0px;
                }
            }
            .hs-form-booleancheckbox {
                position: relative;
                input {
                    position: absolute;
                    top: 5px;
                    left: -30px;
                }
            }
        }
    }

    input[type="submit"] {
        @extend .btn;
        @extend .btn-purple;
        @extend .mt-3;
        text-align: center !important;
        padding: 17px 20px !important;
        transition: all 0.3s ease-in-out;
    }

    .hs-input {
        width: 100%!important;
        border: 2px solid #948FB4;
        color: $midnight;
    }

    input.hs-input:focus, textarea.hs-input:focus {
        outline: none;
        border: 2px solid $midnight!important;
        border-color: $midnight!important;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    .hs-input::placeholder {
        color: #6A6396;
    }

    .hs-error-msgs {
        padding: 0;
        margin: 0;
        label.hs-error-msg {
            font-size: 14px;
            color: $red;
            font-weight: 400;
        }
    }

    .hs_error_rollup {
        ul {
            list-style: none;
            label {
                color: $red;
            }
        }
    }
}

.submitted-message {
    p {
        color: $midnight;
        font-size: 1.3rem;
        line-height: 2rem;
        text-align: center;
        font-weight: 600;
    }
}

.demo-form-col {
    padding-bottom: 50px;
}
