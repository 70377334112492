$font-size-paragraph: 1rem;
$heading-margin-top: 2rem;

main .has-drop-cap:not(:focus)::first-letter {
    float: left;
    font-size: 6rem;
    line-height: 0.68;
    font-weight: bold;
    margin: 0.05em 0.2em 10px 0;
    text-transform: uppercase;
    font-style: normal;
    color: $purple;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    &.rich-text,
    &.heading {
        margin-top: $heading-margin-top;
    }
}

p,
ul {
    &.rich-text,
    &.paragraph,
    &.list {
        font-size: $font-size-paragraph;
    }
}

.single-post {
    main > article > .wp-block-columns {
        p {
            @extend .fs-5;
            font-size: 1.25rem!important;
            line-height: 2rem;
        }
    }

    .breadcrumbs .btn {
        color: $white!important;
        background-color: $black!important;
    }
}