$link-color: $primary;
$link-decoration: none;

a {
    &.underlined {
        position: relative;
        .underline {
            position: absolute;
            bottom: -13px;
            left: 50%;
            overflow: hidden;
            width: 100%;
            height: 4px;
            transform: translate(-50%, 0);
            svg {
                position: absolute;
                top: 50%;
                left: 0px;
                height: 4px;
                width: auto;
                transform: translate(0, -50%);
                path {
                    stroke-dasharray: 1294;
                    stroke-dashoffset: 1294;
                    transition: stroke-dashoffset 300ms ease;
                }
            }
        }
    }
    &:hover {
        .underline {
            svg {
                path {
                    stroke-dashoffset: 0;
                    transition: stroke-dashoffset 1s;
                }
            }
        }
    }
}

// Anchor fix with header nav bar
// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//     &[id]::before {
//         content: "";
//         display: block;
//         height: 120px;
//         margin-top: -120px;
//         visibility: hidden;
//         pointer-events: none;
//     }
// }

.sticky-top.scrollspy {
    top: 96px;
}

body.admin-bar {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        &[id]::before {
            height: 158px;
            margin-top: -158px;
        }
    }
    .sticky-top.scrollspy {
        top: 132px;
    }
}
