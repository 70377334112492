@charset "UTF-8";
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex, .modal.show {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0px 0.2rem 0.3rem rgba(0, 0, 0, 0.1) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(29, 29, 27, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(29, 29, 27, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-border-grey {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-border-grey-rgb), var(--bs-border-opacity)) !important;
}

.border-grey {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-grey-rgb), var(--bs-border-opacity)) !important;
}

.border-light-grey {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-grey-rgb), var(--bs-border-opacity)) !important;
}

.border-ultra-light-grey {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-ultra-light-grey-rgb), var(--bs-border-opacity)) !important;
}

.border-midnight {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-midnight-rgb), var(--bs-border-opacity)) !important;
}

.border-purple {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-purple-rgb), var(--bs-border-opacity)) !important;
}

.border-light-purple {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-purple-rgb), var(--bs-border-opacity)) !important;
}

.border-ultra-light-purple {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-ultra-light-purple-rgb), var(--bs-border-opacity)) !important;
}

.border-red {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-red-rgb), var(--bs-border-opacity)) !important;
}

.border-pink {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-pink-rgb), var(--bs-border-opacity)) !important;
}

.border-light-pink {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-pink-rgb), var(--bs-border-opacity)) !important;
}

.border-amber {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-amber-rgb), var(--bs-border-opacity)) !important;
}

.border-bisque {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-bisque-rgb), var(--bs-border-opacity)) !important;
}

.border-light-bisque {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-bisque-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center, .modal.show {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-6 {
  margin: 6rem !important;
}

.m-7 {
  margin: 7rem !important;
}

.m-8 {
  margin: 8rem !important;
}

.m-9 {
  margin: 9rem !important;
}

.m-10 {
  margin: 10rem !important;
}

.m-single {
  margin: 1rem !important;
}

.m-double {
  margin: 2rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-6 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

.mx-7 {
  margin-right: 7rem !important;
  margin-left: 7rem !important;
}

.mx-8 {
  margin-right: 8rem !important;
  margin-left: 8rem !important;
}

.mx-9 {
  margin-right: 9rem !important;
  margin-left: 9rem !important;
}

.mx-10 {
  margin-right: 10rem !important;
  margin-left: 10rem !important;
}

.mx-single {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-double {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-6 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-7 {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important;
}

.my-8 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.my-9 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important;
}

.my-10 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.my-single {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-double {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3, form input[type=submit] {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-6 {
  margin-top: 6rem !important;
}

.mt-7 {
  margin-top: 7rem !important;
}

.mt-8 {
  margin-top: 8rem !important;
}

.mt-9 {
  margin-top: 9rem !important;
}

.mt-10 {
  margin-top: 10rem !important;
}

.mt-single {
  margin-top: 1rem !important;
}

.mt-double {
  margin-top: 2rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-6 {
  margin-right: 6rem !important;
}

.me-7 {
  margin-right: 7rem !important;
}

.me-8 {
  margin-right: 8rem !important;
}

.me-9 {
  margin-right: 9rem !important;
}

.me-10 {
  margin-right: 10rem !important;
}

.me-single {
  margin-right: 1rem !important;
}

.me-double {
  margin-right: 2rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-6 {
  margin-bottom: 6rem !important;
}

.mb-7 {
  margin-bottom: 7rem !important;
}

.mb-8 {
  margin-bottom: 8rem !important;
}

.mb-9 {
  margin-bottom: 9rem !important;
}

.mb-10 {
  margin-bottom: 10rem !important;
}

.mb-single {
  margin-bottom: 1rem !important;
}

.mb-double {
  margin-bottom: 2rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-6 {
  margin-left: 6rem !important;
}

.ms-7 {
  margin-left: 7rem !important;
}

.ms-8 {
  margin-left: 8rem !important;
}

.ms-9 {
  margin-left: 9rem !important;
}

.ms-10 {
  margin-left: 10rem !important;
}

.ms-single {
  margin-left: 1rem !important;
}

.ms-double {
  margin-left: 2rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-6 {
  padding: 6rem !important;
}

.p-7 {
  padding: 7rem !important;
}

.p-8 {
  padding: 8rem !important;
}

.p-9 {
  padding: 9rem !important;
}

.p-10 {
  padding: 10rem !important;
}

.p-single {
  padding: 1rem !important;
}

.p-double {
  padding: 2rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-6 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.px-7 {
  padding-right: 7rem !important;
  padding-left: 7rem !important;
}

.px-8 {
  padding-right: 8rem !important;
  padding-left: 8rem !important;
}

.px-9 {
  padding-right: 9rem !important;
  padding-left: 9rem !important;
}

.px-10 {
  padding-right: 10rem !important;
  padding-left: 10rem !important;
}

.px-single {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-double {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-6 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-7 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

.py-8 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.py-9 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}

.py-10 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.py-single {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-double {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-6 {
  padding-top: 6rem !important;
}

.pt-7 {
  padding-top: 7rem !important;
}

.pt-8 {
  padding-top: 8rem !important;
}

.pt-9 {
  padding-top: 9rem !important;
}

.pt-10 {
  padding-top: 10rem !important;
}

.pt-single {
  padding-top: 1rem !important;
}

.pt-double {
  padding-top: 2rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pe-6 {
  padding-right: 6rem !important;
}

.pe-7 {
  padding-right: 7rem !important;
}

.pe-8 {
  padding-right: 8rem !important;
}

.pe-9 {
  padding-right: 9rem !important;
}

.pe-10 {
  padding-right: 10rem !important;
}

.pe-single {
  padding-right: 1rem !important;
}

.pe-double {
  padding-right: 2rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-6 {
  padding-bottom: 6rem !important;
}

.pb-7 {
  padding-bottom: 7rem !important;
}

.pb-8 {
  padding-bottom: 8rem !important;
}

.pb-9 {
  padding-bottom: 9rem !important;
}

.pb-10 {
  padding-bottom: 10rem !important;
}

.pb-single {
  padding-bottom: 1rem !important;
}

.pb-double {
  padding-bottom: 2rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.ps-6 {
  padding-left: 6rem !important;
}

.ps-7 {
  padding-left: 7rem !important;
}

.ps-8 {
  padding-left: 8rem !important;
}

.ps-9 {
  padding-left: 9rem !important;
}

.ps-10 {
  padding-left: 10rem !important;
}

.ps-single {
  padding-left: 1rem !important;
}

.ps-double {
  padding-left: 2rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.gap-6 {
  gap: 6rem !important;
}

.gap-7 {
  gap: 7rem !important;
}

.gap-8 {
  gap: 8rem !important;
}

.gap-9 {
  gap: 9rem !important;
}

.gap-10 {
  gap: 10rem !important;
}

.gap-single {
  gap: 1rem !important;
}

.gap-double {
  gap: 2rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.525rem + 3.3vw) !important;
}

.fs-2 {
  font-size: calc(1.425rem + 2.1vw) !important;
}

.fs-3 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5, .single-customers main > article > .wp-block-group > p, .single-post main > article > .wp-block-columns p {
  font-size: 1.125rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-border-grey {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-border-grey-rgb), var(--bs-text-opacity)) !important;
}

.text-grey {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-grey-rgb), var(--bs-text-opacity)) !important;
}

.text-light-grey {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-grey-rgb), var(--bs-text-opacity)) !important;
}

.text-ultra-light-grey {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-ultra-light-grey-rgb), var(--bs-text-opacity)) !important;
}

.text-midnight {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-midnight-rgb), var(--bs-text-opacity)) !important;
}

.text-purple {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-purple-rgb), var(--bs-text-opacity)) !important;
}

.text-light-purple {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-purple-rgb), var(--bs-text-opacity)) !important;
}

.text-ultra-light-purple {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-ultra-light-purple-rgb), var(--bs-text-opacity)) !important;
}

.text-red {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-red-rgb), var(--bs-text-opacity)) !important;
}

.text-pink {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pink-rgb), var(--bs-text-opacity)) !important;
}

.text-light-pink {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-pink-rgb), var(--bs-text-opacity)) !important;
}

.text-amber {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-amber-rgb), var(--bs-text-opacity)) !important;
}

.text-bisque {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-bisque-rgb), var(--bs-text-opacity)) !important;
}

.text-light-bisque {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-bisque-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(29, 29, 27, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-border-grey {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-border-grey-rgb), var(--bs-bg-opacity)) !important;
}

.bg-grey {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-grey-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light-grey {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-grey-rgb), var(--bs-bg-opacity)) !important;
}

.bg-ultra-light-grey {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-ultra-light-grey-rgb), var(--bs-bg-opacity)) !important;
}

.bg-midnight {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-midnight-rgb), var(--bs-bg-opacity)) !important;
}

.bg-purple {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-purple-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light-purple {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-purple-rgb), var(--bs-bg-opacity)) !important;
}

.bg-ultra-light-purple {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-ultra-light-purple-rgb), var(--bs-bg-opacity)) !important;
}

.bg-red {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-red-rgb), var(--bs-bg-opacity)) !important;
}

.bg-pink {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light-pink {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-pink-rgb), var(--bs-bg-opacity)) !important;
}

.bg-amber {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-amber-rgb), var(--bs-bg-opacity)) !important;
}

.bg-bisque {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-bisque-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light-bisque {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-bisque-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-single {
  border-radius: 1rem !important;
}

.rounded-double {
  border-radius: 2rem !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-6 {
    margin: 6rem !important;
  }
  .m-sm-7 {
    margin: 7rem !important;
  }
  .m-sm-8 {
    margin: 8rem !important;
  }
  .m-sm-9 {
    margin: 9rem !important;
  }
  .m-sm-10 {
    margin: 10rem !important;
  }
  .m-sm-single {
    margin: 1rem !important;
  }
  .m-sm-double {
    margin: 2rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-sm-7 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-sm-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-sm-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-sm-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-sm-single {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-double {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-sm-7 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-sm-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-sm-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-sm-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-sm-single {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-double {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-6 {
    margin-top: 6rem !important;
  }
  .mt-sm-7 {
    margin-top: 7rem !important;
  }
  .mt-sm-8 {
    margin-top: 8rem !important;
  }
  .mt-sm-9 {
    margin-top: 9rem !important;
  }
  .mt-sm-10 {
    margin-top: 10rem !important;
  }
  .mt-sm-single {
    margin-top: 1rem !important;
  }
  .mt-sm-double {
    margin-top: 2rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-6 {
    margin-right: 6rem !important;
  }
  .me-sm-7 {
    margin-right: 7rem !important;
  }
  .me-sm-8 {
    margin-right: 8rem !important;
  }
  .me-sm-9 {
    margin-right: 9rem !important;
  }
  .me-sm-10 {
    margin-right: 10rem !important;
  }
  .me-sm-single {
    margin-right: 1rem !important;
  }
  .me-sm-double {
    margin-right: 2rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 6rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 7rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 8rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 9rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 10rem !important;
  }
  .mb-sm-single {
    margin-bottom: 1rem !important;
  }
  .mb-sm-double {
    margin-bottom: 2rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-6 {
    margin-left: 6rem !important;
  }
  .ms-sm-7 {
    margin-left: 7rem !important;
  }
  .ms-sm-8 {
    margin-left: 8rem !important;
  }
  .ms-sm-9 {
    margin-left: 9rem !important;
  }
  .ms-sm-10 {
    margin-left: 10rem !important;
  }
  .ms-sm-single {
    margin-left: 1rem !important;
  }
  .ms-sm-double {
    margin-left: 2rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .p-sm-6 {
    padding: 6rem !important;
  }
  .p-sm-7 {
    padding: 7rem !important;
  }
  .p-sm-8 {
    padding: 8rem !important;
  }
  .p-sm-9 {
    padding: 9rem !important;
  }
  .p-sm-10 {
    padding: 10rem !important;
  }
  .p-sm-single {
    padding: 1rem !important;
  }
  .p-sm-double {
    padding: 2rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-sm-7 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-sm-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-sm-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-sm-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-sm-single {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-double {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-sm-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-sm-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-sm-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-sm-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-sm-single {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-double {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pt-sm-6 {
    padding-top: 6rem !important;
  }
  .pt-sm-7 {
    padding-top: 7rem !important;
  }
  .pt-sm-8 {
    padding-top: 8rem !important;
  }
  .pt-sm-9 {
    padding-top: 9rem !important;
  }
  .pt-sm-10 {
    padding-top: 10rem !important;
  }
  .pt-sm-single {
    padding-top: 1rem !important;
  }
  .pt-sm-double {
    padding-top: 2rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pe-sm-6 {
    padding-right: 6rem !important;
  }
  .pe-sm-7 {
    padding-right: 7rem !important;
  }
  .pe-sm-8 {
    padding-right: 8rem !important;
  }
  .pe-sm-9 {
    padding-right: 9rem !important;
  }
  .pe-sm-10 {
    padding-right: 10rem !important;
  }
  .pe-sm-single {
    padding-right: 1rem !important;
  }
  .pe-sm-double {
    padding-right: 2rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 6rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 7rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 8rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 9rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 10rem !important;
  }
  .pb-sm-single {
    padding-bottom: 1rem !important;
  }
  .pb-sm-double {
    padding-bottom: 2rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .ps-sm-6 {
    padding-left: 6rem !important;
  }
  .ps-sm-7 {
    padding-left: 7rem !important;
  }
  .ps-sm-8 {
    padding-left: 8rem !important;
  }
  .ps-sm-9 {
    padding-left: 9rem !important;
  }
  .ps-sm-10 {
    padding-left: 10rem !important;
  }
  .ps-sm-single {
    padding-left: 1rem !important;
  }
  .ps-sm-double {
    padding-left: 2rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .gap-sm-6 {
    gap: 6rem !important;
  }
  .gap-sm-7 {
    gap: 7rem !important;
  }
  .gap-sm-8 {
    gap: 8rem !important;
  }
  .gap-sm-9 {
    gap: 9rem !important;
  }
  .gap-sm-10 {
    gap: 10rem !important;
  }
  .gap-sm-single {
    gap: 1rem !important;
  }
  .gap-sm-double {
    gap: 2rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-6 {
    margin: 6rem !important;
  }
  .m-md-7 {
    margin: 7rem !important;
  }
  .m-md-8 {
    margin: 8rem !important;
  }
  .m-md-9 {
    margin: 9rem !important;
  }
  .m-md-10 {
    margin: 10rem !important;
  }
  .m-md-single {
    margin: 1rem !important;
  }
  .m-md-double {
    margin: 2rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-md-7 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-md-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-md-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-md-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-md-single {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-double {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-md-7 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-md-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-md-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-md-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-md-single {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-double {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-6 {
    margin-top: 6rem !important;
  }
  .mt-md-7 {
    margin-top: 7rem !important;
  }
  .mt-md-8 {
    margin-top: 8rem !important;
  }
  .mt-md-9 {
    margin-top: 9rem !important;
  }
  .mt-md-10 {
    margin-top: 10rem !important;
  }
  .mt-md-single {
    margin-top: 1rem !important;
  }
  .mt-md-double {
    margin-top: 2rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-6 {
    margin-right: 6rem !important;
  }
  .me-md-7 {
    margin-right: 7rem !important;
  }
  .me-md-8 {
    margin-right: 8rem !important;
  }
  .me-md-9 {
    margin-right: 9rem !important;
  }
  .me-md-10 {
    margin-right: 10rem !important;
  }
  .me-md-single {
    margin-right: 1rem !important;
  }
  .me-md-double {
    margin-right: 2rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-6 {
    margin-bottom: 6rem !important;
  }
  .mb-md-7 {
    margin-bottom: 7rem !important;
  }
  .mb-md-8 {
    margin-bottom: 8rem !important;
  }
  .mb-md-9 {
    margin-bottom: 9rem !important;
  }
  .mb-md-10 {
    margin-bottom: 10rem !important;
  }
  .mb-md-single {
    margin-bottom: 1rem !important;
  }
  .mb-md-double {
    margin-bottom: 2rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-6 {
    margin-left: 6rem !important;
  }
  .ms-md-7 {
    margin-left: 7rem !important;
  }
  .ms-md-8 {
    margin-left: 8rem !important;
  }
  .ms-md-9 {
    margin-left: 9rem !important;
  }
  .ms-md-10 {
    margin-left: 10rem !important;
  }
  .ms-md-single {
    margin-left: 1rem !important;
  }
  .ms-md-double {
    margin-left: 2rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .p-md-6 {
    padding: 6rem !important;
  }
  .p-md-7 {
    padding: 7rem !important;
  }
  .p-md-8 {
    padding: 8rem !important;
  }
  .p-md-9 {
    padding: 9rem !important;
  }
  .p-md-10 {
    padding: 10rem !important;
  }
  .p-md-single {
    padding: 1rem !important;
  }
  .p-md-double {
    padding: 2rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-md-7 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-md-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-md-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-md-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-md-single {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-double {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-md-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-md-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-md-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-md-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-md-single {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-double {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pt-md-6 {
    padding-top: 6rem !important;
  }
  .pt-md-7 {
    padding-top: 7rem !important;
  }
  .pt-md-8 {
    padding-top: 8rem !important;
  }
  .pt-md-9 {
    padding-top: 9rem !important;
  }
  .pt-md-10 {
    padding-top: 10rem !important;
  }
  .pt-md-single {
    padding-top: 1rem !important;
  }
  .pt-md-double {
    padding-top: 2rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pe-md-6 {
    padding-right: 6rem !important;
  }
  .pe-md-7 {
    padding-right: 7rem !important;
  }
  .pe-md-8 {
    padding-right: 8rem !important;
  }
  .pe-md-9 {
    padding-right: 9rem !important;
  }
  .pe-md-10 {
    padding-right: 10rem !important;
  }
  .pe-md-single {
    padding-right: 1rem !important;
  }
  .pe-md-double {
    padding-right: 2rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .pb-md-6 {
    padding-bottom: 6rem !important;
  }
  .pb-md-7 {
    padding-bottom: 7rem !important;
  }
  .pb-md-8 {
    padding-bottom: 8rem !important;
  }
  .pb-md-9 {
    padding-bottom: 9rem !important;
  }
  .pb-md-10 {
    padding-bottom: 10rem !important;
  }
  .pb-md-single {
    padding-bottom: 1rem !important;
  }
  .pb-md-double {
    padding-bottom: 2rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .ps-md-6 {
    padding-left: 6rem !important;
  }
  .ps-md-7 {
    padding-left: 7rem !important;
  }
  .ps-md-8 {
    padding-left: 8rem !important;
  }
  .ps-md-9 {
    padding-left: 9rem !important;
  }
  .ps-md-10 {
    padding-left: 10rem !important;
  }
  .ps-md-single {
    padding-left: 1rem !important;
  }
  .ps-md-double {
    padding-left: 2rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .gap-md-6 {
    gap: 6rem !important;
  }
  .gap-md-7 {
    gap: 7rem !important;
  }
  .gap-md-8 {
    gap: 8rem !important;
  }
  .gap-md-9 {
    gap: 9rem !important;
  }
  .gap-md-10 {
    gap: 10rem !important;
  }
  .gap-md-single {
    gap: 1rem !important;
  }
  .gap-md-double {
    gap: 2rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-6 {
    margin: 6rem !important;
  }
  .m-lg-7 {
    margin: 7rem !important;
  }
  .m-lg-8 {
    margin: 8rem !important;
  }
  .m-lg-9 {
    margin: 9rem !important;
  }
  .m-lg-10 {
    margin: 10rem !important;
  }
  .m-lg-single {
    margin: 1rem !important;
  }
  .m-lg-double {
    margin: 2rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-lg-7 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-lg-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-lg-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-lg-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-lg-single {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-double {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-lg-7 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-lg-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-lg-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-lg-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-lg-single {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-double {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-6 {
    margin-top: 6rem !important;
  }
  .mt-lg-7 {
    margin-top: 7rem !important;
  }
  .mt-lg-8 {
    margin-top: 8rem !important;
  }
  .mt-lg-9 {
    margin-top: 9rem !important;
  }
  .mt-lg-10 {
    margin-top: 10rem !important;
  }
  .mt-lg-single {
    margin-top: 1rem !important;
  }
  .mt-lg-double {
    margin-top: 2rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-6 {
    margin-right: 6rem !important;
  }
  .me-lg-7 {
    margin-right: 7rem !important;
  }
  .me-lg-8 {
    margin-right: 8rem !important;
  }
  .me-lg-9 {
    margin-right: 9rem !important;
  }
  .me-lg-10 {
    margin-right: 10rem !important;
  }
  .me-lg-single {
    margin-right: 1rem !important;
  }
  .me-lg-double {
    margin-right: 2rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 6rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 7rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 8rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 9rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 10rem !important;
  }
  .mb-lg-single {
    margin-bottom: 1rem !important;
  }
  .mb-lg-double {
    margin-bottom: 2rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-6 {
    margin-left: 6rem !important;
  }
  .ms-lg-7 {
    margin-left: 7rem !important;
  }
  .ms-lg-8 {
    margin-left: 8rem !important;
  }
  .ms-lg-9 {
    margin-left: 9rem !important;
  }
  .ms-lg-10 {
    margin-left: 10rem !important;
  }
  .ms-lg-single {
    margin-left: 1rem !important;
  }
  .ms-lg-double {
    margin-left: 2rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .p-lg-6 {
    padding: 6rem !important;
  }
  .p-lg-7 {
    padding: 7rem !important;
  }
  .p-lg-8 {
    padding: 8rem !important;
  }
  .p-lg-9 {
    padding: 9rem !important;
  }
  .p-lg-10 {
    padding: 10rem !important;
  }
  .p-lg-single {
    padding: 1rem !important;
  }
  .p-lg-double {
    padding: 2rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-lg-7 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-lg-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-lg-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-lg-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-lg-single {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-double {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-lg-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-lg-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-lg-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-lg-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-lg-single {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-double {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pt-lg-6 {
    padding-top: 6rem !important;
  }
  .pt-lg-7 {
    padding-top: 7rem !important;
  }
  .pt-lg-8 {
    padding-top: 8rem !important;
  }
  .pt-lg-9 {
    padding-top: 9rem !important;
  }
  .pt-lg-10 {
    padding-top: 10rem !important;
  }
  .pt-lg-single {
    padding-top: 1rem !important;
  }
  .pt-lg-double {
    padding-top: 2rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pe-lg-6 {
    padding-right: 6rem !important;
  }
  .pe-lg-7 {
    padding-right: 7rem !important;
  }
  .pe-lg-8 {
    padding-right: 8rem !important;
  }
  .pe-lg-9 {
    padding-right: 9rem !important;
  }
  .pe-lg-10 {
    padding-right: 10rem !important;
  }
  .pe-lg-single {
    padding-right: 1rem !important;
  }
  .pe-lg-double {
    padding-right: 2rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 6rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 7rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 8rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 9rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 10rem !important;
  }
  .pb-lg-single {
    padding-bottom: 1rem !important;
  }
  .pb-lg-double {
    padding-bottom: 2rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .ps-lg-6 {
    padding-left: 6rem !important;
  }
  .ps-lg-7 {
    padding-left: 7rem !important;
  }
  .ps-lg-8 {
    padding-left: 8rem !important;
  }
  .ps-lg-9 {
    padding-left: 9rem !important;
  }
  .ps-lg-10 {
    padding-left: 10rem !important;
  }
  .ps-lg-single {
    padding-left: 1rem !important;
  }
  .ps-lg-double {
    padding-left: 2rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .gap-lg-6 {
    gap: 6rem !important;
  }
  .gap-lg-7 {
    gap: 7rem !important;
  }
  .gap-lg-8 {
    gap: 8rem !important;
  }
  .gap-lg-9 {
    gap: 9rem !important;
  }
  .gap-lg-10 {
    gap: 10rem !important;
  }
  .gap-lg-single {
    gap: 1rem !important;
  }
  .gap-lg-double {
    gap: 2rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-6 {
    margin: 6rem !important;
  }
  .m-xl-7 {
    margin: 7rem !important;
  }
  .m-xl-8 {
    margin: 8rem !important;
  }
  .m-xl-9 {
    margin: 9rem !important;
  }
  .m-xl-10 {
    margin: 10rem !important;
  }
  .m-xl-single {
    margin: 1rem !important;
  }
  .m-xl-double {
    margin: 2rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xl-7 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-xl-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-xl-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-xl-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-xl-single {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-double {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xl-7 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-xl-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-xl-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-xl-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-xl-single {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-double {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-6 {
    margin-top: 6rem !important;
  }
  .mt-xl-7 {
    margin-top: 7rem !important;
  }
  .mt-xl-8 {
    margin-top: 8rem !important;
  }
  .mt-xl-9 {
    margin-top: 9rem !important;
  }
  .mt-xl-10 {
    margin-top: 10rem !important;
  }
  .mt-xl-single {
    margin-top: 1rem !important;
  }
  .mt-xl-double {
    margin-top: 2rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-6 {
    margin-right: 6rem !important;
  }
  .me-xl-7 {
    margin-right: 7rem !important;
  }
  .me-xl-8 {
    margin-right: 8rem !important;
  }
  .me-xl-9 {
    margin-right: 9rem !important;
  }
  .me-xl-10 {
    margin-right: 10rem !important;
  }
  .me-xl-single {
    margin-right: 1rem !important;
  }
  .me-xl-double {
    margin-right: 2rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 6rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 7rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 8rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 9rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 10rem !important;
  }
  .mb-xl-single {
    margin-bottom: 1rem !important;
  }
  .mb-xl-double {
    margin-bottom: 2rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-6 {
    margin-left: 6rem !important;
  }
  .ms-xl-7 {
    margin-left: 7rem !important;
  }
  .ms-xl-8 {
    margin-left: 8rem !important;
  }
  .ms-xl-9 {
    margin-left: 9rem !important;
  }
  .ms-xl-10 {
    margin-left: 10rem !important;
  }
  .ms-xl-single {
    margin-left: 1rem !important;
  }
  .ms-xl-double {
    margin-left: 2rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .p-xl-6 {
    padding: 6rem !important;
  }
  .p-xl-7 {
    padding: 7rem !important;
  }
  .p-xl-8 {
    padding: 8rem !important;
  }
  .p-xl-9 {
    padding: 9rem !important;
  }
  .p-xl-10 {
    padding: 10rem !important;
  }
  .p-xl-single {
    padding: 1rem !important;
  }
  .p-xl-double {
    padding: 2rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xl-7 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-xl-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-xl-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-xl-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-xl-single {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-double {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xl-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-xl-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-xl-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-xl-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-xl-single {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-double {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pt-xl-6 {
    padding-top: 6rem !important;
  }
  .pt-xl-7 {
    padding-top: 7rem !important;
  }
  .pt-xl-8 {
    padding-top: 8rem !important;
  }
  .pt-xl-9 {
    padding-top: 9rem !important;
  }
  .pt-xl-10 {
    padding-top: 10rem !important;
  }
  .pt-xl-single {
    padding-top: 1rem !important;
  }
  .pt-xl-double {
    padding-top: 2rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pe-xl-6 {
    padding-right: 6rem !important;
  }
  .pe-xl-7 {
    padding-right: 7rem !important;
  }
  .pe-xl-8 {
    padding-right: 8rem !important;
  }
  .pe-xl-9 {
    padding-right: 9rem !important;
  }
  .pe-xl-10 {
    padding-right: 10rem !important;
  }
  .pe-xl-single {
    padding-right: 1rem !important;
  }
  .pe-xl-double {
    padding-right: 2rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 6rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 7rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 8rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 9rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 10rem !important;
  }
  .pb-xl-single {
    padding-bottom: 1rem !important;
  }
  .pb-xl-double {
    padding-bottom: 2rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .ps-xl-6 {
    padding-left: 6rem !important;
  }
  .ps-xl-7 {
    padding-left: 7rem !important;
  }
  .ps-xl-8 {
    padding-left: 8rem !important;
  }
  .ps-xl-9 {
    padding-left: 9rem !important;
  }
  .ps-xl-10 {
    padding-left: 10rem !important;
  }
  .ps-xl-single {
    padding-left: 1rem !important;
  }
  .ps-xl-double {
    padding-left: 2rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .gap-xl-6 {
    gap: 6rem !important;
  }
  .gap-xl-7 {
    gap: 7rem !important;
  }
  .gap-xl-8 {
    gap: 8rem !important;
  }
  .gap-xl-9 {
    gap: 9rem !important;
  }
  .gap-xl-10 {
    gap: 10rem !important;
  }
  .gap-xl-single {
    gap: 1rem !important;
  }
  .gap-xl-double {
    gap: 2rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1500px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-6 {
    margin: 6rem !important;
  }
  .m-xxl-7 {
    margin: 7rem !important;
  }
  .m-xxl-8 {
    margin: 8rem !important;
  }
  .m-xxl-9 {
    margin: 9rem !important;
  }
  .m-xxl-10 {
    margin: 10rem !important;
  }
  .m-xxl-single {
    margin: 1rem !important;
  }
  .m-xxl-double {
    margin: 2rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xxl-7 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-xxl-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-xxl-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-xxl-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-xxl-single {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-double {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xxl-7 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-xxl-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-xxl-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-xxl-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-xxl-single {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-double {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-6 {
    margin-top: 6rem !important;
  }
  .mt-xxl-7 {
    margin-top: 7rem !important;
  }
  .mt-xxl-8 {
    margin-top: 8rem !important;
  }
  .mt-xxl-9 {
    margin-top: 9rem !important;
  }
  .mt-xxl-10 {
    margin-top: 10rem !important;
  }
  .mt-xxl-single {
    margin-top: 1rem !important;
  }
  .mt-xxl-double {
    margin-top: 2rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-6 {
    margin-right: 6rem !important;
  }
  .me-xxl-7 {
    margin-right: 7rem !important;
  }
  .me-xxl-8 {
    margin-right: 8rem !important;
  }
  .me-xxl-9 {
    margin-right: 9rem !important;
  }
  .me-xxl-10 {
    margin-right: 10rem !important;
  }
  .me-xxl-single {
    margin-right: 1rem !important;
  }
  .me-xxl-double {
    margin-right: 2rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 6rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 7rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 8rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 9rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 10rem !important;
  }
  .mb-xxl-single {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-double {
    margin-bottom: 2rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-6 {
    margin-left: 6rem !important;
  }
  .ms-xxl-7 {
    margin-left: 7rem !important;
  }
  .ms-xxl-8 {
    margin-left: 8rem !important;
  }
  .ms-xxl-9 {
    margin-left: 9rem !important;
  }
  .ms-xxl-10 {
    margin-left: 10rem !important;
  }
  .ms-xxl-single {
    margin-left: 1rem !important;
  }
  .ms-xxl-double {
    margin-left: 2rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .p-xxl-6 {
    padding: 6rem !important;
  }
  .p-xxl-7 {
    padding: 7rem !important;
  }
  .p-xxl-8 {
    padding: 8rem !important;
  }
  .p-xxl-9 {
    padding: 9rem !important;
  }
  .p-xxl-10 {
    padding: 10rem !important;
  }
  .p-xxl-single {
    padding: 1rem !important;
  }
  .p-xxl-double {
    padding: 2rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xxl-7 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-xxl-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-xxl-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-xxl-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-xxl-single {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-double {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xxl-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-xxl-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-xxl-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-xxl-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-xxl-single {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-double {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pt-xxl-6 {
    padding-top: 6rem !important;
  }
  .pt-xxl-7 {
    padding-top: 7rem !important;
  }
  .pt-xxl-8 {
    padding-top: 8rem !important;
  }
  .pt-xxl-9 {
    padding-top: 9rem !important;
  }
  .pt-xxl-10 {
    padding-top: 10rem !important;
  }
  .pt-xxl-single {
    padding-top: 1rem !important;
  }
  .pt-xxl-double {
    padding-top: 2rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pe-xxl-6 {
    padding-right: 6rem !important;
  }
  .pe-xxl-7 {
    padding-right: 7rem !important;
  }
  .pe-xxl-8 {
    padding-right: 8rem !important;
  }
  .pe-xxl-9 {
    padding-right: 9rem !important;
  }
  .pe-xxl-10 {
    padding-right: 10rem !important;
  }
  .pe-xxl-single {
    padding-right: 1rem !important;
  }
  .pe-xxl-double {
    padding-right: 2rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 6rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 7rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 8rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 9rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 10rem !important;
  }
  .pb-xxl-single {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-double {
    padding-bottom: 2rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .ps-xxl-6 {
    padding-left: 6rem !important;
  }
  .ps-xxl-7 {
    padding-left: 7rem !important;
  }
  .ps-xxl-8 {
    padding-left: 8rem !important;
  }
  .ps-xxl-9 {
    padding-left: 9rem !important;
  }
  .ps-xxl-10 {
    padding-left: 10rem !important;
  }
  .ps-xxl-single {
    padding-left: 1rem !important;
  }
  .ps-xxl-double {
    padding-left: 2rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .gap-xxl-6 {
    gap: 6rem !important;
  }
  .gap-xxl-7 {
    gap: 7rem !important;
  }
  .gap-xxl-8 {
    gap: 8rem !important;
  }
  .gap-xxl-9 {
    gap: 9rem !important;
  }
  .gap-xxl-10 {
    gap: 10rem !important;
  }
  .gap-xxl-single {
    gap: 1rem !important;
  }
  .gap-xxl-double {
    gap: 2rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 4rem !important;
  }
  .fs-2 {
    font-size: 3rem !important;
  }
  .fs-3 {
    font-size: 2rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.size-0 {
  width: 0 !important;
  height: 0 !important;
}

.size-0-w-svg svg {
  width: 0 !important;
  height: 0 !important;
}

.size-0-auto {
  width: 0 !important;
  height: auto !important;
}

.size-0-w-svg-auto svg {
  width: 0 !important;
  height: auto !important;
}

.size-1 {
  width: 0.25rem !important;
  height: 0.25rem !important;
}

.size-1-w-svg svg {
  width: 0.25rem !important;
  height: 0.25rem !important;
}

.size-1-auto {
  width: 0.25rem !important;
  height: auto !important;
}

.size-1-w-svg-auto svg {
  width: 0.25rem !important;
  height: auto !important;
}

.size-2 {
  width: 0.5rem !important;
  height: 0.5rem !important;
}

.size-2-w-svg svg {
  width: 0.5rem !important;
  height: 0.5rem !important;
}

.size-2-auto {
  width: 0.5rem !important;
  height: auto !important;
}

.size-2-w-svg-auto svg {
  width: 0.5rem !important;
  height: auto !important;
}

.size-3 {
  width: 1rem !important;
  height: 1rem !important;
}

.size-3-w-svg svg {
  width: 1rem !important;
  height: 1rem !important;
}

.size-3-auto {
  width: 1rem !important;
  height: auto !important;
}

.size-3-w-svg-auto svg {
  width: 1rem !important;
  height: auto !important;
}

.size-4 {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.size-4-w-svg svg {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.size-4-auto {
  width: 1.5rem !important;
  height: auto !important;
}

.size-4-w-svg-auto svg {
  width: 1.5rem !important;
  height: auto !important;
}

.size-5 {
  width: 3rem !important;
  height: 3rem !important;
}

.size-5-w-svg svg {
  width: 3rem !important;
  height: 3rem !important;
}

.size-5-auto {
  width: 3rem !important;
  height: auto !important;
}

.size-5-w-svg-auto svg {
  width: 3rem !important;
  height: auto !important;
}

.size-6 {
  width: 6rem !important;
  height: 6rem !important;
}

.size-6-w-svg svg {
  width: 6rem !important;
  height: 6rem !important;
}

.size-6-auto {
  width: 6rem !important;
  height: auto !important;
}

.size-6-w-svg-auto svg {
  width: 6rem !important;
  height: auto !important;
}

.size-7 {
  width: 7rem !important;
  height: 7rem !important;
}

.size-7-w-svg svg {
  width: 7rem !important;
  height: 7rem !important;
}

.size-7-auto {
  width: 7rem !important;
  height: auto !important;
}

.size-7-w-svg-auto svg {
  width: 7rem !important;
  height: auto !important;
}

.size-8 {
  width: 8rem !important;
  height: 8rem !important;
}

.size-8-w-svg svg {
  width: 8rem !important;
  height: 8rem !important;
}

.size-8-auto {
  width: 8rem !important;
  height: auto !important;
}

.size-8-w-svg-auto svg {
  width: 8rem !important;
  height: auto !important;
}

.size-9 {
  width: 9rem !important;
  height: 9rem !important;
}

.size-9-w-svg svg {
  width: 9rem !important;
  height: 9rem !important;
}

.size-9-auto {
  width: 9rem !important;
  height: auto !important;
}

.size-9-w-svg-auto svg {
  width: 9rem !important;
  height: auto !important;
}

.size-10 {
  width: 10rem !important;
  height: 10rem !important;
}

.size-10-w-svg svg {
  width: 10rem !important;
  height: 10rem !important;
}

.size-10-auto {
  width: 10rem !important;
  height: auto !important;
}

.size-10-w-svg-auto svg {
  width: 10rem !important;
  height: auto !important;
}

.size-single {
  width: 1rem !important;
  height: 1rem !important;
}

.size-single-w-svg svg {
  width: 1rem !important;
  height: 1rem !important;
}

.size-single-auto {
  width: 1rem !important;
  height: auto !important;
}

.size-single-w-svg-auto svg {
  width: 1rem !important;
  height: auto !important;
}

.size-double {
  width: 2rem !important;
  height: 2rem !important;
}

.size-double-w-svg svg {
  width: 2rem !important;
  height: 2rem !important;
}

.size-double-auto {
  width: 2rem !important;
  height: auto !important;
}

.size-double-w-svg-auto svg {
  width: 2rem !important;
  height: auto !important;
}

.border-top-1 {
  border-top-width: 1px !important;
}

.border-bottom-1 {
  border-top-width: 1px !important;
}

.border-top-2 {
  border-top-width: 2px !important;
}

.border-bottom-2 {
  border-top-width: 2px !important;
}

.border-top-3 {
  border-top-width: 3px !important;
}

.border-bottom-3 {
  border-top-width: 3px !important;
}

.border-top-4 {
  border-top-width: 4px !important;
}

.border-bottom-4 {
  border-top-width: 4px !important;
}

.border-top-5 {
  border-top-width: 5px !important;
}

.border-bottom-5 {
  border-top-width: 5px !important;
}

.w-xs-auto {
  width: auto !important;
}

.w-xs-100 {
  width: 100% !important;
}

.w-xs-75 {
  width: 75% !important;
}

.w-xs-50 {
  width: 50% !important;
}

.w-xs-25 {
  width: 25% !important;
}

@media (min-width: 576px) {
  .w-sm-auto {
    width: auto !important;
  }
}

@media (min-width: 576px) {
  .w-sm-100 {
    width: 100% !important;
  }
}

@media (min-width: 576px) {
  .w-sm-75 {
    width: 75% !important;
  }
}

@media (min-width: 576px) {
  .w-sm-50 {
    width: 50% !important;
  }
}

@media (min-width: 576px) {
  .w-sm-25 {
    width: 25% !important;
  }
}

@media (min-width: 768px) {
  .w-md-auto {
    width: auto !important;
  }
}

@media (min-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }
}

@media (min-width: 768px) {
  .w-md-75 {
    width: 75% !important;
  }
}

@media (min-width: 768px) {
  .w-md-50 {
    width: 50% !important;
  }
}

@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }
}

@media (min-width: 992px) {
  .w-lg-auto {
    width: auto !important;
  }
}

@media (min-width: 992px) {
  .w-lg-100 {
    width: 100% !important;
  }
}

@media (min-width: 992px) {
  .w-lg-75 {
    width: 75% !important;
  }
}

@media (min-width: 992px) {
  .w-lg-50 {
    width: 50% !important;
  }
}

@media (min-width: 992px) {
  .w-lg-25 {
    width: 25% !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-auto {
    width: auto !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-100 {
    width: 100% !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-75 {
    width: 75% !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-50 {
    width: 50% !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-25 {
    width: 25% !important;
  }
}

@media (min-width: 1500px) {
  .w-xxl-auto {
    width: auto !important;
  }
}

@media (min-width: 1500px) {
  .w-xxl-100 {
    width: 100% !important;
  }
}

@media (min-width: 1500px) {
  .w-xxl-75 {
    width: 75% !important;
  }
}

@media (min-width: 1500px) {
  .w-xxl-50 {
    width: 50% !important;
  }
}

@media (min-width: 1500px) {
  .w-xxl-25 {
    width: 25% !important;
  }
}

.has-black-color {
  color: #1d1d1b !important;
}

.has-black-background-color {
  background-color: #1d1d1b !important;
}

.icon-black path {
  stroke: #1d1d1b !important;
}

.bg-black {
  transition: background-color 300ms ease;
}

.bg-black-hover:hover {
  transition: background-color 300ms ease;
  background-color: #1d1d1b !important;
}

.stroke-black {
  stroke: #1d1d1b !important;
}

.fill-black {
  fill: #1d1d1b !important;
}

.has-white-color {
  color: #ffffff !important;
}

.has-white-background-color {
  background-color: #ffffff !important;
}

.icon-white path {
  stroke: #ffffff !important;
}

.bg-white {
  transition: background-color 300ms ease;
}

.bg-white-hover:hover {
  transition: background-color 300ms ease;
  background-color: #ffffff !important;
}

.stroke-white {
  stroke: #ffffff !important;
}

.fill-white {
  fill: #ffffff !important;
}

.has-border-grey-color {
  color: #566069 !important;
}

.has-border-grey-background-color {
  background-color: #566069 !important;
}

.icon-border-grey path {
  stroke: #566069 !important;
}

.bg-border-grey {
  transition: background-color 300ms ease;
}

.bg-border-grey-hover:hover {
  transition: background-color 300ms ease;
  background-color: #566069 !important;
}

.stroke-border-grey {
  stroke: #566069 !important;
}

.fill-border-grey {
  fill: #566069 !important;
}

.has-grey-color {
  color: #D6CCC2 !important;
}

.has-grey-background-color {
  background-color: #D6CCC2 !important;
}

.icon-grey path {
  stroke: #D6CCC2 !important;
}

.bg-grey {
  transition: background-color 300ms ease;
}

.bg-grey-hover:hover {
  transition: background-color 300ms ease;
  background-color: #D6CCC2 !important;
}

.stroke-grey {
  stroke: #D6CCC2 !important;
}

.fill-grey {
  fill: #D6CCC2 !important;
}

.has-light-grey-color {
  color: #EFEBE7 !important;
}

.has-light-grey-background-color {
  background-color: #EFEBE7 !important;
}

.icon-light-grey path {
  stroke: #EFEBE7 !important;
}

.bg-light-grey {
  transition: background-color 300ms ease;
}

.bg-light-grey-hover:hover {
  transition: background-color 300ms ease;
  background-color: #EFEBE7 !important;
}

.stroke-light-grey {
  stroke: #EFEBE7 !important;
}

.fill-light-grey {
  fill: #EFEBE7 !important;
}

.has-ultra-light-grey-color {
  color: white !important;
}

.has-ultra-light-grey-background-color {
  background-color: white !important;
}

.icon-ultra-light-grey path {
  stroke: white !important;
}

.bg-ultra-light-grey {
  transition: background-color 300ms ease;
}

.bg-ultra-light-grey-hover:hover {
  transition: background-color 300ms ease;
  background-color: white !important;
}

.stroke-ultra-light-grey {
  stroke: white !important;
}

.fill-ultra-light-grey {
  fill: white !important;
}

.has-midnight-color {
  color: #2A2069 !important;
}

.has-midnight-background-color {
  background-color: #2A2069 !important;
}

.icon-midnight path {
  stroke: #2A2069 !important;
}

.bg-midnight {
  transition: background-color 300ms ease;
}

.bg-midnight-hover:hover {
  transition: background-color 300ms ease;
  background-color: #2A2069 !important;
}

.stroke-midnight {
  stroke: #2A2069 !important;
}

.fill-midnight {
  fill: #2A2069 !important;
}

.has-purple-color {
  color: #5644d8 !important;
}

.has-purple-background-color {
  background-color: #5644d8 !important;
}

.icon-purple path {
  stroke: #5644d8 !important;
}

.bg-purple {
  transition: background-color 300ms ease;
}

.bg-purple-hover:hover {
  transition: background-color 300ms ease;
  background-color: #5644d8 !important;
}

.stroke-purple {
  stroke: #5644d8 !important;
}

.fill-purple {
  fill: #5644d8 !important;
}

.has-light-purple-color {
  color: #E1DBF7 !important;
}

.has-light-purple-background-color {
  background-color: #E1DBF7 !important;
}

.icon-light-purple path {
  stroke: #E1DBF7 !important;
}

.bg-light-purple {
  transition: background-color 300ms ease;
}

.bg-light-purple-hover:hover {
  transition: background-color 300ms ease;
  background-color: #E1DBF7 !important;
}

.stroke-light-purple {
  stroke: #E1DBF7 !important;
}

.fill-light-purple {
  fill: #E1DBF7 !important;
}

.has-ultra-light-purple-color {
  color: #F3F1FC !important;
}

.has-ultra-light-purple-background-color {
  background-color: #F3F1FC !important;
}

.icon-ultra-light-purple path {
  stroke: #F3F1FC !important;
}

.bg-ultra-light-purple {
  transition: background-color 300ms ease;
}

.bg-ultra-light-purple-hover:hover {
  transition: background-color 300ms ease;
  background-color: #F3F1FC !important;
}

.stroke-ultra-light-purple {
  stroke: #F3F1FC !important;
}

.fill-ultra-light-purple {
  fill: #F3F1FC !important;
}

.has-red-color {
  color: #F25F5C !important;
}

.has-red-background-color {
  background-color: #F25F5C !important;
}

.icon-red path {
  stroke: #F25F5C !important;
}

.bg-red {
  transition: background-color 300ms ease;
}

.bg-red-hover:hover {
  transition: background-color 300ms ease;
  background-color: #F25F5C !important;
}

.stroke-red {
  stroke: #F25F5C !important;
}

.fill-red {
  fill: #F25F5C !important;
}

.has-pink-color {
  color: #F3C1C0 !important;
}

.has-pink-background-color {
  background-color: #F3C1C0 !important;
}

.icon-pink path {
  stroke: #F3C1C0 !important;
}

.bg-pink {
  transition: background-color 300ms ease;
}

.bg-pink-hover:hover {
  transition: background-color 300ms ease;
  background-color: #F3C1C0 !important;
}

.stroke-pink {
  stroke: #F3C1C0 !important;
}

.fill-pink {
  fill: #F3C1C0 !important;
}

.has-light-pink-color {
  color: #FAE6E6 !important;
}

.has-light-pink-background-color {
  background-color: #FAE6E6 !important;
}

.icon-light-pink path {
  stroke: #FAE6E6 !important;
}

.bg-light-pink {
  transition: background-color 300ms ease;
}

.bg-light-pink-hover:hover {
  transition: background-color 300ms ease;
  background-color: #FAE6E6 !important;
}

.stroke-light-pink {
  stroke: #FAE6E6 !important;
}

.fill-light-pink {
  fill: #FAE6E6 !important;
}

.has-amber-color {
  color: #FFB840 !important;
}

.has-amber-background-color {
  background-color: #FFB840 !important;
}

.icon-amber path {
  stroke: #FFB840 !important;
}

.bg-amber {
  transition: background-color 300ms ease;
}

.bg-amber-hover:hover {
  transition: background-color 300ms ease;
  background-color: #FFB840 !important;
}

.stroke-amber {
  stroke: #FFB840 !important;
}

.fill-amber {
  fill: #FFB840 !important;
}

.has-bisque-color {
  color: #FFE6C1 !important;
}

.has-bisque-background-color {
  background-color: #FFE6C1 !important;
}

.icon-bisque path {
  stroke: #FFE6C1 !important;
}

.bg-bisque {
  transition: background-color 300ms ease;
}

.bg-bisque-hover:hover {
  transition: background-color 300ms ease;
  background-color: #FFE6C1 !important;
}

.stroke-bisque {
  stroke: #FFE6C1 !important;
}

.fill-bisque {
  fill: #FFE6C1 !important;
}

.has-light-bisque-color {
  color: #FFF5E6 !important;
}

.has-light-bisque-background-color {
  background-color: #FFF5E6 !important;
}

.icon-light-bisque path {
  stroke: #FFF5E6 !important;
}

.bg-light-bisque {
  transition: background-color 300ms ease;
}

.bg-light-bisque-hover:hover {
  transition: background-color 300ms ease;
  background-color: #FFF5E6 !important;
}

.stroke-light-bisque {
  stroke: #FFF5E6 !important;
}

.fill-light-bisque {
  fill: #FFF5E6 !important;
}

.border-top-transparent {
  border-top-color: transparent !important;
}

.border-bottom-transparent {
  border-bottom-color: transparent !important;
}

html {
  margin-top: 0px !important;
}

body {
  position: relative;
}
body.edit-post-visual-editor, body.mce-content-body {
  margin-top: 0px !important;
  padding: 0 !important;
}
body.is-purple-theme header#navigation nav.navbar {
  margin-top: 1rem;
  position: absolute !important;
  width: 100%;
  background-color: transparent !important;
}
body.is-purple-theme header#navigation.scrolling nav.navbar {
  background-color: transparent !important;
}
body.is-purple-theme section.block-hero, body.is-purple-theme section.customer-hero, body.is-purple-theme section.podcast-hero {
  background-color: #E1DBF7 !important;
}
body.is-purple-theme section.block-three-col-icon, body.is-purple-theme section.customer-bio {
  background-color: #F3F1FC !important;
}
body.is-yellow-theme header#navigation nav.navbar {
  margin-top: 1rem;
  position: absolute !important;
  width: 100%;
  background-color: transparent !important;
}
body.is-yellow-theme header#navigation.scrolling nav.navbar {
  background-color: transparent !important;
}
body.is-yellow-theme section.block-hero, body.is-yellow-theme section.customer-hero, body.is-yellow-theme section.podcast-hero {
  background-color: #FFE6C1 !important;
}
body.is-yellow-theme section.block-three-col-icon, body.is-yellow-theme section.customer-bio {
  background-color: #FFF5E6 !important;
}
body.is-sand-theme header#navigation nav.navbar {
  margin-top: 1rem;
  position: absolute !important;
  width: 100%;
  background-color: transparent !important;
}
body.is-sand-theme header#navigation.scrolling nav.navbar {
  background-color: transparent !important;
}
body.is-sand-theme section.block-hero, body.is-sand-theme section.customer-hero, body.is-sand-theme section.podcast-hero {
  background-color: #D6CCC2 !important;
}
body.is-sand-theme section.block-three-col-icon, body.is-sand-theme section.customer-bio {
  background-color: #EFEBE7 !important;
}
body.is-pink-theme header#navigation nav.navbar {
  margin-top: 1rem;
  position: absolute !important;
  width: 100%;
  background-color: transparent !important;
}
body.is-pink-theme header#navigation.scrolling nav.navbar {
  background-color: transparent !important;
}
body.is-pink-theme section.block-hero, body.is-pink-theme section.customer-hero, body.is-pink-theme section.podcast-hero {
  background-color: #F3C1C0 !important;
}
body.is-pink-theme section.block-three-col-icon, body.is-pink-theme section.customer-bio {
  background-color: #FAE6E6 !important;
}
body.is-home-theme header#navigation nav.navbar {
  margin-top: 1rem;
  position: absolute !important;
  width: 100%;
  background-color: transparent !important;
}
body.is-home-theme header#navigation.scrolling nav.navbar {
  background-color: transparent !important;
}
body.is-home-theme section.block-hero, body.is-home-theme section.customer-hero, body.is-home-theme section.podcast-hero {
  background-color: #F3C1C0 !important;
}
body.is-home-theme section.block-three-col-icon, body.is-home-theme section.customer-bio {
  background-color: #FAE6E6 !important;
}

.single-events.is-purple-theme,
.single-on-demand.is-purple-theme,
.single-customer-events.is-purple-theme,
.single-customer-on-demand.is-purple-theme,
.single-podcasts.is-purple-theme,
.single-ebooks-and-reports.is-purple-theme {
  background-color: #F3F1FC !important;
}
.single-events.is-yellow-theme,
.single-on-demand.is-yellow-theme,
.single-customer-events.is-yellow-theme,
.single-customer-on-demand.is-yellow-theme,
.single-podcasts.is-yellow-theme,
.single-ebooks-and-reports.is-yellow-theme {
  background-color: #FFF5E6 !important;
}
.single-events.is-sand-theme,
.single-on-demand.is-sand-theme,
.single-customer-events.is-sand-theme,
.single-customer-on-demand.is-sand-theme,
.single-podcasts.is-sand-theme,
.single-ebooks-and-reports.is-sand-theme {
  background-color: white !important;
}
.single-events.is-pink-theme,
.single-on-demand.is-pink-theme,
.single-customer-events.is-pink-theme,
.single-customer-on-demand.is-pink-theme,
.single-podcasts.is-pink-theme,
.single-ebooks-and-reports.is-pink-theme {
  background-color: #FAE6E6 !important;
}

#press-release-hero .wp-block-columns,
#press-release-content .wp-block-columns {
  max-width: 863px;
}
#press-release-hero .wp-block-columns p, #press-release-hero .wp-block-columns ul li,
#press-release-content .wp-block-columns p,
#press-release-content .wp-block-columns ul li {
  font-size: 20px;
  line-height: 1.7;
}
#press-release-hero .wp-block-columns p .icon, #press-release-hero .wp-block-columns ul li .icon,
#press-release-content .wp-block-columns p .icon,
#press-release-content .wp-block-columns ul li .icon {
  margin-top: 6px !important;
}
#press-release-hero .wp-block-columns p,
#press-release-content .wp-block-columns p {
  margin-bottom: 2rem;
}

.text-truncate-3 {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.text-truncate-2 {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.text-truncate-1 {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.cursor-pointer {
  cursor: pointer;
}

.centered-content {
  text-align: center;
}

.link-overlay,
.tooltip-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: block;
}

.without-ml {
  display: inline-block;
  margin: 0;
}
.without-ml > a {
  margin: 0 !important;
}

.mt-button-height {
  margin-top: -80px;
}
@media only screen and (max-device-width: 450px) {
  .mt-button-height {
    margin-top: 0 !important;
  }
}
@media screen and (max-width: 991px) {
  .mt-button-height {
    margin-top: 0 !important;
  }
}
@media screen and (max-width: 767px) {
  .mt-button-height {
    margin-top: 0 !important;
  }
}
@media screen and (max-width: 376px) {
  .mt-button-height {
    margin-top: 0 !important;
  }
}

.text-decoration-none {
  text-decoration: none;
}
.text-decoration-none.absolute {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

@media screen and (max-width: 767px) {
  .wp-embedded-content {
    width: 100%;
  }
}

@media screen and (max-width: 479px) {
  .no-mobile {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .d-sm-down {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .d-md-down {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .d-lg-down {
    display: none;
  }
}

@media (min-width: 576px) {
  .d-sm-up {
    display: none;
  }
}

@media (min-width: 768px) {
  .d-md-up {
    display: none;
  }
}

@media (min-width: 992px) {
  .d-lg-up {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .order-md-1-down {
    order: 1;
  }
}

@media (max-width: 767.98px) {
  .order-md-2-down {
    order: 2;
  }
}

@media (max-width: 767.98px) {
  .order-md-3-down {
    order: 3;
  }
}

@media (max-width: 767.98px) {
  .order-md-4-down {
    order: 4;
  }
}

@media (max-width: 767.98px) {
  .order-md-5-down {
    order: 5;
  }
}

@media (max-width: 767.98px) {
  .order-md-6-down {
    order: 6;
  }
}

.svg-container svg {
  width: 100%;
  height: auto;
}

.size-16 {
  width: 16px;
  height: 16px;
}

.size-24 {
  width: 24px;
  height: 24px;
}

.size-32 {
  width: 32px;
  height: 32px;
}

.size-48 {
  width: 48px;
  height: 48px;
}

.size-64 {
  width: 64px;
  height: 64px;
}

.size-96 {
  width: 96px;
  height: 96px;
}

.size-128 {
  width: 128px;
  height: 128px;
}

.size-256 {
  width: 256px;
  height: 256px;
}

.size-512 {
  width: 512px;
  height: 512px;
}

.rotate-0 {
  transform: translate(0, 0) rotate(0deg);
}

.rotate-90 {
  transform: translate(0, 0) rotate(90deg);
}

.rotate-180 {
  transform: translate(0, 0) rotate(180deg);
}

.rotate-270 {
  transform: translate(0, 0) rotate(270deg);
}

.is-rounded {
  border-radius: 2rem;
}

.cta-button-holder {
  display: flex;
  padding-top: 45px;
  justify-content: center;
}
@media screen and (max-width: 479px) {
  .cta-button-holder {
    padding-top: 30px;
  }
}

.know-more-arrow {
  position: absolute;
  left: 0;
  top: -5.5vw;
  right: auto;
  bottom: auto;
  width: 300px;
}
@media screen and (max-width: 991px) {
  .know-more-arrow {
    width: 200px;
  }
}
@media screen and (max-width: 767px) {
  .know-more-arrow {
    display: none;
  }
}

.know-more-cta-inner {
  padding-top: 140px;
  padding-bottom: 140px;
}
@media screen and (max-width: 991px) {
  .know-more-cta-inner {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 767px) {
  .know-more-cta-inner {
    padding-right: 58px;
    padding-left: 58px;
  }
}
@media screen and (max-width: 479px) {
  .know-more-cta-inner {
    padding-right: 20px;
    padding-left: 20px;
  }
}

._464 {
  max-width: 464px;
}

.button-holder-flex-45 {
  display: flex;
  padding-top: 45px;
}
@media screen and (max-width: 479px) {
  .button-holder-flex-45 {
    padding-top: 23px;
  }
}

.post-quotes-inner {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 479px) {
  .post-quotes-inner {
    display: block;
    max-width: 110%;
  }
}

.post-quote-mark {
  width: 52px;
  margin-right: 30px;
  flex: 0 0 auto;
}
@media screen and (max-width: 767px) {
  .post-quote-mark {
    width: 40px;
    margin-right: 15px;
  }
}
@media screen and (max-width: 479px) {
  .post-quote-mark {
    margin-top: 4px;
    float: left;
  }
}

.about-text-column {
  position: relative;
  z-index: 1;
  width: 835px;
  max-width: 69%;
  flex: 0 0 auto;
}
@media screen and (max-width: 991px) {
  .about-text-column {
    width: 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .about-text-column {
    padding-bottom: 17px;
  }
}
@media screen and (max-width: 479px) {
  .about-text-column {
    max-width: 100%;
  }
}

.about-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 190px 112px 0;
  float: right;
}
@media screen and (max-width: 992px) {
  .about-triangle {
    border-width: 0 138px 82px 0;
  }
}

.about-subhead {
  max-width: 474px;
}
@media screen and (max-width: 991px) {
  .about-subhead {
    max-width: none;
  }
}

.conversations-grid {
  display: grid;
  padding-top: 120px;
  justify-content: space-between;
  align-items: start;
  grid-auto-columns: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 16px;
  grid-template-columns: 1fr minmax(min-content, max-content) 1fr;
  grid-template-rows: auto;
}
@media screen and (max-width: 991px) {
  .conversations-grid {
    padding-top: 80px;
  }
}
@media screen and (max-width: 767px) {
  .conversations-grid {
    padding-top: 40px;
    grid-row-gap: 50px;
    grid-template-columns: 1fr;
  }
}
.conversations-grid.why {
  padding-top: 0;
}

.conversations-card {
  position: relative;
  max-width: 585px;
}
.conversations-card.card-3 {
  position: relative;
  left: 4vw;
}
@media screen and (max-width: 991px) {
  .conversations-card.card-3 {
    left: 0;
    top: -101px;
    margin-bottom: -80px;
  }
}
@media screen and (max-width: 767px) {
  .conversations-card.card-3 {
    top: 0;
    margin-bottom: 0;
    padding-top: 50px;
  }
}
.conversations-card > iframe {
  width: 100%;
}

.conversations-image {
  width: 100%;
  margin-bottom: 35px;
}

.conversations-button-holder {
  display: flex;
  padding-top: 30px;
  margin: 5px 10px;
  grid-auto-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 20px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  margin-left: 0 !important;
}
@media screen and (max-width: 991px) {
  .conversations-button-holder {
    display: flex;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 767px) {
  .conversations-button-holder {
    grid-row-gap: 10px;
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 479px) {
  .conversations-button-holder {
    padding-top: 17px;
    grid-template-columns: 1fr;
    display: block;
  }
}

.conversations-card-spacer {
  height: 337px;
}
@media screen and (max-width: 767px) {
  .conversations-card-spacer {
    display: none;
  }
}

.conversations-arrow-2 {
  position: absolute;
  width: 213px;
  margin-top: 7vw;
  margin-left: 7vw;
}
@media screen and (max-width: 991px) {
  .conversations-arrow-2 {
    width: 180px;
    margin-left: 0;
  }
}
@media screen and (max-width: 767px) {
  .conversations-arrow-2 {
    left: auto;
    top: auto;
    right: 9%;
    bottom: -5%;
    width: 140px;
    margin-top: 0;
  }
}
@media screen and (max-width: 479px) {
  .conversations-arrow-2 {
    bottom: -15%;
    width: 90px;
  }
}

.conversations-arrow-1 {
  position: absolute;
  left: auto;
  top: auto;
  right: -9vw;
  bottom: -137px;
  width: 183px;
  max-width: 40vw;
  transform-style: preserve-3d;
}
@media screen and (max-width: 991px) {
  .conversations-arrow-1 {
    right: -7.1vw;
    width: 164px;
  }
}
@media screen and (max-width: 767px) {
  .conversations-arrow-1 {
    left: auto;
    top: auto;
    right: 9%;
    bottom: -9%;
    width: 130px;
    transform: rotateX(0deg) rotateY(180deg) rotateZ(0deg);
  }
}
@media screen and (max-width: 479px) {
  .conversations-arrow-1 {
    bottom: -15%;
    width: 90px;
  }
}

.conversations-grid-divider {
  width: 10vw;
  min-width: 50px;
}

@media screen and (max-width: 1279px) and (min-width: 992px) {
  .s-image-block .p-16.subtitle {
    max-width: 330px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 991px) and (min-width: 767px) {
  .s-image-block .p-16.subtitle {
    max-width: 330px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 772px) {
  .s-image-block .p-16.subtitle {
    max-width: 230px;
    margin-left: auto;
    margin-right: auto;
  }
}

.check-icon-row {
  display: flex;
  padding-bottom: 10px;
  align-items: center;
}
.check-icon-row.last {
  padding-bottom: 0;
}
.check-icon-row.top {
  align-items: flex-start;
}

.product-check-icons {
  padding-top: 30px;
  padding-bottom: 15px;
}
@media screen and (max-width: 479px) {
  .product-check-icons {
    padding-top: 25px;
  }
}

section.block-large-quote .glide__slides {
  transition: none !important;
}
section.block-large-quote .glide__slides li {
  opacity: 0;
  transition: all 700ms ease-in-out;
}
section.block-large-quote li.glide__slide--active {
  opacity: 1;
}
section.block-large-quote .navigation {
  position: absolute;
  top: 50%;
}
section.block-large-quote .navigation.left {
  left: -100px;
}
section.block-large-quote .navigation.right {
  right: -100px;
}
@media (max-width: 767.98px) {
  section.block-large-quote .navigation {
    display: none;
  }
}

.tiles .tile {
  position: relative;
}
.tiles .tile .text {
  order: 1;
}
.tiles .tile .video {
  order: 2;
}
.tiles .tile .video .video-wrapper {
  position: relative;
  top: -30px;
}
@media (max-width: 991.98px) {
  .tiles .tile .video .video-wrapper {
    top: 0;
  }
}
.tiles .tile .video .video-wrapper img {
  position: absolute;
  bottom: -100px;
  left: -50px;
  max-width: 350px;
}
@media (max-width: 1199.98px) {
  .tiles .tile .video .video-wrapper img {
    max-width: 250px;
  }
}
@media (max-width: 991.98px) {
  .tiles .tile .video .video-wrapper img {
    display: none;
  }
}
.tiles .tile .tile-arrow {
  position: absolute;
  left: 50%;
  bottom: -50px;
  transform: translate(-50%, 0%) scaleX(-1);
  width: 100px;
  z-index: 100;
}
@media (max-width: 767.98px) {
  .tiles .tile .tile-arrow {
    display: none;
  }
}
.tiles .tile:nth-child(odd) .text {
  order: 2;
}
@media (max-width: 991.98px) {
  .tiles .tile:nth-child(odd) .text {
    order: 1;
  }
}
.tiles .tile:nth-child(odd) .video {
  order: 1;
}
@media (max-width: 991.98px) {
  .tiles .tile:nth-child(odd) .video {
    order: 2;
  }
}
.tiles .tile:nth-child(odd) .video img {
  right: -50px;
  left: auto;
}
.tiles .tile:nth-child(odd) .tile-arrow {
  transform: translate(-50%, 0%) scaleX(1);
}
.tiles .tile:last-of-type .tile-arrow {
  display: none;
}

@media (max-width: 575.98px) {
  .buttons {
    width: 100%;
  }
}
.buttons .btn, .buttons .mm-ocd .mm-ocd__content #mobile-menu a.btn, .mm-ocd .mm-ocd__content #mobile-menu .buttons a.btn, .buttons form input[type=submit], form .buttons input[type=submit] {
  margin: 0 1rem 1rem 0;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.navbar #button_book-a-demo {
  background: linear-gradient(-45deg, #2A2069, #5644d8, #F25F5C);
  background-size: 400% 400%;
  animation: gradient 8s ease infinite;
}

.btn, .mm-ocd .mm-ocd__content #mobile-menu a.btn, form input[type=submit] {
  font-family: "Athletics", sans-serif;
  margin: 0;
  text-align: left !important;
  z-index: 1;
}
@media (max-width: 575.98px) {
  .btn.btn-type-button, .mm-ocd .mm-ocd__content #mobile-menu a.btn-type-button.btn, form input.btn-type-button[type=submit] {
    width: auto;
  }
  .btn.btn-naked, .mm-ocd .mm-ocd__content #mobile-menu a.btn-naked.btn, form input.btn-naked[type=submit] {
    justify-content: start !important;
  }
  .btn.no-w-100, .mm-ocd .mm-ocd__content #mobile-menu a.no-w-100.btn, form input.no-w-100[type=submit] {
    width: auto;
  }
}
.btn .icon, .mm-ocd .mm-ocd__content #mobile-menu a.btn .icon, form input[type=submit] .icon {
  margin-left: 1rem;
}
.btn .icon .overlap, .mm-ocd .mm-ocd__content #mobile-menu a.btn .icon .overlap, form input[type=submit] .icon .overlap {
  position: absolute;
  top: 0;
  left: 0;
}
.btn .icon svg, .mm-ocd .mm-ocd__content #mobile-menu a.btn .icon svg, form input[type=submit] .icon svg {
  position: relative;
  opacity: 1;
  z-index: 10;
  width: 18px;
  height: 18px;
}
.btn .icon svg:not(.bend) path, .mm-ocd .mm-ocd__content #mobile-menu a.btn .icon svg:not(.bend) path, form input[type=submit] .icon svg:not(.bend) path {
  stroke: currentColor;
}
.btn .icon svg.bend, .mm-ocd .mm-ocd__content #mobile-menu a.btn .icon svg.bend, form input[type=submit] .icon svg.bend {
  opacity: 0;
}
.btn .icon svg.bend path, .mm-ocd .mm-ocd__content #mobile-menu a.btn .icon svg.bend path, form input[type=submit] .icon svg.bend path {
  stroke: currentColor;
}
.btn .icon svg:not(.btn-arrow), .mm-ocd .mm-ocd__content #mobile-menu a.btn .icon svg:not(.btn-arrow), form input[type=submit] .icon svg:not(.btn-arrow) {
  width: 20px;
  height: 20px;
}
.btn .icon.rotate-0, .mm-ocd .mm-ocd__content #mobile-menu a.btn .icon.rotate-0, form input[type=submit] .icon.rotate-0 {
  transform: translate(0, 0) rotate(0deg);
}
.btn .icon.rotate-90, .mm-ocd .mm-ocd__content #mobile-menu a.btn .icon.rotate-90, form input[type=submit] .icon.rotate-90 {
  transform: translate(0, 0) rotate(90deg);
}
.btn .icon.rotate-180, .mm-ocd .mm-ocd__content #mobile-menu a.btn .icon.rotate-180, form input[type=submit] .icon.rotate-180 {
  transform: translate(0, 0) rotate(180deg);
}
.btn .icon.rotate-270, .mm-ocd .mm-ocd__content #mobile-menu a.btn .icon.rotate-270, form input[type=submit] .icon.rotate-270 {
  transform: translate(0, 0) rotate(270deg);
}
.btn.btn-sm, .mm-ocd .mm-ocd__content #mobile-menu a.btn-sm.btn, form input.btn-sm[type=submit] {
  padding: 11px 1rem;
}
.btn.btn-sm svg, .mm-ocd .mm-ocd__content #mobile-menu a.btn-sm.btn svg, form input.btn-sm[type=submit] svg {
  width: 16px;
  height: 16px;
}
.btn.btn-sm svg:not(.btn-arrow), .mm-ocd .mm-ocd__content #mobile-menu a.btn-sm.btn svg:not(.btn-arrow), form input.btn-sm[type=submit] svg:not(.btn-arrow) {
  width: 18px;
  height: 18px;
}
.btn.btn-lg svg, .mm-ocd .mm-ocd__content #mobile-menu a.btn-lg.btn svg, form input.btn-lg[type=submit] svg {
  width: 20px;
  height: 20px;
}
.btn.btn-lg svg:not(.btn-arrow), .mm-ocd .mm-ocd__content #mobile-menu a.btn-lg.btn svg:not(.btn-arrow), form input.btn-lg[type=submit] svg:not(.btn-arrow) {
  width: 24px;
  height: 24px;
}
.btn[class*=btn-outline], .mm-ocd .mm-ocd__content #mobile-menu a[class*=btn-outline].btn, form input[class*=btn-outline][type=submit] {
  border-width: 2px;
}
.btn.btn-type-label_button, .mm-ocd .mm-ocd__content #mobile-menu a.btn-type-label_button.btn, form input.btn-type-label_button[type=submit] {
  border-radius: 50px;
  padding-right: 42px;
  padding-left: 42px;
}
.btn.btn-type-label_button svg.btn-arrow, .mm-ocd .mm-ocd__content #mobile-menu a.btn-type-label_button.btn svg.btn-arrow, form input.btn-type-label_button[type=submit] svg.btn-arrow {
  display: none;
}
.btn.btn-type-label_button.btn-sm, .mm-ocd .mm-ocd__content #mobile-menu a.btn-type-label_button.btn-sm.btn, form input.btn-type-label_button.btn-sm[type=submit] {
  padding-right: 32px;
  padding-left: 32px;
}
.btn.btn-type-label_button.btn-lg, .mm-ocd .mm-ocd__content #mobile-menu a.btn-type-label_button.btn-lg.btn, form input.btn-type-label_button.btn-lg[type=submit] {
  padding-right: 48px;
  padding-left: 48px;
}
.btn.btn-naked, .mm-ocd .mm-ocd__content #mobile-menu a.btn-naked.btn, form input.btn-naked[type=submit] {
  padding: 0 !important;
}
@media (min-width: 1200px) {
  .btn.btn-naked, .mm-ocd .mm-ocd__content #mobile-menu a.btn-naked.btn, form input.btn-naked[type=submit] {
    padding: 0 55px 0 0 !important;
  }
}
.btn.btn-naked.btn-type-label_button, .mm-ocd .mm-ocd__content #mobile-menu a.btn-naked.btn-type-label_button.btn, form input.btn-naked.btn-type-label_button[type=submit] {
  padding: 17px 20px 17px 0px !important;
}
.btn.btn-no-arrow.btn-sm, .mm-ocd .mm-ocd__content #mobile-menu a.btn-no-arrow.btn-sm.btn, form input.btn-no-arrow.btn-sm[type=submit] {
  padding: 11px 1rem 10px !important;
}
.btn.btn-no-arrow.btn-md, .mm-ocd .mm-ocd__content #mobile-menu a.btn-no-arrow.btn-md.btn, form input.btn-no-arrow.btn-md[type=submit] {
  padding: 13px 20px !important;
}
.btn.btn-no-arrow.btn-lg, .mm-ocd .mm-ocd__content #mobile-menu a.btn-no-arrow.btn-lg.btn, form input.btn-no-arrow.btn-lg[type=submit] {
  padding: 17px 24px !important;
}
.btn.btn-no-arrow .icon, .mm-ocd .mm-ocd__content #mobile-menu a.btn-no-arrow.btn .icon, form input.btn-no-arrow[type=submit] .icon {
  display: none;
}
.btn.btn-bubble, .mm-ocd .mm-ocd__content #mobile-menu a.btn-bubble.btn, form input.btn-bubble[type=submit] {
  position: relative;
}
.btn.btn-bubble:before, .mm-ocd .mm-ocd__content #mobile-menu a.btn-bubble.btn:before, form input.btn-bubble[type=submit]:before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0px;
  width: 0;
  height: 0;
  border-top: 25px solid #1d1d1b;
  border-right: 40px solid transparent;
}
.btn.btn-icon, .mm-ocd .mm-ocd__content #mobile-menu a.btn-icon.btn, form input.btn-icon[type=submit] {
  padding: 14px;
  border-radius: 50px;
}
.btn.btn-icon .icon, .mm-ocd .mm-ocd__content #mobile-menu a.btn-icon.btn .icon, form input.btn-icon[type=submit] .icon {
  margin-left: 0;
}
.btn.btn-icon svg path, .mm-ocd .mm-ocd__content #mobile-menu a.btn-icon.btn svg path, form input.btn-icon[type=submit] svg path {
  stroke: currentColor;
  fill: currentColor;
}
.btn.btn-icon.btn-sm, .mm-ocd .mm-ocd__content #mobile-menu a.btn-icon.btn-sm.btn, form input.btn-icon.btn-sm[type=submit] {
  padding: 13px;
}
.btn.btn-icon.btn-lg, .mm-ocd .mm-ocd__content #mobile-menu a.btn-icon.btn-lg.btn, form input.btn-icon.btn-lg[type=submit] {
  padding: 17px;
}
@media (max-width: 767.98px) {
  .btn.btn-icon, .mm-ocd .mm-ocd__content #mobile-menu a.btn-icon.btn, form input.btn-icon[type=submit] {
    width: auto;
  }
}
.btn.btn-no-link:hover, .mm-ocd .mm-ocd__content #mobile-menu a.btn-no-link.btn:hover, form input.btn-no-link[type=submit]:hover {
  cursor: default;
}
.btn:hover .btn-arrow, .mm-ocd .mm-ocd__content #mobile-menu a.btn:hover .btn-arrow, form input[type=submit]:hover .btn-arrow, .btn.active .btn-arrow, .mm-ocd .mm-ocd__content #mobile-menu a.active.btn .btn-arrow, form input.active[type=submit] .btn-arrow, .card:hover .btn .btn-arrow, .card:hover .mm-ocd .mm-ocd__content #mobile-menu a.btn .btn-arrow, .mm-ocd .mm-ocd__content #mobile-menu .card:hover a.btn .btn-arrow, .card:hover form input[type=submit] .btn-arrow, form .card:hover input[type=submit] .btn-arrow {
  opacity: 0;
  transition: 200ms all ease;
}
.btn:hover .btn-arrow.bend, .mm-ocd .mm-ocd__content #mobile-menu a.btn:hover .btn-arrow.bend, form input[type=submit]:hover .btn-arrow.bend, .btn.active .btn-arrow.bend, .mm-ocd .mm-ocd__content #mobile-menu a.active.btn .btn-arrow.bend, form input.active[type=submit] .btn-arrow.bend, .card:hover .btn .btn-arrow.bend, .card:hover .mm-ocd .mm-ocd__content #mobile-menu a.btn .btn-arrow.bend, .mm-ocd .mm-ocd__content #mobile-menu .card:hover a.btn .btn-arrow.bend, .card:hover form input[type=submit] .btn-arrow.bend, form .card:hover input[type=submit] .btn-arrow.bend {
  opacity: 1;
}
.btn:hover:not(.btn-naked, .btn-), .mm-ocd .mm-ocd__content #mobile-menu a.btn:hover:not(.btn-naked, .btn-), form input[type=submit]:hover:not(.btn-naked, .btn-) {
  transform: scale(0.97);
  transition: 200ms all ease;
}
.btn:active:not(.btn-no-link), .mm-ocd .mm-ocd__content #mobile-menu a.btn:active:not(.btn-no-link), form input[type=submit]:active:not(.btn-no-link) {
  transform: translate(0%, 3px);
}
.btn:focus:not(.btn-no-link), .mm-ocd .mm-ocd__content #mobile-menu a.btn:focus:not(.btn-no-link), form input[type=submit]:focus:not(.btn-no-link) {
  box-shadow: unset !important;
}
.btn.btn-black:hover, .mm-ocd .mm-ocd__content #mobile-menu a.btn-black.btn:hover, form input.btn-black[type=submit]:hover, .form-wrapper.submit-button-color-black input.btn[type=submit]:hover, .form-wrapper.submit-button-color-black form input[type=submit]:hover, form .form-wrapper.submit-button-color-black input[type=submit]:hover {
  background-color: rgb(74.2, 74.2, 72.6);
  border-color: rgb(74.2, 74.2, 72.6);
}

.btn-black.bubble:before, .form-wrapper.submit-button-color-black input.bubble[type=submit]:before {
  border-top-color: #1d1d1b;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-black.bubble:hover::before, .form-wrapper.submit-button-color-black input.bubble[type=submit]:hover::before {
  border-top-color: rgb(24.65, 24.65, 22.95);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-black.bubble:hover.btn-black::before, .form-wrapper.submit-button-color-black input.bubble[type=submit]:hover::before {
  border-top-color: rgb(74.2, 74.2, 72.6);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-naked-black {
  color: #1d1d1b !important;
}

.btn-outline-black .btn-arrow.straight path {
  stroke: #1d1d1b !important;
}
.btn-outline-black .btn-arrow:not(.bend) path {
  stroke: currentcolor;
}
.btn-outline-black .btn-arrow.bend path {
  stroke: currentcolor;
}

.btn-white.bubble:before, .form-wrapper.submit-button-color-white input.bubble[type=submit]:before {
  border-top-color: #ffffff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-white.bubble:hover::before, .form-wrapper.submit-button-color-white input.bubble[type=submit]:hover::before {
  border-top-color: white;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-white.bubble:hover.btn-black::before, .form-wrapper.submit-button-color-white input.bubble[type=submit]:hover.btn-black::before, .form-wrapper.submit-button-color-black input.btn-white.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-black .form-wrapper.submit-button-color-white input.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-white .form-wrapper.submit-button-color-black input.bubble[type=submit]:hover::before {
  border-top-color: rgb(74.2, 74.2, 72.6);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-naked-white {
  color: #ffffff !important;
}

.btn-outline-white .btn-arrow.straight path {
  stroke: #ffffff !important;
}
.btn-outline-white .btn-arrow:not(.bend) path {
  stroke: currentcolor;
}
.btn-outline-white .btn-arrow.bend path {
  stroke: currentcolor;
}

.btn-border-grey.bubble:before, .form-wrapper.submit-button-color-border-grey input.bubble[type=submit]:before {
  border-top-color: #566069;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-border-grey.bubble:hover::before, .form-wrapper.submit-button-color-border-grey input.bubble[type=submit]:hover::before {
  border-top-color: rgb(73.1, 81.6, 89.25);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-border-grey.bubble:hover.btn-black::before, .form-wrapper.submit-button-color-border-grey input.bubble[type=submit]:hover.btn-black::before, .form-wrapper.submit-button-color-black input.btn-border-grey.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-black .form-wrapper.submit-button-color-border-grey input.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-border-grey .form-wrapper.submit-button-color-black input.bubble[type=submit]:hover::before {
  border-top-color: rgb(74.2, 74.2, 72.6);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-naked-border-grey {
  color: #566069 !important;
}

.btn-outline-border-grey .btn-arrow.straight path {
  stroke: #566069 !important;
}
.btn-outline-border-grey .btn-arrow:not(.bend) path {
  stroke: currentcolor;
}
.btn-outline-border-grey .btn-arrow.bend path {
  stroke: currentcolor;
}

.btn-grey.bubble:before, .form-wrapper.submit-button-color-grey input.bubble[type=submit]:before {
  border-top-color: #D6CCC2;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-grey.bubble:hover::before, .form-wrapper.submit-button-color-grey input.bubble[type=submit]:hover::before {
  border-top-color: rgb(220.15, 211.65, 203.15);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-grey.bubble:hover.btn-black::before, .form-wrapper.submit-button-color-grey input.bubble[type=submit]:hover.btn-black::before, .form-wrapper.submit-button-color-black input.btn-grey.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-black .form-wrapper.submit-button-color-grey input.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-grey .form-wrapper.submit-button-color-black input.bubble[type=submit]:hover::before {
  border-top-color: rgb(74.2, 74.2, 72.6);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-naked-grey {
  color: #D6CCC2 !important;
}

.btn-outline-grey .btn-arrow.straight path {
  stroke: #D6CCC2 !important;
}
.btn-outline-grey .btn-arrow:not(.bend) path {
  stroke: currentcolor;
}
.btn-outline-grey .btn-arrow.bend path {
  stroke: currentcolor;
}

.btn-light-grey.bubble:before, .form-wrapper.submit-button-color-light-grey input.bubble[type=submit]:before {
  border-top-color: #EFEBE7;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-light-grey.bubble:hover::before, .form-wrapper.submit-button-color-light-grey input.bubble[type=submit]:hover::before {
  border-top-color: rgb(241.4, 238, 234.6);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-light-grey.bubble:hover.btn-black::before, .form-wrapper.submit-button-color-light-grey input.bubble[type=submit]:hover.btn-black::before, .form-wrapper.submit-button-color-black input.btn-light-grey.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-black .form-wrapper.submit-button-color-light-grey input.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-light-grey .form-wrapper.submit-button-color-black input.bubble[type=submit]:hover::before {
  border-top-color: rgb(74.2, 74.2, 72.6);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-naked-light-grey {
  color: #EFEBE7 !important;
}

.btn-outline-light-grey .btn-arrow.straight path {
  stroke: #EFEBE7 !important;
}
.btn-outline-light-grey .btn-arrow:not(.bend) path {
  stroke: currentcolor;
}
.btn-outline-light-grey .btn-arrow.bend path {
  stroke: currentcolor;
}

.btn-ultra-light-grey.bubble:before, .form-wrapper.submit-button-color-ultra-light-grey input.bubble[type=submit]:before {
  border-top-color: white;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-ultra-light-grey.bubble:hover::before, .form-wrapper.submit-button-color-ultra-light-grey input.bubble[type=submit]:hover::before {
  border-top-color: white;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-ultra-light-grey.bubble:hover.btn-black::before, .form-wrapper.submit-button-color-ultra-light-grey input.bubble[type=submit]:hover.btn-black::before, .form-wrapper.submit-button-color-black input.btn-ultra-light-grey.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-black .form-wrapper.submit-button-color-ultra-light-grey input.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-ultra-light-grey .form-wrapper.submit-button-color-black input.bubble[type=submit]:hover::before {
  border-top-color: rgb(74.2, 74.2, 72.6);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-naked-ultra-light-grey {
  color: white !important;
}

.btn-outline-ultra-light-grey .btn-arrow.straight path {
  stroke: white !important;
}
.btn-outline-ultra-light-grey .btn-arrow:not(.bend) path {
  stroke: currentcolor;
}
.btn-outline-ultra-light-grey .btn-arrow.bend path {
  stroke: currentcolor;
}

.btn-midnight.bubble:before, .form-wrapper.submit-button-color-midnight input.bubble[type=submit]:before {
  border-top-color: #2A2069;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-midnight.bubble:hover::before, .form-wrapper.submit-button-color-midnight input.bubble[type=submit]:hover::before {
  border-top-color: rgb(35.7, 27.2, 89.25);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-midnight.bubble:hover.btn-black::before, .form-wrapper.submit-button-color-midnight input.bubble[type=submit]:hover.btn-black::before, .form-wrapper.submit-button-color-black input.btn-midnight.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-black .form-wrapper.submit-button-color-midnight input.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-midnight .form-wrapper.submit-button-color-black input.bubble[type=submit]:hover::before {
  border-top-color: rgb(74.2, 74.2, 72.6);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-naked-midnight {
  color: #2A2069 !important;
}

.btn-outline-midnight .btn-arrow.straight path {
  stroke: #2A2069 !important;
}
.btn-outline-midnight .btn-arrow:not(.bend) path {
  stroke: currentcolor;
}
.btn-outline-midnight .btn-arrow.bend path {
  stroke: currentcolor;
}

.btn-purple.bubble:before, .mm-ocd .mm-ocd__content #mobile-menu a.bubble.btn-book-demo:before, form input.bubble[type=submit]:before, .form-wrapper.submit-button-color-purple input.bubble[type=submit]:before {
  border-top-color: #5644d8;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-purple.bubble:hover::before, .mm-ocd .mm-ocd__content #mobile-menu a.bubble.btn-book-demo:hover::before, form input.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-purple input.bubble[type=submit]:hover::before {
  border-top-color: rgb(73.1, 57.8, 183.6);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-purple.bubble:hover.btn-black::before, .mm-ocd .mm-ocd__content #mobile-menu a.bubble.btn-book-demo:hover.btn-black::before, form input.bubble[type=submit]:hover.btn-black::before, .form-wrapper.submit-button-color-purple input.bubble[type=submit]:hover.btn-black::before, .form-wrapper.submit-button-color-black input.btn-purple.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-black form input.bubble[type=submit]:hover::before, form .form-wrapper.submit-button-color-black input.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-black .form-wrapper.submit-button-color-purple input.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-purple .form-wrapper.submit-button-color-black input.bubble[type=submit]:hover::before {
  border-top-color: rgb(74.2, 74.2, 72.6);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-naked-purple {
  color: #5644d8 !important;
}

.btn-outline-purple .btn-arrow.straight path {
  stroke: #5644d8 !important;
}
.btn-outline-purple .btn-arrow:not(.bend) path {
  stroke: currentcolor;
}
.btn-outline-purple .btn-arrow.bend path {
  stroke: currentcolor;
}

.btn-light-purple.bubble:before, .form-wrapper.submit-button-color-light-purple input.bubble[type=submit]:before {
  border-top-color: #E1DBF7;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-light-purple.bubble:hover::before, .form-wrapper.submit-button-color-light-purple input.bubble[type=submit]:hover::before {
  border-top-color: rgb(229.5, 224.4, 248.2);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-light-purple.bubble:hover.btn-black::before, .form-wrapper.submit-button-color-light-purple input.bubble[type=submit]:hover.btn-black::before, .form-wrapper.submit-button-color-black input.btn-light-purple.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-black .form-wrapper.submit-button-color-light-purple input.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-light-purple .form-wrapper.submit-button-color-black input.bubble[type=submit]:hover::before {
  border-top-color: rgb(74.2, 74.2, 72.6);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-naked-light-purple {
  color: #E1DBF7 !important;
}

.btn-outline-light-purple .btn-arrow.straight path {
  stroke: #E1DBF7 !important;
}
.btn-outline-light-purple .btn-arrow:not(.bend) path {
  stroke: currentcolor;
}
.btn-outline-light-purple .btn-arrow.bend path {
  stroke: currentcolor;
}

.btn-ultra-light-purple.bubble:before, .form-wrapper.submit-button-color-ultra-light-purple input.bubble[type=submit]:before {
  border-top-color: #F3F1FC;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-ultra-light-purple.bubble:hover::before, .form-wrapper.submit-button-color-ultra-light-purple input.bubble[type=submit]:hover::before {
  border-top-color: rgb(244.8, 243.1, 252.45);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-ultra-light-purple.bubble:hover.btn-black::before, .form-wrapper.submit-button-color-ultra-light-purple input.bubble[type=submit]:hover.btn-black::before, .form-wrapper.submit-button-color-black input.btn-ultra-light-purple.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-black .form-wrapper.submit-button-color-ultra-light-purple input.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-ultra-light-purple .form-wrapper.submit-button-color-black input.bubble[type=submit]:hover::before {
  border-top-color: rgb(74.2, 74.2, 72.6);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-naked-ultra-light-purple {
  color: #F3F1FC !important;
}

.btn-outline-ultra-light-purple .btn-arrow.straight path {
  stroke: #F3F1FC !important;
}
.btn-outline-ultra-light-purple .btn-arrow:not(.bend) path {
  stroke: currentcolor;
}
.btn-outline-ultra-light-purple .btn-arrow.bend path {
  stroke: currentcolor;
}

.btn-red.bubble:before, .form-wrapper.submit-button-color-red input.bubble[type=submit]:before {
  border-top-color: #F25F5C;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-red.bubble:hover::before, .form-wrapper.submit-button-color-red input.bubble[type=submit]:hover::before {
  border-top-color: rgb(243.95, 119, 116.45);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-red.bubble:hover.btn-black::before, .form-wrapper.submit-button-color-red input.bubble[type=submit]:hover.btn-black::before, .form-wrapper.submit-button-color-black input.btn-red.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-black .form-wrapper.submit-button-color-red input.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-red .form-wrapper.submit-button-color-black input.bubble[type=submit]:hover::before {
  border-top-color: rgb(74.2, 74.2, 72.6);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-naked-red {
  color: #F25F5C !important;
}

.btn-outline-red .btn-arrow.straight path {
  stroke: #F25F5C !important;
}
.btn-outline-red .btn-arrow:not(.bend) path {
  stroke: currentcolor;
}
.btn-outline-red .btn-arrow.bend path {
  stroke: currentcolor;
}

.btn-pink.bubble:before, .form-wrapper.submit-button-color-pink input.bubble[type=submit]:before {
  border-top-color: #F3C1C0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-pink.bubble:hover::before, .form-wrapper.submit-button-color-pink input.bubble[type=submit]:hover::before {
  border-top-color: rgb(244.8, 202.3, 201.45);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-pink.bubble:hover.btn-black::before, .form-wrapper.submit-button-color-pink input.bubble[type=submit]:hover.btn-black::before, .form-wrapper.submit-button-color-black input.btn-pink.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-black .form-wrapper.submit-button-color-pink input.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-pink .form-wrapper.submit-button-color-black input.bubble[type=submit]:hover::before {
  border-top-color: rgb(74.2, 74.2, 72.6);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-naked-pink {
  color: #F3C1C0 !important;
}

.btn-outline-pink .btn-arrow.straight path {
  stroke: #F3C1C0 !important;
}
.btn-outline-pink .btn-arrow:not(.bend) path {
  stroke: currentcolor;
}
.btn-outline-pink .btn-arrow.bend path {
  stroke: currentcolor;
}

.btn-light-pink.bubble:before, .form-wrapper.submit-button-color-light-pink input.bubble[type=submit]:before {
  border-top-color: #FAE6E6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-light-pink.bubble:hover::before, .form-wrapper.submit-button-color-light-pink input.bubble[type=submit]:hover::before {
  border-top-color: rgb(250.75, 233.75, 233.75);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-light-pink.bubble:hover.btn-black::before, .form-wrapper.submit-button-color-light-pink input.bubble[type=submit]:hover.btn-black::before, .form-wrapper.submit-button-color-black input.btn-light-pink.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-black .form-wrapper.submit-button-color-light-pink input.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-light-pink .form-wrapper.submit-button-color-black input.bubble[type=submit]:hover::before {
  border-top-color: rgb(74.2, 74.2, 72.6);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-naked-light-pink {
  color: #FAE6E6 !important;
}

.btn-outline-light-pink .btn-arrow.straight path {
  stroke: #FAE6E6 !important;
}
.btn-outline-light-pink .btn-arrow:not(.bend) path {
  stroke: currentcolor;
}
.btn-outline-light-pink .btn-arrow.bend path {
  stroke: currentcolor;
}

.btn-amber.bubble:before, .form-wrapper.submit-button-color-amber input.bubble[type=submit]:before {
  border-top-color: #FFB840;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-amber.bubble:hover::before, .form-wrapper.submit-button-color-amber input.bubble[type=submit]:hover::before {
  border-top-color: rgb(255, 194.65, 92.65);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-amber.bubble:hover.btn-black::before, .form-wrapper.submit-button-color-amber input.bubble[type=submit]:hover.btn-black::before, .form-wrapper.submit-button-color-black input.btn-amber.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-black .form-wrapper.submit-button-color-amber input.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-amber .form-wrapper.submit-button-color-black input.bubble[type=submit]:hover::before {
  border-top-color: rgb(74.2, 74.2, 72.6);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-naked-amber {
  color: #FFB840 !important;
}

.btn-outline-amber .btn-arrow.straight path {
  stroke: #FFB840 !important;
}
.btn-outline-amber .btn-arrow:not(.bend) path {
  stroke: currentcolor;
}
.btn-outline-amber .btn-arrow.bend path {
  stroke: currentcolor;
}

.btn-bisque.bubble:before, .form-wrapper.submit-button-color-bisque input.bubble[type=submit]:before {
  border-top-color: #FFE6C1;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-bisque.bubble:hover::before, .form-wrapper.submit-button-color-bisque input.bubble[type=submit]:hover::before {
  border-top-color: rgb(255, 233.75, 202.3);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-bisque.bubble:hover.btn-black::before, .form-wrapper.submit-button-color-bisque input.bubble[type=submit]:hover.btn-black::before, .form-wrapper.submit-button-color-black input.btn-bisque.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-black .form-wrapper.submit-button-color-bisque input.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-bisque .form-wrapper.submit-button-color-black input.bubble[type=submit]:hover::before {
  border-top-color: rgb(74.2, 74.2, 72.6);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-naked-bisque {
  color: #FFE6C1 !important;
}

.btn-outline-bisque .btn-arrow.straight path {
  stroke: #FFE6C1 !important;
}
.btn-outline-bisque .btn-arrow:not(.bend) path {
  stroke: currentcolor;
}
.btn-outline-bisque .btn-arrow.bend path {
  stroke: currentcolor;
}

.btn-light-bisque.bubble:before, .form-wrapper.submit-button-color-light-bisque input.bubble[type=submit]:before {
  border-top-color: #FFF5E6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-light-bisque.bubble:hover::before, .form-wrapper.submit-button-color-light-bisque input.bubble[type=submit]:hover::before {
  border-top-color: rgb(255, 246.5, 233.75);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-light-bisque.bubble:hover.btn-black::before, .form-wrapper.submit-button-color-light-bisque input.bubble[type=submit]:hover.btn-black::before, .form-wrapper.submit-button-color-black input.btn-light-bisque.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-black .form-wrapper.submit-button-color-light-bisque input.bubble[type=submit]:hover::before, .form-wrapper.submit-button-color-light-bisque .form-wrapper.submit-button-color-black input.bubble[type=submit]:hover::before {
  border-top-color: rgb(74.2, 74.2, 72.6);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-naked-light-bisque {
  color: #FFF5E6 !important;
}

.btn-outline-light-bisque .btn-arrow.straight path {
  stroke: #FFF5E6 !important;
}
.btn-outline-light-bisque .btn-arrow:not(.bend) path {
  stroke: currentcolor;
}
.btn-outline-light-bisque .btn-arrow.bend path {
  stroke: currentcolor;
}

.posts-cta-curl {
  z-index: 1;
}

.meetings-iframe-container {
  margin-top: 150px;
}

.form-preview {
  color: #1d1d1b;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  z-index: 100;
  position: relative;
  max-width: 600px;
}

.form-wrapper {
  color: #1d1d1b;
  background-color: #ffffff;
  z-index: 100;
}
.form-wrapper.inline form {
  display: flex;
  flex-direction: row;
}
.form-wrapper.inline form label {
  display: none;
}
.form-wrapper.inline form .hs-form-field {
  margin-bottom: 0;
  min-width: 275px;
  margin-right: 1rem;
}
.form-wrapper.inline form input[type=submit] {
  margin: 0 !important;
}
.form-wrapper.inline .submitted-message {
  color: inherit;
}
.form-wrapper.naked {
  background-color: transparent !important;
  padding: 0;
}

.hs-richtext {
  font-size: 0.8rem;
  line-height: 1.4;
  color: rgba(0, 0, 0, 0.5);
}

form {
  position: relative;
  max-width: 100%;
}
form input,
form select {
  height: 50px;
}
form input,
form select,
form textarea {
  border: 1px solid rgba(29, 29, 27, 0.4);
  width: 100% !important;
  border-radius: 1rem;
  padding: 10px 10px;
}
form input[type=checkbox],
form input[type=radio] {
  height: 20px;
  width: 20px !important;
}
form select {
  padding-right: 40px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAh0lEQVQ4T93TMQrCUAzG8V9x8QziiYSuXdzFC7h4AcELOPQAdXYovZCHEATlgQV5GFTe1ozJlz/kS1IpjKqw3wQBVyy++JI0y1GTe7DCBbMAckeNIQKk/BanALBB+16LtnDELoMcsM/BESDlz2heDR3WePwKSLo5eoxz3z6NNcFD+vu3ij14Aqz/DxGbKB7CAAAAAElFTkSuQmCC");
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-color: #ffffff;
}
form textarea {
  min-height: 150px;
}
form label {
  color: #2A2069;
  text-align: left !important;
  font-size: 1rem;
  padding-bottom: 0.5rem !important;
}
form .legal-consent-container p {
  margin-bottom: 0 !important;
}
form legend {
  margin-bottom: 5px;
  font-style: italic;
}
form fieldset {
  max-width: 100% !important;
}
form fieldset .field:last-of-type .input {
  margin-right: 0px !important;
}
form fieldset .input {
  margin-right: 15px !important;
}
@media screen and (max-width: 480px) {
  form fieldset .input {
    margin-right: 0px !important;
  }
}
form .field {
  margin-bottom: 20px;
  text-align: left !important;
}
form .field .inputs-list {
  list-style: none;
}
form .field .inputs-list li span {
  margin: 0px;
}
form .field .inputs-list .hs-form-booleancheckbox {
  position: relative;
}
form .field .inputs-list .hs-form-booleancheckbox input {
  position: absolute;
  top: 5px;
  left: -30px;
}
form input[type=submit] {
  text-align: center !important;
  padding: 17px 20px !important;
  transition: all 0.3s ease-in-out;
}
form .hs-input {
  width: 100% !important;
  border: 2px solid #948FB4;
  color: #2A2069;
}
form input.hs-input:focus, form textarea.hs-input:focus {
  outline: none;
  border: 2px solid #2A2069 !important;
  border-color: #2A2069 !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
form .hs-input::placeholder {
  color: #6A6396;
}
form .hs-error-msgs {
  padding: 0;
  margin: 0;
}
form .hs-error-msgs label.hs-error-msg {
  font-size: 14px;
  color: #F25F5C;
  font-weight: 400;
}
form .hs_error_rollup ul {
  list-style: none;
}
form .hs_error_rollup ul label {
  color: #F25F5C;
}

.submitted-message p {
  color: #2A2069;
  font-size: 1.3rem;
  line-height: 2rem;
  text-align: center;
  font-weight: 600;
}

.demo-form-col {
  padding-bottom: 50px;
}

.check-icon {
  width: 30px;
  margin-right: 19px;
}
.check-icon.top {
  margin-top: 5px;
}

svg.duotone {
  fill: #2A2069;
}

.quote {
  margin-bottom: 45px;
  font-family: Athletics, sans-serif;
  font-size: 68px;
  line-height: 1;
  font-weight: 700;
}
@media screen and (max-width: 991px) {
  .quote {
    margin-bottom: 21px;
    font-size: 45px;
    line-height: 1.2;
  }
}
@media screen and (max-width: 767px) {
  .quote {
    margin-bottom: 14px;
    font-size: 30px;
  }
}

.quote-credit {
  font-family: Athletics, sans-serif;
  color: #5644d8;
  font-size: 26px;
  font-weight: 700;
}
@media screen and (max-width: 991px) {
  .quote-credit {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .quote-credit {
    font-size: 17px;
  }
}
@media screen and (max-width: 479px) {
  .quote-credit {
    font-size: 14px;
  }
}
.quote-credit.white {
  color: #ffffff;
}

.testimonials-button-holder {
  display: flex;
  max-width: 80%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 50px;
}
@media screen and (max-width: 991px) {
  .testimonials-button-holder {
    padding-top: 40px;
  }
}
@media screen and (max-width: 767px) {
  .testimonials-button-holder {
    padding-top: 30px;
  }
}
@media screen and (max-width: 479px) {
  .testimonials-button-holder {
    max-width: 100%;
  }
}

.table-responsive thead h4, .table-responsive thead .h4 {
  font-size: 0.875rem;
}
.table-responsive td {
  font-size: 0.75rem;
}

table td {
  border-bottom: 0;
}

h1.large, .large.h1 {
  font-size: 5rem;
}
@media (max-width: 767.98px) {
  h1.large, .large.h1 {
    font-size: calc(1.525rem + 3.3vw);
  }
}

h1, .h1,
h2,
.h2,
h3,
.h3,
.form-wrapper.inline .submitted-message,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.block-herocenter__subtitle,
.block-hero__subtitle {
  word-wrap: break-word;
  margin-block-end: 1rem;
}

h6, .h6, .block-herocenter__subtitle, .block-hero__subtitle, h5, .h5, h4, .h4, h3, .h3, .form-wrapper.inline .submitted-message {
  line-height: 125% !important;
}

.wp-block-column h1, .wp-block-column .h1,
.wp-block-column h2,
.wp-block-column .h2,
.wp-block-column h3,
.wp-block-column .h3,
.wp-block-column .form-wrapper.inline .submitted-message,
.form-wrapper.inline .wp-block-column .submitted-message,
.wp-block-column h4,
.wp-block-column .h4,
.wp-block-column h5,
.wp-block-column .h5,
.wp-block-column h6,
.wp-block-column .h6,
.wp-block-column .block-herocenter__subtitle,
.wp-block-column .block-hero__subtitle {
  margin-top: 3rem;
  margin-block-end: 1rem;
}
@media (max-width: 767.98px) {
  .wp-block-column h1, .wp-block-column .h1,
  .wp-block-column h2,
  .wp-block-column .h2,
  .wp-block-column h3,
  .wp-block-column .h3,
  .wp-block-column .form-wrapper.inline .submitted-message,
  .form-wrapper.inline .wp-block-column .submitted-message,
  .wp-block-column h4,
  .wp-block-column .h4,
  .wp-block-column h5,
  .wp-block-column .h5,
  .wp-block-column h6,
  .wp-block-column .h6,
  .wp-block-column .block-herocenter__subtitle,
  .wp-block-column .block-hero__subtitle {
    margin-top: 1rem;
  }
}
.wp-block-column :first-child {
  margin-top: 0;
}
.wp-block-column ol.ol-list,
.wp-block-column ol.wp-block-list,
.wp-block-column ol.wp-block-list ol {
  counter-reset: section;
  list-style-type: none;
  padding-left: 35px;
}
@media (max-width: 767.98px) {
  .wp-block-column ol.ol-list,
  .wp-block-column ol.wp-block-list,
  .wp-block-column ol.wp-block-list ol {
    padding-left: 25px;
  }
}
.wp-block-column ol.ol-list li,
.wp-block-column ol.wp-block-list li,
.wp-block-column ol.wp-block-list ol li {
  position: relative;
  padding-left: 0;
  line-height: 2;
}
.wp-block-column ol.ol-list li:before,
.wp-block-column ol.wp-block-list li:before,
.wp-block-column ol.wp-block-list ol li:before {
  position: absolute;
  top: 0;
  left: -70px;
  text-align: right;
  counter-increment: section;
  content: counters(section, ".");
  width: 60px;
  font-weight: bold;
}
.wp-block-column ol.ol-list li::marker,
.wp-block-column ol.wp-block-list li::marker,
.wp-block-column ol.wp-block-list ol li::marker {
  display: none;
}
.wp-block-column > ol.ol-list,
.wp-block-column ol.wp-block-list {
  padding-left: 15px;
}

p {
  line-height: 2rem;
}

div[class*=wp-container] > p {
  margin-block-end: 1rem;
}

@media (max-width: 767.98px) {
  .font-md-p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    font-family: "Eina02", sans-serif;
  }
}

blockquote {
  margin-bottom: 10px;
  padding: 10px 20px;
  border-left: 5px solid #e2e2e2;
  font-size: 16px;
  line-height: 1.3;
}

.badge {
  font-size: 0.875em !important;
}

.is-black {
  font-weight: 900 !important;
}

.semi-bold {
  font-weight: 600;
}

video {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.plyr {
  --plyr-color-main: #5644d8;
  --plyr-control-spacing: 30px;
  --plyr-video-controls-background: linear-gradient(0deg, rgba(42,32,105,1) 0%, rgba(255,255,240,0) 80%);
  --plyr-control-spacing: 10px;
  border-radius: 15px;
}
.plyr__video-embed {
  position: relative;
}
.plyr__poster {
  background-size: cover;
}

picture {
  height: auto;
  width: 100%;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.background-size-cover {
  background-size: cover;
  background-position: center center;
}

.wp-block-image {
  margin-bottom: 1rem;
}

.wp-block-image {
  display: flex !important;
  flex-direction: column;
}

.wp-block-image.aligncenter,
.wp-block-image .aligncenter {
  align-items: center;
}

.wp-block-image.alignright,
.wp-block-image .alignright {
  align-items: flex-end;
}

.wp-block-image.alignleft,
.wp-block-image .alignleft {
  align-items: flex-start;
}

a.underlined {
  position: relative;
}
a.underlined .underline {
  position: absolute;
  bottom: -13px;
  left: 50%;
  overflow: hidden;
  width: 100%;
  height: 4px;
  transform: translate(-50%, 0);
}
a.underlined .underline svg {
  position: absolute;
  top: 50%;
  left: 0px;
  height: 4px;
  width: auto;
  transform: translate(0, -50%);
}
a.underlined .underline svg path {
  stroke-dasharray: 1294;
  stroke-dashoffset: 1294;
  transition: stroke-dashoffset 300ms ease;
}
a:hover .underline svg path {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 1s;
}

.sticky-top.scrollspy {
  top: 96px;
}

body.admin-bar h1[id]::before, body.admin-bar [id].h1::before,
body.admin-bar h2[id]::before,
body.admin-bar [id].h2::before,
body.admin-bar h3[id]::before,
body.admin-bar [id].h3::before,
body.admin-bar .form-wrapper.inline [id].submitted-message::before,
.form-wrapper.inline body.admin-bar [id].submitted-message::before,
body.admin-bar h4[id]::before,
body.admin-bar [id].h4::before,
body.admin-bar h5[id]::before,
body.admin-bar [id].h5::before,
body.admin-bar h6[id]::before,
body.admin-bar [id].h6::before,
body.admin-bar [id].block-herocenter__subtitle::before,
body.admin-bar [id].block-hero__subtitle::before {
  height: 158px;
  margin-top: -158px;
}
body.admin-bar .sticky-top.scrollspy {
  top: 132px;
}

.card {
  position: relative;
  z-index: 10;
  padding: 0;
  max-width: 100%;
  overflow: hidden;
}
.card.featured {
  background-color: #FFE6C1;
}
.card .card-img-wrap.image-type-normal {
  transition: all 500ms ease;
}
.card .card-img-wrap.image-type-normal img {
  transition: transform 500ms;
}
.card .card-img-wrap .card-img-top {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card .card-img-wrap:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.2);
  opacity: 0;
  transition: opacity 500ms;
}
.card .card-img-wrap img {
  width: 100%;
}
.card.card-layout-wide {
  flex-direction: row;
}
@media (max-width: 991.98px) {
  .card.card-layout-wide {
    flex-direction: column;
  }
}
.card.card-layout-wide .card-img-wrap {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.card.card-layout-wide .card-body.highlighted {
  background: linear-gradient(180deg, #9090e8 -40.09%, #5644d8 89.63%);
}
@media (min-width: 768px) {
  .card.card-layout-wide .card-body.highlighted {
    padding-left: 3rem;
    margin-left: 0 !important;
  }
}
.card.card-layout-wide .card-body.highlighted .card-text,
.card.card-layout-wide .card-body.highlighted .card-title,
.card.card-layout-wide .card-body.highlighted .btn,
.card.card-layout-wide .card-body.highlighted .mm-ocd .mm-ocd__content #mobile-menu a.btn,
.mm-ocd .mm-ocd__content #mobile-menu .card.card-layout-wide .card-body.highlighted a.btn,
.card.card-layout-wide .card-body.highlighted form input[type=submit],
form .card.card-layout-wide .card-body.highlighted input[type=submit],
.card.card-layout-wide .card-body.highlighted .small {
  color: #fff !important;
}
.card.linkable:hover .card-img-wrap img {
  transform: scale(1.05);
}
.card.linkable:hover .card-img-wrap:after {
  opacity: 1;
}

.logo-card {
  position: relative;
  z-index: 1;
}
.logo-card .card-title {
  position: relative;
  display: inline;
}
.logo-card .card-title .underline {
  position: absolute;
  bottom: -13px;
  left: 50%;
  overflow: hidden;
  width: 100%;
  height: 4px;
  transform: translate(-50%, 0);
}
.logo-card .card-title .underline svg {
  position: absolute;
  top: 50%;
  left: 0px;
  height: 4px;
  width: auto;
  transform: translate(0, -50%);
}
.logo-card .card-logo {
  height: 200px;
}
@media (max-width: 767.98px) {
  .logo-card .card-logo {
    height: 80px;
  }
}
.logo-card .card-logo .card-img {
  max-height: 80%;
  width: auto;
}
@media (max-width: 767.98px) {
  .logo-card .card-logo .card-img {
    min-height: 100%;
  }
}
.logo-card .btn, .logo-card .mm-ocd .mm-ocd__content #mobile-menu a.btn, .mm-ocd .mm-ocd__content #mobile-menu .logo-card a.btn, .logo-card form input[type=submit], form .logo-card input[type=submit] {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10;
  padding-right: 0 !important;
}
.logo-card .hover-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #5644d8;
  color: #ffffff;
  opacity: 0;
  transition: 500ms all ease;
  z-index: 9;
}
.logo-card .stretched-link {
  z-index: 10;
}
@media (min-width: 768px) {
  .logo-card:hover .btn, .logo-card:hover .mm-ocd .mm-ocd__content #mobile-menu a.btn, .mm-ocd .mm-ocd__content #mobile-menu .logo-card:hover a.btn, .logo-card:hover form input[type=submit], form .logo-card:hover input[type=submit] {
    color: #ffffff !important;
  }
  .logo-card:hover .hover-overlay {
    opacity: 1;
  }
}

.integration-card {
  position: relative;
}
.integration-card .card-category {
  position: absolute;
  top: 30px;
  right: 30px;
}
.integration-card .card-category .underline {
  position: absolute;
  bottom: -13px;
  left: 50%;
  overflow: hidden;
  width: 100%;
  height: 4px;
  transform: translate(-50%, 0);
}
.integration-card .card-category .underline svg {
  position: absolute;
  top: 50%;
  left: 0px;
  height: 4px;
  width: auto;
  transform: translate(0, -50%);
}
.integration-card .card-logo {
  height: 150px;
}
@media (max-width: 767.98px) {
  .integration-card .card-logo {
    height: 80px;
  }
}
.integration-card .card-logo .card-img {
  max-height: 80%;
  width: auto;
}
@media (max-width: 767.98px) {
  .integration-card .card-logo .card-img {
    min-height: 100%;
  }
}
.integration-card .btn, .integration-card .mm-ocd .mm-ocd__content #mobile-menu a.btn, .mm-ocd .mm-ocd__content #mobile-menu .integration-card a.btn, .integration-card form input[type=submit], form .integration-card input[type=submit] {
  padding-left: 0px !important;
  transition: 0ms;
}
@media (min-width: 768px) {
  .integration-card:hover {
    background-color: #5644d8;
    color: #ffffff;
  }
  .integration-card:hover .btn .text, .integration-card:hover .mm-ocd .mm-ocd__content #mobile-menu a.btn .text, .mm-ocd .mm-ocd__content #mobile-menu .integration-card:hover a.btn .text, .integration-card:hover form input[type=submit] .text, form .integration-card:hover input[type=submit] .text {
    color: #ffffff;
  }
  .integration-card:hover .btn svg path, .integration-card:hover .mm-ocd .mm-ocd__content #mobile-menu a.btn svg path, .mm-ocd .mm-ocd__content #mobile-menu .integration-card:hover a.btn svg path, .integration-card:hover form input[type=submit] svg path, form .integration-card:hover input[type=submit] svg path {
    stroke: #ffffff;
  }
  .integration-card:hover .card-title {
    color: #ffffff !important;
  }
}

.pricing-card {
  box-shadow: 0px 0px 24px rgba(200, 200, 200, 0.5);
}
.pricing-card .features .icon {
  width: 24px;
  height: 24px;
}
.pricing-card .features .icon svg {
  width: 24px;
  height: 24px;
}

.pagination li a,
.pagination li span {
  color: #1d1d1b;
  font-weight: bold;
}
.pagination li a.current,
.pagination li span.current {
  color: #5644d8;
}
.pagination li a.prev svg,
.pagination li span.prev svg {
  transform: rotate(180deg);
}

.glide {
  position: relative;
}
.glide__slides {
  margin-bottom: 0;
}
.glide__arrows {
  position: absolute;
  top: calc(50% - 27px);
  left: 0;
  width: 100%;
  user-select: none;
}
.glide__arrow {
  position: absolute;
  cursor: pointer;
  z-index: 2;
  appearance: none;
  background: none;
  border: none;
}
.glide__arrow--right {
  right: -10px;
}
.glide__arrow--left {
  left: -10px;
}
.glide__arrow svg {
  fill: #2A2069;
}

@media (min-width: 576px) {
  .modal .modal-dialog {
    margin: 1.75rem auto;
  }
  .modal .modal-dialog.more-top-space {
    margin: 5rem auto;
  }
}
.modal .modal-dialog {
  width: 90vw;
  height: fit-content;
}
.modal .modal-dialog.more-top-space {
  margin: auto;
}
@media (max-width: 767.98px) {
  .modal .modal-xl {
    padding: 0 !important;
  }
  .modal .modal-xl .modal-content {
    border-radius: 0 !important;
  }
}
.modal .close:hover {
  cursor: pointer;
}
.border-dashed {
  border-style: dashed !important;
}

.border-dotted {
  border-style: dotted !important;
}

.border-groove {
  border-style: groove !important;
}

.border-solid {
  border-style: solid !important;
}

.tooltip p {
  margin-bottom: 0px;
}

.section {
  position: relative;
  padding: 90px 50px 70px;
}
@media screen and (max-width: 991px) {
  .section {
    padding: 60px 35px;
  }
}
@media screen and (max-width: 767px) {
  .section {
    padding: 40px 30px;
  }
}
@media screen and (max-width: 479px) {
  .section {
    padding: 40px 25px;
  }
}
.section.s-footer {
  padding-top: 90px;
  padding-bottom: 50px;
  background-color: #1d1d1b;
  color: #ffffff;
}
@media screen and (max-width: 991px) {
  .section.s-footer {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 479px) {
  .section.s-footer {
    padding-bottom: 25px;
  }
}
.section.s-lilac-f0f0ff {
  background-color: #f0f0ff;
}
.section.s-lilac-f0f0ff.intelligent-call {
  padding-top: 140px;
  padding-bottom: 170px;
}
@media screen and (max-width: 991px) {
  .section.s-lilac-f0f0ff.intelligent-call {
    padding-top: 154px;
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 767px) {
  .section.s-lilac-f0f0ff.intelligent-call {
    padding-top: 150px;
  }
}
@media screen and (max-width: 479px) {
  .section.s-lilac-f0f0ff.intelligent-call {
    padding-top: 174px;
    padding-bottom: 47px;
  }
}
.section.s-lilac-f0f0ff.s-discover {
  margin-bottom: 143px;
  padding-top: 1px;
  padding-bottom: 0;
}
@media screen and (max-width: 991px) {
  .section.s-lilac-f0f0ff.s-discover {
    margin-bottom: 30px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.section.s-lilac-f0f0ff.s-other-features {
  padding-top: 120px;
  padding-bottom: 180px;
}
@media screen and (max-width: 991px) {
  .section.s-lilac-f0f0ff.s-other-features {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 767px) {
  .section.s-lilac-f0f0ff.s-other-features {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media screen and (max-width: 479px) {
  .section.s-lilac-f0f0ff.s-other-features {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.section.s-lilac-f0f0ff.pricing-ratings {
  padding-top: 260px;
  padding-bottom: 260px;
}
@media screen and (max-width: 991px) {
  .section.s-lilac-f0f0ff.pricing-ratings {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 479px) {
  .section.s-lilac-f0f0ff.pricing-ratings {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}
@media screen and (min-width: 992px) {
  .section.s-lilac-f0f0ff.pricing-ratings {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}
.section.s-lilac-f0f0ff.s-posts-quotes {
  padding-top: 130px;
  padding-bottom: 130px;
}
@media screen and (max-width: 991px) {
  .section.s-lilac-f0f0ff.s-posts-quotes {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media screen and (max-width: 479px) {
  .section.s-lilac-f0f0ff.s-posts-quotes {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.section.s-purple-5644d8 {
  background-color: #5644d8;
  color: #ffffff;
}
@media screen and (max-width: 991px) {
  .section.s-purple-5644d8 {
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 479px) {
  .section.s-purple-5644d8 {
    padding-bottom: 45px;
  }
}
.section.s-purple-5644d8.conversations {
  padding-top: 120px;
  padding-bottom: 120px;
}
@media screen and (max-width: 991px) {
  .section.s-purple-5644d8.conversations {
    padding-top: 80px;
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 767px) {
  .section.s-purple-5644d8.conversations {
    padding-bottom: 60px;
  }
}
@media screen and (max-width: 479px) {
  .section.s-purple-5644d8.conversations {
    padding-top: 50px;
  }
}
.section.s-purple-5644d8.conversations.bg-unset .section-h2-90 {
  color: #1d1d1b;
  font-size: 34px;
}
.section.s-purple-5644d8.s-story-quotes {
  padding-top: 160px;
  padding-bottom: 160px;
}
@media screen and (max-width: 991px) {
  .section.s-purple-5644d8.s-story-quotes {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 479px) {
  .section.s-purple-5644d8.s-story-quotes {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.section.s-demo {
  padding: 0;
}
@media screen and (max-width: 767px) {
  .section.s-demo {
    padding-top: 10px;
  }
}
@media screen and (max-width: 767px) {
  .section.s-why-dixa-s2 {
    padding-top: 140px;
  }
}
@media screen and (max-width: 479px) {
  .section.s-why-dixa-s2 {
    padding-top: 70px;
  }
}
.section.s-left-content {
  padding-top: 30px;
}
@media screen and (max-width: 479px) {
  .section.s-left-content {
    padding-top: 10px;
  }
}
.section.s-left-content.top-row {
  margin-top: -50px;
  padding-top: 0;
}
@media screen and (max-width: 991px) {
  .section.s-left-content.top-row {
    margin-top: 0;
  }
}
.section.s-left-content.last {
  padding-bottom: 30px;
}

main .has-drop-cap:not(:focus)::first-letter {
  float: left;
  font-size: 6rem;
  line-height: 0.68;
  font-weight: bold;
  margin: 0.05em 0.2em 10px 0;
  text-transform: uppercase;
  font-style: normal;
  color: #5644d8;
}

h1.rich-text, .rich-text.h1, h1.heading, .heading.h1,
h2.rich-text,
.rich-text.h2,
h2.heading,
.heading.h2,
h3.rich-text,
.rich-text.h3,
.form-wrapper.inline .rich-text.submitted-message,
h3.heading,
.heading.h3,
.form-wrapper.inline .heading.submitted-message,
h4.rich-text,
.rich-text.h4,
h4.heading,
.heading.h4,
h5.rich-text,
.rich-text.h5,
h5.heading,
.heading.h5,
h6.rich-text,
.rich-text.h6,
.rich-text.block-herocenter__subtitle,
.rich-text.block-hero__subtitle,
h6.heading,
.heading.h6,
.heading.block-herocenter__subtitle,
.heading.block-hero__subtitle {
  margin-top: 2rem;
}

p.rich-text, p.paragraph, p.list,
ul.rich-text,
ul.paragraph,
ul.list {
  font-size: 1rem;
}

.single-post main > article > .wp-block-columns p {
  font-size: 1.25rem !important;
  line-height: 2rem;
}
.single-post .breadcrumbs .btn, .single-post .breadcrumbs .mm-ocd .mm-ocd__content #mobile-menu a.btn, .mm-ocd .mm-ocd__content #mobile-menu .single-post .breadcrumbs a.btn, .single-post .breadcrumbs form input[type=submit], form .single-post .breadcrumbs input[type=submit] {
  color: #ffffff !important;
  background-color: #1d1d1b !important;
}

.single-customers main > article > .wp-block-group > p {
  font-size: 1.25rem !important;
  line-height: 2rem;
}

@media (min-width: 600px) and (max-width: 781px) {
  .wp-block-column:not(:only-child) {
    flex-basis: 100% !important;
  }
}
.wp-block {
  width: 100%;
  max-width: 100%;
}

.wp-block-group {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
@media (max-width: 767.98px) {
  .wp-block-group {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
.wp-block-group .wp-block-columns {
  margin-bottom: 2rem;
}
.wp-block-group .wp-block-columns:first-of-type {
  margin-top: 2rem;
}
@media (max-width: 767.98px) {
  .wp-block-group .wp-block-columns:first-of-type {
    margin-top: 0rem;
  }
}
@media (max-width: 767.98px) {
  .wp-block-group .wp-block-columns {
    padding-top: 2rem;
    padding-bottom: 0rem;
    margin-bottom: 0;
  }
}
.wp-block-group .wp-block-columns .wp-block-column {
  margin-bottom: 1rem !important;
}
@media (max-width: 767.98px) {
  .wp-block-group .wp-block-columns .wp-block-column {
    margin-bottom: 1.5rem;
  }
  .wp-block-group .wp-block-columns .wp-block-column:empty {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .wp-block-group .wp-block-columns .wp-block-column:nth-child(even) {
    margin-left: 0px;
  }
}
.wp-block-group .wp-block-columns .wp-block-column:last-of-type {
  margin-bottom: 0px;
}
.wp-block-group .wp-block-columns .wp-block-column:first-of-type {
  margin-bottom: 1rem !important;
}
.wp-block-group .wp-block-columns .wp-block-column > :last-child:not(:first-child) {
  margin-bottom: 1rem !important;
}
@media (max-width: 767.98px) {
  .wp-block-group .wp-block-columns .wp-block-column:not(:first-of-type) {
    margin-left: 0;
  }
}
.wp-block-group .wp-block-columns .wp-block-columns {
  padding-left: 0;
  padding-right: 0;
}
.wp-block-group.cut-to-bottom .wp-block-columns:last-of-type {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
.wp-block-group.cut-to-bottom .wp-block-columns:last-of-type .wp-block-column:last-of-type {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
.wp-block-group.cut-to-bottom .wp-block-columns:last-of-type .wp-block-column:last-of-type .wp-block-image:last-of-type {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
.wp-block-group.cut-to-bottom .wp-block-columns:last-of-type .wp-block-column:last-of-type .wp-block-image:last-of-type figure {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

@media (max-width: 767.98px) {
  .wp-block-spacer {
    height: 0px !important;
  }
}

@media screen and (max-width: 991px) {
  .section.s-page-scroll-row.secondary-nav {
    position: initial;
    background-color: transparent;
    padding: 20px;
  }
  .sidenav .sidenav__inner {
    top: 55%;
  }
}
.single-solutions.is-purple-theme header#navigation nav.navbar {
  margin-top: 1rem;
  position: absolute !important;
  width: 100%;
  background-color: transparent !important;
}
.single-solutions.is-purple-theme header#navigation.scrolling nav.navbar {
  background-color: transparent !important;
}
.single-solutions.is-purple-theme section.block-hero {
  background-color: #E1DBF7;
}
.single-solutions.is-purple-theme section.block-three-col-icon {
  background-color: #F3F1FC;
}
.single-solutions.is-yellow-theme header#navigation nav.navbar {
  margin-top: 1rem;
  position: absolute !important;
  width: 100%;
  background-color: transparent !important;
}
.single-solutions.is-yellow-theme header#navigation.scrolling nav.navbar {
  background-color: transparent !important;
}
.single-solutions.is-yellow-theme section.block-hero {
  background-color: #FFE6C1;
}
.single-solutions.is-yellow-theme section.block-three-col-icon {
  background-color: #FFF5E6;
}
.single-solutions.is-sand-theme header#navigation nav.navbar {
  margin-top: 1rem;
  position: absolute !important;
  width: 100%;
  background-color: transparent !important;
}
.single-solutions.is-sand-theme header#navigation.scrolling nav.navbar {
  background-color: transparent !important;
}
.single-solutions.is-sand-theme section.block-hero {
  background-color: #D6CCC2;
}
.single-solutions.is-sand-theme section.block-three-col-icon {
  background-color: #EFEBE7;
}
.single-solutions.is-pink-theme header#navigation nav.navbar {
  margin-top: 1rem;
  position: absolute !important;
  width: 100%;
  background-color: transparent !important;
}
.single-solutions.is-pink-theme header#navigation.scrolling nav.navbar {
  background-color: transparent !important;
}
.single-solutions.is-pink-theme section.block-hero {
  background-color: #F3C1C0;
}
.single-solutions.is-pink-theme section.block-three-col-icon {
  background-color: #FAE6E6;
}

@media (min-width: 768px) {
  .home .block-hero_image {
    transform: translateY(48px);
  }
}

div.block-bigsplitcard .card {
  border-radius: 20px;
}
div.block-bigsplitcard .card .image {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
@media (max-width: 767.98px) {
  div.block-bigsplitcard .card .image {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px;
  }
}
div.block-bigsplitcard .card .image.cover img {
  object-fit: cover;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
@media (max-width: 767.98px) {
  div.block-bigsplitcard .card .image.cover img {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px;
  }
}

ul.block-iconlist .large-icon svg {
  width: 30px;
  height: 30px;
}

.block-logogrid .logo-items .logo-item {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
.block-logogrid .logo-items .logo-item:nth-of-type(6),
.block-logogrid .logo-items .logo-item:nth-of-type(12),
.block-logogrid .logo-items .logo-item:nth-of-type(18) {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.block-logogrid .logo-items .logo-item:nth-of-type(7),
.block-logogrid .logo-items .logo-item:nth-of-type(8),
.block-logogrid .logo-items .logo-item:nth-of-type(9),
.block-logogrid .logo-items .logo-item:nth-of-type(10),
.block-logogrid .logo-items .logo-item:nth-of-type(11),
.block-logogrid .logo-items .logo-item:nth-of-type(12) {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 991px) {
  .block-logogrid .logo-items .logo-item {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2rem !important;
  }
  .block-logogrid .logo-items .logo-item:nth-of-type(6),
  .block-logogrid .logo-items .logo-item:nth-of-type(12),
  .block-logogrid .logo-items .logo-item:nth-of-type(18) {
    border-right: 0;
  }
  .block-logogrid .logo-items .logo-item:nth-of-type(7),
  .block-logogrid .logo-items .logo-item:nth-of-type(8),
  .block-logogrid .logo-items .logo-item:nth-of-type(9),
  .block-logogrid .logo-items .logo-item:nth-of-type(10),
  .block-logogrid .logo-items .logo-item:nth-of-type(11),
  .block-logogrid .logo-items .logo-item:nth-of-type(12) {
    border-top: 0;
  }
  .block-logogrid .logo-items .logo-item:nth-of-type(3),
  .block-logogrid .logo-items .logo-item:nth-of-type(6),
  .block-logogrid .logo-items .logo-item:nth-of-type(9),
  .block-logogrid .logo-items .logo-item:nth-of-type(12) {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
  .block-logogrid .logo-items .logo-item:nth-of-type(18),
  .block-logogrid .logo-items .logo-item:nth-of-type(17),
  .block-logogrid .logo-items .logo-item:nth-of-type(16),
  .block-logogrid .logo-items .logo-item:nth-of-type(15),
  .block-logogrid .logo-items .logo-item:nth-of-type(14),
  .block-logogrid .logo-items .logo-item:nth-of-type(13) {
    display: none;
  }
  .block-logogrid .logo-items .logo-item:nth-of-type(12),
  .block-logogrid .logo-items .logo-item:nth-of-type(11),
  .block-logogrid .logo-items .logo-item:nth-of-type(10) {
    border-bottom: 0;
  }
}
@media screen and (max-width: 767px) {
  .block-logogrid .logo-items .logo-item {
    padding: 1.5rem !important;
  }
}
@media screen and (max-width: 376px) {
  .block-logogrid .logo-items .logo-item {
    padding: 1rem !important;
  }
}

.block-logorow .glide {
  opacity: 0;
  transition: opacity 500ms ease;
}
.block-logorow .glide.glide--swipeable {
  opacity: 1;
}
.block-logorow .glide .logo-elem {
  max-height: 120px;
}

.block-pricingaddoncard hr {
  border-top: 1px dotted #545454 !important;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
.block-pricingaddoncard .text-small {
  font-size: 0.75rem;
  color: #ABABAB;
}
.block-pricingaddoncard .pricing-addon-card {
  border: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.block-pricingaddoncard .price {
  width: 100%;
  height: 35px;
  overflow: hidden;
}
.block-pricingaddoncard .price h3:first-child, .block-pricingaddoncard .price .h3:first-child, .block-pricingaddoncard .price .form-wrapper.inline .submitted-message:first-child, .form-wrapper.inline .block-pricingaddoncard .price .submitted-message:first-child {
  transform: translateY(-100%);
  transition: all 0.2s ease-in-out;
}
.block-pricingaddoncard .price h3:first-child.active, .block-pricingaddoncard .price .h3:first-child.active, .block-pricingaddoncard .price .form-wrapper.inline .submitted-message:first-child.active, .form-wrapper.inline .block-pricingaddoncard .price .submitted-message:first-child.active {
  transform: translateY(0);
}
.block-pricingaddoncard .price h3:nth-child(2), .block-pricingaddoncard .price .h3:nth-child(2), .block-pricingaddoncard .price .form-wrapper.inline .submitted-message:nth-child(2), .form-wrapper.inline .block-pricingaddoncard .price .submitted-message:nth-child(2) {
  transform: translateY(0);
  transition: all 0.2s ease-in-out;
}
.block-pricingaddoncard .price h3:nth-child(2).active, .block-pricingaddoncard .price .h3:nth-child(2).active, .block-pricingaddoncard .price .form-wrapper.inline .submitted-message:nth-child(2).active, .form-wrapper.inline .block-pricingaddoncard .price .submitted-message:nth-child(2).active {
  transform: translateY(-100%);
}
.block-pricingaddoncard .price .price-number {
  margin-right: 0.5rem;
}

.block-pricingcurrencyswitcher {
  position: sticky;
  top: 80px;
}
.block-pricingcurrencyswitcher.is-light .currency {
  background-color: rgba(255, 255, 255, 0.5);
  color: #FFF5E6;
  transition: all 0.5s;
  z-index: 100;
}
.block-pricingcurrencyswitcher.is-light .currency:hover {
  background-color: rgba(255, 255, 255, 0.75);
  color: #5644d8;
}
.block-pricingcurrencyswitcher.is-light .currency.active {
  background-color: #ffffff;
  color: #5644d8;
}
.block-pricingcurrencyswitcher.is-light .currency div {
  pointer-events: none;
}
.block-pricingcurrencyswitcher.is-light .billing input:checked + label {
  background: #FFE6C1;
}
.block-pricingcurrencyswitcher.is-light .billing label {
  background: #FFF5E6;
}
.block-pricingcurrencyswitcher.is-light .billing span {
  color: #FFF5E6;
}
.block-pricingcurrencyswitcher.is-light .billing span.active {
  color: #FFE6C1;
}
.block-pricingcurrencyswitcher.is-dark .currency {
  background-color: #F3F1FC;
  color: #1d1d1b;
  transition: all 0.5s;
  z-index: 100;
}
.block-pricingcurrencyswitcher.is-dark .currency:hover {
  background-color: #E1DBF7;
  color: #1d1d1b;
}
.block-pricingcurrencyswitcher.is-dark .currency.active {
  background-color: #5644d8;
  color: #ffffff;
}
.block-pricingcurrencyswitcher.is-dark .currency div {
  pointer-events: none;
}
.block-pricingcurrencyswitcher .billing input.switch-billing {
  height: 0;
  width: 0;
  visibility: hidden;
}
.block-pricingcurrencyswitcher .billing input:checked + label {
  background: #5644d8;
}
.block-pricingcurrencyswitcher .billing input:checked + label::after {
  left: 100%;
  transform: translateX(-100%);
  background-color: #FFF5E6;
}
.block-pricingcurrencyswitcher .billing label {
  display: block;
  cursor: pointer;
  text-indent: -9999px;
  width: 70px;
  height: 35px;
  background: #E1DBF7;
  border-radius: 50px;
  border: 1px solid #5644d8;
  position: relative;
}
.block-pricingcurrencyswitcher .billing label::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0.5px;
  width: 32.5px;
  height: 32.5px;
  background: #ffffff;
  border-radius: 50px;
  transition: 0.3s;
  border: 2px solid #5644d8;
}
.block-pricingcurrencyswitcher .billing label:active::after {
  width: 60px;
}
.block-pricingcurrencyswitcher .billing span {
  transition: all 0.3s ease-in-out;
}
.block-pricingcurrencyswitcher .billing span.active {
  font-weight: bold;
  color: #5644d8;
}

.block-share-links .icons .icon {
  width: 60px;
  height: 60px;
  background-color: #1d1d1b;
  display: block;
  position: relative;
  transition: 100ms background-color ease;
  border: 2px solid #1d1d1b;
}
.block-share-links .icons .icon svg {
  width: 50%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.block-share-links .icons .icon svg path,
.block-share-links .icons .icon svg circle {
  transition: 100ms background-color ease;
}
.block-share-links .icons .icon:hover {
  background-color: #ffffff;
}
.block-share-links .icons .icon:hover svg path,
.block-share-links .icons .icon:hover svg circle {
  fill: #1d1d1b;
}

section.block-bigcalltoaction {
  position: relative;
}
section.block-bigcalltoaction.triangle:after {
  content: "";
  width: 0;
  height: 0;
  border-bottom: 70px solid #5644d8;
  border-right: 100px solid transparent;
  position: absolute;
  left: 0px;
  top: -70px;
}
@media (max-width: 767.98px) {
  section.block-bigcalltoaction.triangle:after {
    border-bottom: 40px solid #5644d8;
    top: -40px;
    border-right: 80px solid transparent;
  }
}

.card-icon-holder {
  width: 70px;
  height: 70px;
  border-radius: 35px;
}
.card-icon-holder .icon, .card-icon-holder .icon svg {
  width: 32px;
  height: 32px;
}

.card-grid .card {
  padding-bottom: 7rem !important;
}
.card-grid .card-grid-buttons {
  position: absolute;
  bottom: 3rem;
}

.compare-block-holder::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 30vh;
  left: 0;
  top: 0;
  z-index: -1;
  background-color: #E1DBF7;
}

section.block-compareplans .table-wrapper {
  overflow: clip;
}
section.block-compareplans .compare-plans {
  border-collapse: collapse;
}
section.block-compareplans .compare-plans .sticky-top {
  top: 0;
  z-index: 1000;
}
section.block-compareplans .compare-plans .sticky-top.stuck {
  background: #ffffff;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.05);
}
section.block-compareplans .compare-plans .sticky-top.stuck .package {
  padding-top: 96px !important;
  padding-bottom: 0 !important;
  transition: all 0.5s;
}
section.block-compareplans .compare-plans .sticky-top.stuck .hide-on-stuck {
  opacity: 0;
  transition: max-height 0.5s, opacity 0.5s;
}
section.block-compareplans .compare-plans .sticky-top.stuck .inner {
  transition: max-height 0.5s, opacity 0.5s;
  max-height: 44px;
}
section.block-compareplans .compare-plans thead .package {
  transition: all 0.5s;
}
section.block-compareplans .compare-plans thead .inner {
  max-height: 200px;
  box-sizing: border-box;
  overflow: hidden;
  transition: max-height 0.5s, opacity 0.5s;
}
section.block-compareplans .compare-plans thead .inner .hide-on-stuck {
  opacity: 1;
  transition: max-height 0.5s, opacity 0.5s;
}
section.block-compareplans .compare-plans tbody tr.feature-item:nth-child(even) {
  background-color: #F3F1FC;
}
section.block-compareplans .compare-plans-mobile {
  border-radius: 1.5rem;
}
section.block-compareplans .compare-plans-mobile svg {
  width: 16px;
  height: 16px;
}

section.block-comparetable .table-wrapper {
  overflow: clip;
}
section.block-comparetable table {
  border-collapse: collapse;
}
section.block-comparetable table .sticky-top {
  top: 80px;
  z-index: 1000;
}
section.block-comparetable table .sticky-top.stuck {
  background: #ffffff;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.05);
}
section.block-comparetable table .sticky-top.stuck .package {
  padding-top: 1rem !important;
  padding-bottom: 0 !important;
  transition: all 0.5s;
}
section.block-comparetable table .sticky-top.stuck .hide-on-stuck {
  opacity: 0;
  transition: max-height 0.5s, opacity 0.5s;
}
section.block-comparetable table .sticky-top.stuck .inner {
  transition: max-height 0.5s, opacity 0.5s;
  max-height: 44px;
}
section.block-comparetable table thead .package {
  transition: all 0.5s;
}
section.block-comparetable table thead .inner {
  max-height: 200px;
  box-sizing: border-box;
  overflow: hidden;
  transition: max-height 0.5s, opacity 0.5s;
}
section.block-comparetable table thead .inner .hide-on-stuck {
  opacity: 1;
  transition: max-height 0.5s, opacity 0.5s;
}
section.block-comparetable table tbody tr.feature-item:nth-child(odd) {
  background-color: #F3F1FC;
}
section.block-comparetable .compare-plans-mobile {
  border-radius: 1.5rem;
}
section.block-comparetable .compare-plans-mobile svg {
  width: 16px;
  height: 16px;
}

.customer-bio {
  padding-top: 25vh;
}
.customer-bio__numbers {
  border-radius: 10px;
}

@media (min-width: 992px) {
  .customer-hero {
    margin-bottom: 0;
  }
}
.customer-hero h1, .customer-hero .h1 {
  font-weight: 900;
}
.customer-hero .lead {
  font-size: 1.5rem;
  font-weight: 400;
}
.customer-hero__image {
  position: relative;
  width: 100%;
  height: 20vh;
}
@media (min-width: 992px) {
  .customer-hero__image {
    height: 50vh;
  }
}
.customer-hero__image img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  object-fit: cover;
  border-radius: 10px;
}
@media (min-width: 992px) {
  .customer-hero__image img {
    top: 6%;
    height: 112%;
  }
}
@media (min-width: 1200px) {
  .customer-hero__image img {
    top: 6%;
    height: 120%;
  }
}

.customer-quote {
  margin-top: 40px;
  margin-bottom: 40px;
}
.customer-quote figure {
  border-radius: 2rem;
  padding: 90px 30px 50px;
}
.customer-quote figure.bg- {
  background: linear-gradient(180deg, #F3F1FC 0%, #B19FF3 100%) !important;
  color: #2A2069 !important;
}
@media (min-width: 992px) {
  .customer-quote figure {
    padding: 120px 80px 80px;
  }
}
.customer-quote figure blockquote {
  position: relative;
  padding: 0;
  border: none;
}
.customer-quote figure blockquote svg {
  display: block;
  width: 40px;
  position: absolute;
  top: -50px;
  left: 0;
}
.customer-quote figure figcaption span {
  display: block;
}

.customer-slider {
  color: #ffffff;
  overflow: hidden;
}
.customer-slider .inner {
  padding: 80px 32px 40px;
}
@media (min-width: 1200px) {
  .customer-slider .inner {
    padding: 0 80px 0;
  }
}
.customer-slider .customer-item {
  overflow: hidden;
}
.customer-slider .customer-item figure blockquote {
  position: relative;
  padding: 0;
  border: none;
}
.customer-slider .customer-item figure blockquote svg {
  display: block;
  width: 40px;
  position: absolute;
  top: -50px;
  left: 0;
}
.customer-slider .customer-item figure figcaption span {
  display: block;
}
.customer-slider .customer-item picture {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  bottom: -17px;
  right: -40px;
}
.customer-slider .customer-item picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
}
.customer-slider .glide__bullet {
  position: relative;
  border: 0;
  overflow: hidden;
  max-width: 200px;
  background-color: rgba(255, 255, 255, 0.2);
}
.customer-slider .glide__bullet::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0;
  transform: translateX(-100%);
  background-color: rgba(255, 255, 255, 0.4);
  transition: transform 5.4s linear, opacity 0.2s linear;
}
.customer-slider .glide__bullet .logo {
  display: block;
  width: 100%;
  height: 50px;
  object-fit: contain;
  filter: grayscale(0%) invert(100%) brightness(2);
}
@media (min-width: 992px) {
  .customer-slider .glide__bullet .logo {
    height: 70px;
  }
}
.customer-slider .glide__bullet--active::before {
  opacity: 1;
  transform: translateX(0);
}

.demo-right-bg {
  background: linear-gradient(180deg, #FFB840 0%, #FFE6C1 100%);
  min-height: 100vh;
  position: relative;
}
@media (max-width: 767.98px) {
  .demo-right-bg {
    min-height: auto;
    padding-bottom: 3rem;
  }
}
.demo-right-bg .demo-right-container {
  max-width: 40rem;
  margin: auto;
}
.demo-right-bg .demo-right-container blockquote {
  margin-top: 4rem;
  position: relative;
  padding: 0;
  border: none;
  color: #2A2069;
}
.demo-right-bg .demo-right-container blockquote svg {
  display: block;
  width: 40px;
  margin-bottom: 1rem;
}
.demo-right-bg .demo-right-container span {
  color: #2A2069;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0.01rem;
}
.demo-right-bg .demo-right-container span .demo-quote-rating {
  height: 1rem;
  width: auto;
  margin-top: -0.25rem;
}
.demo-right-bg .demo-right-container .demo-g2-badges .demo-g2-badge-item {
  float: left;
  width: 25%;
  padding: 1rem;
}
.demo-right-bg .demo-right-container .demo-g2-badges .demo-g2-badge-item svg {
  width: 100%;
  height: auto;
}

.demo-left-container {
  max-width: 32rem;
  margin: auto;
}
.demo-left-container .demo-brand {
  margin: auto;
  display: block;
  width: 78px;
}
.demo-left-container .demo-avatar-stack {
  float: none;
  display: block;
  margin: auto;
  overflow: hidden;
  width: 7.5rem;
  height: 2.75rem;
  position: relative;
}
.demo-left-container .demo-avatar-stack picture {
  position: absolute;
  width: 2.75rem;
  height: 2.75rem;
}
.demo-left-container .demo-avatar-stack picture:nth-child(1) {
  left: 0;
}
.demo-left-container .demo-avatar-stack picture:nth-child(2) {
  left: 2.4rem;
}
.demo-left-container .demo-avatar-stack picture:nth-child(3) {
  left: 4.8rem;
}
.demo-left-container .demo-avatar-stack .demo-avatar {
  border-radius: 50%;
  border: 2px solid white;
  width: 2.75rem;
  height: 2.75rem;
  float: left;
}
.demo-left-container .demo-customer-logos .demo-customer-logo-item {
  width: 33.3333%;
  float: left;
}
.demo-left-container .demo-customer-logos .demo-customer-logo-item svg {
  width: 100%;
  height: auto;
}
.demo-left-container .submitted-message {
  background: linear-gradient(200deg, rgb(242, 95, 92) 0%, rgb(86, 68, 216) 100%);
  padding: 3rem;
  border-radius: 1rem;
  color: white;
}
.demo-left-container .submitted-message p {
  color: #ffffff !important;
  font-size: 1rem;
  line-height: 2rem;
  text-align: center;
  font-weight: 600;
  margin: 0;
}
.demo-left-container .submitted-message p:first-child {
  color: #ffffff !important;
  font-size: 1.5rem;
  line-height: 2.5rem;
  text-align: center;
  font-weight: 600;
  margin-bottom: 1rem;
}
.demo-left-container form label, .demo-left-container form legend {
  display: none !important;
}
.demo-left-container form .hs-button {
  background: linear-gradient(-45deg, #2A2069, #5644d8, #F25F5C);
  background-size: 400% 400%;
  animation: gradient 14s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
section.block-postsummary .container .icons .icon, section.block-postsummary .single-customers main > article > .wp-block-group .icons .icon, .single-customers section.block-postsummary main > article > .wp-block-group .icons .icon, section.block-postsummary .alignwide .icons .icon,
section.block-postsummary .wp-block-columns .icons .icon, section.block-postsummary .wp-block.editor-post-title .icons .icon, section.block-postsummary .wp-block[data-align=wide] .icons .icon, section.block-postsummary .single-podcasts main > article > .wp-block-group .icons .icon, .single-podcasts section.block-postsummary main > article > .wp-block-group .icons .icon {
  width: 60px;
  height: 60px;
  background-color: #1d1d1b;
  display: block;
  position: relative;
  transition: 100ms background-color ease;
  border: 2px solid #1d1d1b;
}
section.block-postsummary .container .icons .icon svg, section.block-postsummary .single-customers main > article > .wp-block-group .icons .icon svg, .single-customers section.block-postsummary main > article > .wp-block-group .icons .icon svg, section.block-postsummary .alignwide .icons .icon svg,
section.block-postsummary .wp-block-columns .icons .icon svg, section.block-postsummary .wp-block.editor-post-title .icons .icon svg, section.block-postsummary .wp-block[data-align=wide] .icons .icon svg, section.block-postsummary .single-podcasts main > article > .wp-block-group .icons .icon svg, .single-podcasts section.block-postsummary main > article > .wp-block-group .icons .icon svg {
  width: 50%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
section.block-postsummary .container .icons .icon svg path, section.block-postsummary .single-customers main > article > .wp-block-group .icons .icon svg path, .single-customers section.block-postsummary main > article > .wp-block-group .icons .icon svg path, section.block-postsummary .alignwide .icons .icon svg path,
section.block-postsummary .wp-block-columns .icons .icon svg path, section.block-postsummary .wp-block.editor-post-title .icons .icon svg path, section.block-postsummary .wp-block[data-align=wide] .icons .icon svg path, section.block-postsummary .single-podcasts main > article > .wp-block-group .icons .icon svg path, .single-podcasts section.block-postsummary main > article > .wp-block-group .icons .icon svg path,
section.block-postsummary .container .icons .icon svg circle,
section.block-postsummary .single-customers main > article > .wp-block-group .icons .icon svg circle,
.single-customers section.block-postsummary main > article > .wp-block-group .icons .icon svg circle,
section.block-postsummary .alignwide .icons .icon svg circle,
section.block-postsummary .wp-block-columns .icons .icon svg circle,
section.block-postsummary .wp-block.editor-post-title .icons .icon svg circle,
section.block-postsummary .wp-block[data-align=wide] .icons .icon svg circle,
section.block-postsummary .single-podcasts main > article > .wp-block-group .icons .icon svg circle,
.single-podcasts section.block-postsummary main > article > .wp-block-group .icons .icon svg circle {
  transition: 100ms background-color ease;
}
section.block-postsummary .container .icons .icon:hover, section.block-postsummary .single-customers main > article > .wp-block-group .icons .icon:hover, .single-customers section.block-postsummary main > article > .wp-block-group .icons .icon:hover, section.block-postsummary .alignwide .icons .icon:hover,
section.block-postsummary .wp-block-columns .icons .icon:hover, section.block-postsummary .wp-block.editor-post-title .icons .icon:hover, section.block-postsummary .wp-block[data-align=wide] .icons .icon:hover, section.block-postsummary .single-podcasts main > article > .wp-block-group .icons .icon:hover, .single-podcasts section.block-postsummary main > article > .wp-block-group .icons .icon:hover {
  background-color: #ffffff;
}
section.block-postsummary .container .icons .icon:hover svg path, section.block-postsummary .single-customers main > article > .wp-block-group .icons .icon:hover svg path, .single-customers section.block-postsummary main > article > .wp-block-group .icons .icon:hover svg path, section.block-postsummary .alignwide .icons .icon:hover svg path,
section.block-postsummary .wp-block-columns .icons .icon:hover svg path, section.block-postsummary .wp-block.editor-post-title .icons .icon:hover svg path, section.block-postsummary .wp-block[data-align=wide] .icons .icon:hover svg path, section.block-postsummary .single-podcasts main > article > .wp-block-group .icons .icon:hover svg path, .single-podcasts section.block-postsummary main > article > .wp-block-group .icons .icon:hover svg path,
section.block-postsummary .container .icons .icon:hover svg circle,
section.block-postsummary .single-customers main > article > .wp-block-group .icons .icon:hover svg circle,
.single-customers section.block-postsummary main > article > .wp-block-group .icons .icon:hover svg circle,
section.block-postsummary .alignwide .icons .icon:hover svg circle,
section.block-postsummary .wp-block-columns .icons .icon:hover svg circle,
section.block-postsummary .wp-block.editor-post-title .icons .icon:hover svg circle,
section.block-postsummary .wp-block[data-align=wide] .icons .icon:hover svg circle,
section.block-postsummary .single-podcasts main > article > .wp-block-group .icons .icon:hover svg circle,
.single-podcasts section.block-postsummary main > article > .wp-block-group .icons .icon:hover svg circle {
  fill: #1d1d1b;
}

.featured-customer {
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: #2A2069;
  color: #ffffff;
}
.featured-customer .inner {
  padding: 60px 32px 32px;
}
@media (min-width: 992px) {
  .featured-customer .inner {
    padding: 120px 80px 80px;
  }
}
.featured-customer figure blockquote {
  position: relative;
  padding: 0;
  border: none;
}
.featured-customer figure blockquote svg {
  display: block;
  width: 40px;
  position: absolute;
  top: -50px;
  left: 0;
}
.featured-customer figure figcaption span {
  display: block;
}
.featured-customer .logo {
  filter: grayscale(0%) invert(100%) brightness(2);
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.features-slider {
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: #ffffff;
  overflow: hidden;
}
.features-slider .inner, .features-slider .highlights {
  padding: 32px;
}
@media (min-width: 1200px) {
  .features-slider .inner, .features-slider .highlights {
    padding: 0 40px 0 20px;
  }
}
.features-slider .feature-item {
  overflow: hidden;
}
.features-slider .feature-item picture {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  bottom: -17px;
  left: -40px;
}
.features-slider .feature-item picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
}
.features-slider .feature-item a {
  transition: all 0.3s ease-in-out;
}
.features-slider .feature-item a:hover {
  color: #2A2069;
}
.features-slider .glide__bullet {
  position: relative;
  border: 0;
  overflow: hidden;
  color: #2A2069;
  background-color: #FFF5E6;
}
.features-slider .glide__bullet::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0;
  z-index: 0;
  transform: translateX(-100%);
  background-color: #FFE6C1;
  transition: transform 5.4s linear, opacity 0.2s linear;
}
.features-slider .glide__bullet span {
  position: relative;
  z-index: 1;
}
@media (min-width: 768px) {
  .features-slider .glide__bullet span {
    font-size: 16px;
    font-weight: bold;
  }
}
.features-slider .glide__bullet--active::before {
  opacity: 1;
  transform: translateX(0);
}

.block-hero {
  position: relative;
  overflow: hidden;
}
@media (min-width: 992px) {
  .block-hero {
    height: fit-content;
  }
}
.block-hero video.cover {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  object-fit: cover;
  background-color: #D6CCC2;
  background-size: cover;
  transition: all 500ms ease;
}
.block-hero .container, .block-hero .single-customers main > article > .wp-block-group, .single-customers .block-hero main > article > .wp-block-group, .block-hero .alignwide,
.block-hero .wp-block-columns, .block-hero .wp-block.editor-post-title, .block-hero .wp-block[data-align=wide], .block-hero .single-podcasts main > article > .wp-block-group, .single-podcasts .block-hero main > article > .wp-block-group {
  position: relative;
}
.block-hero__subtitle {
  opacity: 0.7;
}
@media (min-width: 768px) {
  .block-hero__subtitle {
    font-size: 16px !important;
  }
}
.block-hero .hero-book-form label, .block-hero .hero-book-form legend {
  display: none !important;
}
.block-hero .hero-book-form .hs-email {
  float: left;
}
.block-hero .hero-book-form .hs-email input {
  border-radius: 1rem 0 0 1rem;
  border: 2px solid #5644d8;
  padding-top: 0.5rem;
}
.block-hero .hero-book-form .hs-email input.invalid.error::placeholder, .block-hero .hero-book-form .hs-email input.invalid.error {
  color: #F25F5C;
}
.block-hero .hero-book-form .hs-submit {
  float: left;
}
.block-hero .hero-book-form .hs-submit .hs-button {
  margin-top: 0 !important;
  border-radius: 0 1rem 1rem 0;
  padding-top: 1rem !important;
}
.block-hero .hero-book-form .hs-submit .hs-button:hover {
  transform: scale(1);
}
.block-hero .hero-book-form .hs-error-msgs {
  display: none;
}

.hero-banner {
  min-height: 156px;
  background-repeat: no-repeat;
  border-radius: 2rem;
  overflow: hidden;
  background-color: #ffffff;
  color: #1d1d1b;
}
.hero-banner .image-holder {
  min-height: 156px;
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-position-x: center;
}
@media (max-width: 767.98px) {
  .hero-banner {
    background: rgb(242, 95, 92);
    background: linear-gradient(263deg, rgb(242, 95, 92) 0%, rgb(86, 68, 216) 100%);
    color: #ffffff;
  }
  .hero-banner .btn-naked-purple {
    color: #ffffff !important;
  }
}

@media (max-width: 991.98px) {
  .media-side:has(img) {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    overflow: hidden;
  }
}
section.template-bff {
  height: 450px;
  position: relative;
}
@media (max-width: 1199.98px) {
  section.template-bff {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
}
@media (max-width: 991.98px) {
  section.template-bff {
    margin-top: 3rem;
  }
}
@media (max-width: 575.98px) {
  section.template-bff {
    margin-top: 0rem;
  }
}
section.template-bff .person {
  position: absolute;
}
@media (max-width: 1199.98px) {
  section.template-bff .person {
    position: relative;
    flex: 0 0 auto;
    width: 50% !important;
    height: auto !important;
    bottom: auto !important;
    top: auto !important;
    right: auto !important;
    left: auto !important;
    margin-bottom: 1rem;
  }
}
section.template-bff .person .text {
  width: 120px;
  text-align: center;
  font-size: 12px;
}
section.template-bff .person .text h4, section.template-bff .person .text .h4 {
  margin-bottom: 0.5rem;
}
@media (max-width: 1199.98px) {
  section.template-bff .person .text {
    position: relative !important;
    width: 100%;
    top: auto !important;
    right: auto !important;
    left: auto !important;
    bottom: auto !important;
  }
}
@media (max-width: 575.98px) {
  section.template-bff .person .text {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
section.template-bff .person .image {
  width: 170px;
  height: 170px;
  position: relative;
}
@media (max-width: 1499.98px) {
  section.template-bff .person .image {
    width: 130px;
    height: 130px;
  }
}
@media (max-width: 1199.98px) {
  section.template-bff .person .image {
    position: relative !important;
    width: 150px;
    height: 150px;
    bottom: auto !important;
    top: auto !important;
    right: auto !important;
    left: 50% !important;
    transform: translateX(-50%);
    margin-bottom: 1rem;
  }
}
@media (max-width: 767.98px) {
  section.template-bff .person .image {
    position: relative !important;
    width: 170px;
    height: 170px;
    bottom: auto !important;
    top: auto !important;
    right: auto !important;
    left: 50% !important;
    transform: translateX(-50%);
    margin-bottom: 1rem;
  }
}
@media (max-width: 575.98px) {
  section.template-bff .person .image {
    position: relative !important;
    width: 110px;
    height: 110px;
    bottom: auto !important;
    top: auto !important;
    right: auto !important;
    left: 50% !important;
    transform: translateX(-50%);
    margin-bottom: 1rem;
  }
}
@media (max-width: 1199.98px) {
  section.template-bff .person .image img {
    position: relative !important;
    width: 100%;
    height: auto;
    bottom: auto !important;
    top: auto !important;
    right: auto !important;
    left: auto !important;
  }
}
section.template-bff .person .image .hover-wrapper {
  overflow: hidden;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 173px;
  height: 173px;
  transform: translate(-50%, -50%);
  clip-path: circle(0%);
  transition: 200ms clip-path ease;
}
@media (max-width: 1499.98px) {
  section.template-bff .person .image .hover-wrapper {
    width: 133px;
    height: 133px;
  }
}
@media (max-width: 1199.98px) {
  section.template-bff .person .image .hover-wrapper {
    width: 150px;
    height: 150px;
  }
}
@media (max-width: 991.98px) {
  section.template-bff .person .image .hover-wrapper {
    display: none;
  }
}
section.template-bff .person .image .hover-wrapper svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}
section.template-bff .person .image:hover .hover-wrapper {
  transition: 500ms clip-path cubic-bezier(0.5, 0.25, 0, 1);
  clip-path: circle(100%);
}
section.template-bff .person.person-1 {
  top: 70px;
  left: 0px;
  width: 300px;
  height: 188px;
}
@media (max-width: 1499.98px) {
  section.template-bff .person.person-1 {
    width: 240px;
    top: 70px;
    left: 0px;
  }
}
section.template-bff .person.person-1 .image {
  position: absolute;
  right: 0px;
  top: 0px;
}
section.template-bff .person.person-1 .text {
  position: absolute;
  left: 0px;
  top: 70px;
}
section.template-bff .person.person-2 {
  top: 0px;
  right: 0px;
  width: 290px;
  height: 188px;
}
@media (max-width: 1499.98px) {
  section.template-bff .person.person-2 {
    top: 0px;
    right: 0px;
    width: 260px;
    height: 188px;
  }
}
section.template-bff .person.person-2 .image {
  position: absolute;
  left: 0px;
  top: 0px;
}
section.template-bff .person.person-2 .text {
  position: absolute;
  right: 0px;
  top: 0px;
}
section.template-bff .person.person-3 {
  bottom: 0px;
  left: 120px;
  width: 290px;
  height: 188px;
}
@media (max-width: 1499.98px) {
  section.template-bff .person.person-3 {
    width: 230px;
    bottom: 60px;
    left: 100px;
  }
}
section.template-bff .person.person-3 .image {
  position: absolute;
  right: 0px;
  top: 0px;
}
section.template-bff .person.person-3 .text {
  position: absolute;
  left: 0px;
  top: 100px;
}
section.template-bff .person.person-4 {
  bottom: 20px;
  right: 0px;
  width: 170px;
  height: 250px;
}
@media (max-width: 1499.98px) {
  section.template-bff .person.person-4 {
    bottom: 90px;
    right: 20px;
    width: 130px;
    height: 210px;
  }
}
section.template-bff .person.person-4 .image {
  position: absolute;
  right: 0px;
  top: 0px;
}
section.template-bff .person.person-4 .text {
  position: absolute;
  left: 27px;
  bottom: 0px;
}
@media (max-width: 1499.98px) {
  section.template-bff .person.person-4 .text {
    left: 13px;
  }
}
section.template-bff .hands {
  position: absolute;
  top: 110px;
  right: 80px;
}
@media (max-width: 1499.98px) {
  section.template-bff .hands {
    top: 110px;
    right: 113px;
  }
  section.template-bff .hands svg {
    width: 60px;
    height: auto;
  }
}
@media (max-width: 1199.98px) {
  section.template-bff .hands {
    top: 50px;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (max-width: 575.98px) {
  section.template-bff .hands {
    top: 30px;
  }
  section.template-bff .hands svg {
    width: 50px;
    height: auto;
  }
}
section.template-bff .hands-2 {
  position: absolute;
  top: 210px;
  left: 240px;
}
@media (max-width: 1499.98px) {
  section.template-bff .hands-2 {
    top: 180px;
    left: 190px;
  }
  section.template-bff .hands-2 svg {
    width: 60px;
    height: auto;
  }
}
@media (max-width: 1199.98px) {
  section.template-bff .hands-2 {
    top: 300px;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (max-width: 575.98px) {
  section.template-bff .hands-2 {
    top: 250px;
  }
  section.template-bff .hands-2 svg {
    width: 50px;
    height: auto;
  }
}

.block-herocenter {
  position: relative;
  overflow: hidden;
  padding-bottom: 0 !important;
}
@media (min-width: 992px) {
  .block-herocenter {
    height: fit-content;
  }
}
.block-herocenter video.cover {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  object-fit: cover;
  background-color: #D6CCC2;
  background-size: cover;
  transition: all 500ms ease;
}
.block-herocenter .container, .block-herocenter .single-customers main > article > .wp-block-group, .single-customers .block-herocenter main > article > .wp-block-group, .block-herocenter .alignwide,
.block-herocenter .wp-block-columns, .block-herocenter .wp-block.editor-post-title, .block-herocenter .wp-block[data-align=wide], .block-herocenter .single-podcasts main > article > .wp-block-group, .single-podcasts .block-herocenter main > article > .wp-block-group {
  position: relative;
}
.block-herocenter__subtitle {
  opacity: 0.7;
}
@media (min-width: 768px) {
  .block-herocenter__subtitle {
    font-size: 16px !important;
  }
}
.block-herocenter .media-side-holder {
  width: 80%;
}
@media (max-width: 1199px) {
  .block-herocenter h2.h1 br, .block-herocenter .h1.h2 br {
    display: none;
  }
  .block-herocenter .buttons {
    width: auto;
  }
  .block-herocenter .buttons .btn, .block-herocenter .buttons .mm-ocd .mm-ocd__content #mobile-menu a.btn, .mm-ocd .mm-ocd__content #mobile-menu .block-herocenter .buttons a.btn, .block-herocenter .buttons form input[type=submit], form .block-herocenter .buttons input[type=submit] {
    margin: auto;
  }
  .block-herocenter .media-side-holder {
    width: 100%;
  }
  .block-herocenter .media-side-holder .media-side {
    background-color: transparent;
    border-radius: 0;
    overflow: hidden;
    padding-bottom: 0;
  }
}

.hero-banner {
  min-height: 156px;
  background-repeat: no-repeat;
  border-radius: 2rem;
  overflow: hidden;
  background-color: #ffffff;
  color: #1d1d1b;
}
.hero-banner .image-holder {
  min-height: 156px;
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-position-x: center;
}
@media (max-width: 767.98px) {
  .hero-banner {
    background: rgb(242, 95, 92);
    background: linear-gradient(263deg, rgb(242, 95, 92) 0%, rgb(86, 68, 216) 100%);
    color: #ffffff;
  }
  .hero-banner .btn-naked-purple {
    color: #ffffff !important;
  }
}

@media (max-width: 991.98px) {
  .media-side:has(img) {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    overflow: hidden;
  }
}
section.home-customers figure blockquote {
  position: relative;
  padding: 0;
  margin-top: 40px;
  border: none;
}
section.home-customers figure blockquote svg {
  display: block;
  width: 40px;
  position: absolute;
  top: -40px;
  left: 0;
}
section.home-customers figure figcaption span {
  display: block;
}
section.home-customers .logo {
  display: block;
  width: 100%;
  height: 50px;
  object-fit: contain;
  filter: grayscale(0%) invert(100%) brightness(2);
}
@media (min-width: 992px) {
  section.home-customers .logo {
    height: 70px;
  }
}

@media (max-width: 1000px) and (min-width: 769px) {
  section.home-customers .container, section.home-customers .single-customers main > article > .wp-block-group, .single-customers section.home-customers main > article > .wp-block-group, section.home-customers .alignwide,
  section.home-customers .wp-block-columns, section.home-customers .wp-block.editor-post-title, section.home-customers .wp-block[data-align=wide], section.home-customers .single-podcasts main > article > .wp-block-group, .single-podcasts section.home-customers main > article > .wp-block-group {
    max-width: 100% !important;
  }
}
@media (max-width: 1000px) and (min-width: 769px) {
  section.home-featured-content .container, section.home-featured-content .single-customers main > article > .wp-block-group, .single-customers section.home-featured-content main > article > .wp-block-group, section.home-featured-content .alignwide,
  section.home-featured-content .wp-block-columns, section.home-featured-content .wp-block.editor-post-title, section.home-featured-content .wp-block[data-align=wide], section.home-featured-content .single-podcasts main > article > .wp-block-group, .single-podcasts section.home-featured-content main > article > .wp-block-group {
    max-width: 100% !important;
  }
}
section.one-platform .op-separator, section.block-three-col-icon .op-separator {
  height: 2px;
  opacity: 0.5;
  margin: 1.5rem 0 1rem 0;
  border-radius: 1px;
}
section.one-platform .icon svg, section.block-three-col-icon .icon svg {
  height: 100%;
  width: auto;
}
section.one-platform .op-icon-holder, section.block-three-col-icon .op-icon-holder {
  width: 50px;
  height: 50px;
}
section.one-platform .op-icon-holder.rect, section.block-three-col-icon .op-icon-holder.rect {
  border-radius: 9px;
}
section.one-platform .op-icon-holder.circ, section.block-three-col-icon .op-icon-holder.circ {
  border-radius: 25px;
}
section.one-platform .op-icon-holder .icon, section.one-platform .op-icon-holder .icon svg, section.block-three-col-icon .op-icon-holder .icon, section.block-three-col-icon .op-icon-holder .icon svg {
  width: 32px;
  height: 32px;
}

section.block-three-col-icon .op-icon-holder {
  margin: auto;
}

@media (max-width: 1000px) and (min-width: 769px) {
  section.one-platform .container, section.one-platform .single-customers main > article > .wp-block-group, .single-customers section.one-platform main > article > .wp-block-group, section.one-platform .alignwide,
  section.one-platform .wp-block-columns, section.one-platform .wp-block.editor-post-title, section.one-platform .wp-block[data-align=wide], section.one-platform .single-podcasts main > article > .wp-block-group, .single-podcasts section.one-platform main > article > .wp-block-group {
    max-width: 100% !important;
  }
}
.lp-right-bg {
  background: linear-gradient(180deg, #FFB840 0%, #FFE6C1 100%);
  min-height: auto;
  position: relative;
  padding-bottom: 3rem;
}
@media (min-width: 992px) {
  .lp-right-bg {
    min-height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
  }
}
.lp-right-bg .lp-right-container {
  max-width: 900px;
  margin: auto;
}
@media (min-width: 992px) {
  .lp-right-bg .lp-right-container {
    max-width: 40rem;
  }
}
.lp-right-bg .lp-right-container .lp-avatar-stack {
  float: none;
  display: block;
  margin: auto;
  overflow: hidden;
  width: 7.5rem;
  height: 2.75rem;
  position: relative;
}
.lp-right-bg .lp-right-container .lp-avatar-stack picture {
  position: absolute;
  width: 2.75rem;
  height: 2.75rem;
}
.lp-right-bg .lp-right-container .lp-avatar-stack picture:nth-child(1) {
  left: 0;
}
.lp-right-bg .lp-right-container .lp-avatar-stack picture:nth-child(2) {
  left: 2.4rem;
}
.lp-right-bg .lp-right-container .lp-avatar-stack picture:nth-child(3) {
  left: 4.8rem;
}
.lp-right-bg .lp-right-container .lp-avatar-stack .lp-avatar {
  border-radius: 50%;
  border: 2px solid white;
  width: 2.75rem;
  height: 2.75rem;
  float: left;
}
.lp-right-bg .lp-right-container .submitted-message {
  background: linear-gradient(200deg, rgb(242, 95, 92) 0%, rgb(86, 68, 216) 100%);
  padding: 3rem;
  border-radius: 1rem;
  color: white;
}
.lp-right-bg .lp-right-container .submitted-message p {
  color: #ffffff !important;
  font-size: 1rem;
  line-height: 2rem;
  text-align: center;
  font-weight: 600;
  margin: 0;
}
.lp-right-bg .lp-right-container .submitted-message p:first-child {
  color: #ffffff !important;
  font-size: 1.5rem;
  line-height: 2.5rem;
  text-align: center;
  font-weight: 600;
  margin-bottom: 1rem;
}
.lp-right-bg .lp-right-container form label, .lp-right-bg .lp-right-container form legend {
  display: none !important;
}
.lp-right-bg .lp-right-container form .hs-button {
  background: linear-gradient(-45deg, #2A2069, #5644d8, #F25F5C);
  background-size: 400% 400%;
  animation: gradient 14s ease infinite;
}

.lp-left-container {
  max-width: 900px;
  margin: auto;
}
.lp-left-container .lp-brand {
  display: block;
  width: 78px;
}
.lp-left-container .lp-customer-logos svg {
  width: 100%;
  height: auto;
}
.lp-left-container blockquote {
  margin-top: 4rem;
  position: relative;
  padding: 0;
  border: none;
  color: #2A2069;
}
.lp-left-container .lp-g2-badges .lp-g2-badge-item {
  padding: 1rem;
}
.lp-left-container .lp-g2-badges .lp-g2-badge-item svg {
  width: 100%;
  height: auto;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.s-image-bg-block {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.mid-page-inner {
  padding: 40px 20px;
}
@media (min-width: 1200px) {
  .mid-page-inner {
    padding: 80px 80px;
  }
}

section.block-newslettersignup .big-card {
  overflow: hidden;
}
section.block-newslettersignup .hubspot-form-wrapper {
  border: none !important;
  border-radius: 2rem;
}

@media (min-width: 992px) {
  .podcast-hero {
    margin-bottom: 0;
  }
}
.podcast-hero .container, .podcast-hero .single-customers main > article > .wp-block-group, .single-customers .podcast-hero main > article > .wp-block-group, .podcast-hero .alignwide,
.podcast-hero .wp-block-columns, .podcast-hero .wp-block.editor-post-title, .podcast-hero .wp-block[data-align=wide], .podcast-hero .single-podcasts main > article > .wp-block-group, .single-podcasts .podcast-hero main > article > .wp-block-group {
  height: 80vh;
}
@media (min-width: 992px) {
  .podcast-hero .container, .podcast-hero .single-customers main > article > .wp-block-group, .single-customers .podcast-hero main > article > .wp-block-group, .podcast-hero .alignwide,
  .podcast-hero .wp-block-columns, .podcast-hero .wp-block.editor-post-title, .podcast-hero .wp-block[data-align=wide], .podcast-hero .single-podcasts main > article > .wp-block-group, .single-podcasts .podcast-hero main > article > .wp-block-group {
    height: 65vh;
  }
}
.podcast-hero .lead {
  font-size: 1.5rem;
}
.podcast-hero .btn svg, .podcast-hero .mm-ocd .mm-ocd__content #mobile-menu a.btn svg, .mm-ocd .mm-ocd__content #mobile-menu .podcast-hero a.btn svg, .podcast-hero form input[type=submit] svg, form .podcast-hero input[type=submit] svg {
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.podcast-hero .btn svg path, .podcast-hero .mm-ocd .mm-ocd__content #mobile-menu a.btn svg path, .mm-ocd .mm-ocd__content #mobile-menu .podcast-hero a.btn svg path, .podcast-hero form input[type=submit] svg path, form .podcast-hero input[type=submit] svg path {
  fill: #5644d8 !important;
}
.podcast-hero .btn:hover svg path, .podcast-hero .mm-ocd .mm-ocd__content #mobile-menu a.btn:hover svg path, .mm-ocd .mm-ocd__content #mobile-menu .podcast-hero a.btn:hover svg path, .podcast-hero form input[type=submit]:hover svg path, form .podcast-hero input[type=submit]:hover svg path {
  fill: #ffffff !important;
}
.podcast-hero__player {
  position: relative;
  width: 100%;
}

.post-slider {
  overflow: hidden;
}
.post-slider .glide__slide {
  align-self: stretch;
  height: auto;
}

section.block-postsummary .container .icons .icon, section.block-postsummary .single-customers main > article > .wp-block-group .icons .icon, .single-customers section.block-postsummary main > article > .wp-block-group .icons .icon, section.block-postsummary .alignwide .icons .icon,
section.block-postsummary .wp-block-columns .icons .icon, section.block-postsummary .wp-block.editor-post-title .icons .icon, section.block-postsummary .wp-block[data-align=wide] .icons .icon, section.block-postsummary .single-podcasts main > article > .wp-block-group .icons .icon, .single-podcasts section.block-postsummary main > article > .wp-block-group .icons .icon {
  width: 60px;
  height: 60px;
  background-color: #1d1d1b;
  display: block;
  position: relative;
  transition: 100ms background-color ease;
  border: 2px solid #1d1d1b;
}
section.block-postsummary .container .icons .icon svg, section.block-postsummary .single-customers main > article > .wp-block-group .icons .icon svg, .single-customers section.block-postsummary main > article > .wp-block-group .icons .icon svg, section.block-postsummary .alignwide .icons .icon svg,
section.block-postsummary .wp-block-columns .icons .icon svg, section.block-postsummary .wp-block.editor-post-title .icons .icon svg, section.block-postsummary .wp-block[data-align=wide] .icons .icon svg, section.block-postsummary .single-podcasts main > article > .wp-block-group .icons .icon svg, .single-podcasts section.block-postsummary main > article > .wp-block-group .icons .icon svg {
  width: 50%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
section.block-postsummary .container .icons .icon svg path, section.block-postsummary .single-customers main > article > .wp-block-group .icons .icon svg path, .single-customers section.block-postsummary main > article > .wp-block-group .icons .icon svg path, section.block-postsummary .alignwide .icons .icon svg path,
section.block-postsummary .wp-block-columns .icons .icon svg path, section.block-postsummary .wp-block.editor-post-title .icons .icon svg path, section.block-postsummary .wp-block[data-align=wide] .icons .icon svg path, section.block-postsummary .single-podcasts main > article > .wp-block-group .icons .icon svg path, .single-podcasts section.block-postsummary main > article > .wp-block-group .icons .icon svg path,
section.block-postsummary .container .icons .icon svg circle,
section.block-postsummary .single-customers main > article > .wp-block-group .icons .icon svg circle,
.single-customers section.block-postsummary main > article > .wp-block-group .icons .icon svg circle,
section.block-postsummary .alignwide .icons .icon svg circle,
section.block-postsummary .wp-block-columns .icons .icon svg circle,
section.block-postsummary .wp-block.editor-post-title .icons .icon svg circle,
section.block-postsummary .wp-block[data-align=wide] .icons .icon svg circle,
section.block-postsummary .single-podcasts main > article > .wp-block-group .icons .icon svg circle,
.single-podcasts section.block-postsummary main > article > .wp-block-group .icons .icon svg circle {
  transition: 100ms background-color ease;
}
section.block-postsummary .container .icons .icon:hover, section.block-postsummary .single-customers main > article > .wp-block-group .icons .icon:hover, .single-customers section.block-postsummary main > article > .wp-block-group .icons .icon:hover, section.block-postsummary .alignwide .icons .icon:hover,
section.block-postsummary .wp-block-columns .icons .icon:hover, section.block-postsummary .wp-block.editor-post-title .icons .icon:hover, section.block-postsummary .wp-block[data-align=wide] .icons .icon:hover, section.block-postsummary .single-podcasts main > article > .wp-block-group .icons .icon:hover, .single-podcasts section.block-postsummary main > article > .wp-block-group .icons .icon:hover {
  background-color: #ffffff;
}
section.block-postsummary .container .icons .icon:hover svg path, section.block-postsummary .single-customers main > article > .wp-block-group .icons .icon:hover svg path, .single-customers section.block-postsummary main > article > .wp-block-group .icons .icon:hover svg path, section.block-postsummary .alignwide .icons .icon:hover svg path,
section.block-postsummary .wp-block-columns .icons .icon:hover svg path, section.block-postsummary .wp-block.editor-post-title .icons .icon:hover svg path, section.block-postsummary .wp-block[data-align=wide] .icons .icon:hover svg path, section.block-postsummary .single-podcasts main > article > .wp-block-group .icons .icon:hover svg path, .single-podcasts section.block-postsummary main > article > .wp-block-group .icons .icon:hover svg path,
section.block-postsummary .container .icons .icon:hover svg circle,
section.block-postsummary .single-customers main > article > .wp-block-group .icons .icon:hover svg circle,
.single-customers section.block-postsummary main > article > .wp-block-group .icons .icon:hover svg circle,
section.block-postsummary .alignwide .icons .icon:hover svg circle,
section.block-postsummary .wp-block-columns .icons .icon:hover svg circle,
section.block-postsummary .wp-block.editor-post-title .icons .icon:hover svg circle,
section.block-postsummary .wp-block[data-align=wide] .icons .icon:hover svg circle,
section.block-postsummary .single-podcasts main > article > .wp-block-group .icons .icon:hover svg circle,
.single-podcasts section.block-postsummary main > article > .wp-block-group .icons .icon:hover svg circle {
  fill: #1d1d1b;
}

section.block-pricingcardsnew {
  position: relative;
}
section.block-pricingcardsnew::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 30vh;
  left: 0;
  top: 0;
  z-index: -1;
  background-color: #E1DBF7;
}
section.block-pricingcardsnew .collapse_button a.collapsed .hide_features {
  display: none;
}
section.block-pricingcardsnew .collapse_button a:not(.collapsed) .show_features {
  display: none;
}
section.block-pricingcardsnew .package:not(:first-child) .features > ul > li:first-child {
  color: #5644d8;
}
section.block-pricingcardsnew .package .price {
  width: 100%;
  height: 35px;
  overflow: hidden;
}
section.block-pricingcardsnew .package .price h3:first-child, section.block-pricingcardsnew .package .price .h3:first-child, section.block-pricingcardsnew .package .price .form-wrapper.inline .submitted-message:first-child, .form-wrapper.inline section.block-pricingcardsnew .package .price .submitted-message:first-child {
  transform: translateY(-100%);
  transition: all 0.4s ease-in-out;
}
section.block-pricingcardsnew .package .price h3:first-child.active, section.block-pricingcardsnew .package .price .h3:first-child.active, section.block-pricingcardsnew .package .price .form-wrapper.inline .submitted-message:first-child.active, .form-wrapper.inline section.block-pricingcardsnew .package .price .submitted-message:first-child.active {
  transform: translateY(0);
}
section.block-pricingcardsnew .package .price h3:nth-child(2), section.block-pricingcardsnew .package .price .h3:nth-child(2), section.block-pricingcardsnew .package .price .form-wrapper.inline .submitted-message:nth-child(2), .form-wrapper.inline section.block-pricingcardsnew .package .price .submitted-message:nth-child(2) {
  transform: translateY(0);
  transition: all 0.4s ease-in-out;
}
section.block-pricingcardsnew .package .price h3:nth-child(2).active, section.block-pricingcardsnew .package .price .h3:nth-child(2).active, section.block-pricingcardsnew .package .price .form-wrapper.inline .submitted-message:nth-child(2).active, .form-wrapper.inline section.block-pricingcardsnew .package .price .submitted-message:nth-child(2).active {
  transform: translateY(-100%);
}
section.block-pricingcardsnew .features ul li {
  font-size: 14px;
}
section.block-pricingcardsnew .features .accordion-body p:last-of-type {
  margin-bottom: 0;
}

section.block-quoteslider .navigation {
  z-index: 1;
}
section.block-quoteslider .logo {
  width: 30%;
}
@media (max-width: 767.98px) {
  section.block-quoteslider .logo {
    width: 50%;
  }
}

section.block-three-col-icon .icon svg {
  height: 100%;
  width: auto;
}
section.block-three-col-icon .card-icon-holder {
  width: 70px;
  height: 70px;
  border-radius: 35px;
  margin: auto;
}
section.block-three-col-icon .card-icon-holder .icon, section.block-three-col-icon .card-icon-holder .icon svg {
  width: 32px;
  height: 32px;
}

section.block-timeline {
  overflow: hidden;
}
section.block-timeline .glide .glide__track {
  overflow: visible;
}

.block-two-columns .text__para ul {
  list-style: none;
  padding-left: 1rem;
}
.block-two-columns .text__para ul li {
  display: flex;
  width: 100%;
  line-height: 1.6;
  margin-bottom: 0.5rem;
}
.block-two-columns .text__para ul li::marker {
  display: none;
}
.block-two-columns .text__para ul li::before {
  display: block;
  content: url('data:image/svg+xml,%0A%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M20.86 14.13C20 14.7 19.56 15.74 19.77 16.76C20.13 18.55 18.55 20.13 16.76 19.77C15.74 19.57 14.7 20 14.13 20.86C13.12 22.38 10.89 22.38 9.88 20.86C9.3 20 8.26 19.56 7.24 19.77C5.45 20.13 3.87 18.55 4.23 16.76C4.43 15.74 4 14.7 3.14 14.13C1.62 13.12 1.62 10.89 3.14 9.88C4 9.3 4.44 8.26 4.23 7.24C3.87 5.45 5.45 3.87 7.24 4.23C8.26 4.44 9.3 4 9.87 3.14C10.88 1.62 13.11 1.62 14.12 3.14C14.7 4 15.74 4.44 16.76 4.23C18.55 3.87 20.13 5.45 19.77 7.24C19.56 8.26 20 9.3 20.86 9.87C22.38 10.88 22.38 13.12 20.86 14.13Z" stroke="darkblue" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" /%3E%3Cpath d="M8 12L10.5 15L16 9" stroke="darkblue" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E');
  position: relative;
  top: 0.05rem;
  left: -0.5rem;
  height: 24px;
}

section.two-column-sticky {
  display: flex;
  flex-direction: row;
}
section.two-column-sticky div.left-side, section.two-column-sticky div.right-side {
  display: flex;
  width: 50%;
}
section.two-column-sticky div.left-side {
  height: 100vh;
  background: linear-gradient(180deg, #FFF5E6 0%, rgba(255, 245, 230, 0) 100%);
  padding: 7rem 3.5rem;
  justify-content: flex-end;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}
section.two-column-sticky div.left-side .text-left {
  padding-right: 0.5rem;
}
section.two-column-sticky div.left-side div.left-inner {
  width: 100%;
  max-width: 591px;
}
section.two-column-sticky:nth-child(even) div.left-side {
  background: linear-gradient(180deg, #F3F1FC 0%, rgba(243, 241, 252, 0) 100%);
}
section.two-column-sticky div.no-bg {
  background: transparent !important;
}
section.two-column-sticky div.right-side {
  padding: 7rem 3.5rem;
}
section.two-column-sticky div.right-side div.right-inner {
  width: 100%;
  max-width: 591px;
}
@media (max-width: 1199px) {
  section.two-column-sticky div.left-side {
    position: relative;
  }
}
@media (max-width: 1000px) {
  section.two-column-sticky {
    flex-direction: column;
  }
  section.two-column-sticky div.left-side, section.two-column-sticky div.right-side {
    width: 100%;
  }
  section.two-column-sticky div.left-side {
    padding: 3rem 8rem;
    height: auto;
  }
  section.two-column-sticky div.left-side div.left-inner {
    width: 100%;
    max-width: 100%;
  }
  section.two-column-sticky div.right-side {
    padding: 3rem 8rem;
    height: auto;
  }
  section.two-column-sticky div.right-side div.right-inner {
    width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  section.two-column-sticky div.left-side {
    padding: 3rem 0.75rem;
    height: auto;
  }
  section.two-column-sticky div.right-side {
    padding: 3rem 0.75rem;
  }
}

.sub-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}
.sub-footer .sub-menu {
  line-height: 2;
}
.sub-footer .sub-footer-left {
  min-width: 120px;
  max-width: 160px;
}

#site-footer h4, #site-footer h5, #site-footer .h4, #site-footer .h5 {
  opacity: 0.5;
}
#site-footer .container-fluid, #site-footer .container-xxl, #site-footer .container-xl, #site-footer .container-lg, #site-footer .container-md, #site-footer .container-sm, #site-footer .wp-block[data-align=full] {
  max-width: 2000px;
}

body.admin-bar header#navigation {
  top: 32px;
}
@media (max-width: 767.98px) {
  body.admin-bar header#navigation {
    top: 46px;
  }
}
@media (max-width: 991.98px) {
  body.admin-bar #wpadminbar {
    position: fixed;
  }
}
body.admin-bar .mm-ocd__content {
  padding-top: 46px;
}

header#navigation {
  color: #2A2069;
}
header#navigation .navigation-left-box, header#navigation .navigation-right-box {
  background-color: rgba(255, 255, 255, 0.65);
  border-radius: 20px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
}
header#navigation .navigation-left-box {
  padding-right: 0.5rem;
}
header#navigation .navigation-right-box {
  padding-left: 0.5rem;
}
header#navigation.scrolling .navigation-left-box, header#navigation.scrolling .navigation-right-box {
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
header#navigation .navbar-brand {
  color: #2A2069;
  border-radius: 20px 0 0 20px;
  margin-right: 0 !important;
}
header#navigation .navbar-brand svg {
  fill: #2A2069;
  stroke: none;
  margin-top: 3px;
}
header#navigation .navbar {
  position: relative;
  height: 60px;
}
@media (max-width: 767.98px) {
  header#navigation .navbar {
    height: auto;
  }
}
header#navigation .navbar .navigation-left-box:has(:hover), header#navigation .navbar .navigation-right-box:has(:hover) {
  background-color: white !important;
}
header#navigation .navbar .navbar-nav-left {
  border-radius: 0 20px 20px 0;
}
header#navigation .navbar .nav-item .nav-link {
  color: #2A2069;
  font-weight: bold;
  text-decoration: none;
  position: relative;
  margin: 0px 0.2rem;
  padding: 10px;
}
header#navigation .navbar .nav-item .nav-link .caret {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
header#navigation .navbar .nav-item .nav-link .caret svg {
  position: relative;
  height: 0.5rem;
  width: auto;
}
header#navigation .navbar .nav-item.dropdown {
  position: unset;
}
header#navigation .navbar .nav-item.dropdown .nav-link {
  padding-right: 2rem;
}
header#navigation .navbar .nav-item .dropdown-wrapper {
  width: 100%;
  perspective: 2000px;
  visibility: hidden;
  display: block !important;
}
header#navigation .navbar .nav-item .dropdown-wrapper .dropdown-sections {
  opacity: 0;
  will-change: transform;
}
header#navigation .navbar .nav-item .dropdown-wrapper .dropdown-sections .dropdown-section .menu-item:hover .text h5, header#navigation .navbar .nav-item .dropdown-wrapper .dropdown-sections .dropdown-section .menu-item:hover .text .h5 {
  color: #5644d8;
}
header#navigation .navbar .nav-item:hover .nav-item-inner {
  background-color: #E1DBF7;
  border-radius: 1rem;
}
header#navigation .navbar .nav-item:hover .dropdown-wrapper {
  visibility: visible;
}
header#navigation .navbar .nav-item:hover .dropdown-wrapper .container, header#navigation .navbar .nav-item:hover .dropdown-wrapper .single-customers main > article > .wp-block-group, .single-customers header#navigation .navbar .nav-item:hover .dropdown-wrapper main > article > .wp-block-group, header#navigation .navbar .nav-item:hover .dropdown-wrapper .alignwide,
header#navigation .navbar .nav-item:hover .dropdown-wrapper .wp-block-columns, header#navigation .navbar .nav-item:hover .dropdown-wrapper .wp-block.editor-post-title, header#navigation .navbar .nav-item:hover .dropdown-wrapper .wp-block[data-align=wide], header#navigation .navbar .nav-item:hover .dropdown-wrapper .single-podcasts main > article > .wp-block-group, .single-podcasts header#navigation .navbar .nav-item:hover .dropdown-wrapper main > article > .wp-block-group {
  padding-top: 1rem;
}
header#navigation .navbar .nav-item:hover .dropdown-wrapper .container .dropdown-inner, header#navigation .navbar .nav-item:hover .dropdown-wrapper .single-customers main > article > .wp-block-group .dropdown-inner, .single-customers header#navigation .navbar .nav-item:hover .dropdown-wrapper main > article > .wp-block-group .dropdown-inner, header#navigation .navbar .nav-item:hover .dropdown-wrapper .alignwide .dropdown-inner,
header#navigation .navbar .nav-item:hover .dropdown-wrapper .wp-block-columns .dropdown-inner, header#navigation .navbar .nav-item:hover .dropdown-wrapper .wp-block.editor-post-title .dropdown-inner, header#navigation .navbar .nav-item:hover .dropdown-wrapper .wp-block[data-align=wide] .dropdown-inner, header#navigation .navbar .nav-item:hover .dropdown-wrapper .single-podcasts main > article > .wp-block-group .dropdown-inner, .single-podcasts header#navigation .navbar .nav-item:hover .dropdown-wrapper main > article > .wp-block-group .dropdown-inner {
  border-radius: 20px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 0 2rem;
}
header#navigation .navbar .nav-item:hover .dropdown-wrapper .dropdown-sections {
  opacity: 1;
  transform: rotateX(0deg);
  transition: 250ms;
  transition-timing-function: ease;
  transition-property: transform, opacity, visibility;
  will-change: transform;
}
header#navigation .navbar .featured a {
  transition: all 0.3s ease-in-out;
}
header#navigation .navbar .featured a:hover {
  color: #2A2069 !important;
}
header#navigation .navbar .nav-group small.description, header#navigation .navbar .nav-group .description.small {
  font-size: 12px;
  color: rgb(121.4375, 121.4375, 113.0625);
}
header#navigation .navbar .nav-group__head {
  transition: all 0.3s ease-in-out;
}
header#navigation .navbar .nav-group__head:hover {
  background-color: #EFEBE7;
}
header#navigation .navbar .nav-group__name {
  letter-spacing: 2px;
  font-size: 12px;
  color: rgb(121.4375, 121.4375, 113.0625);
}
header#navigation .navbar .nav-group .menu-item a {
  transition: all 0.3s ease-in-out;
}
header#navigation .navbar .nav-group .menu-item a:hover h5, header#navigation .navbar .nav-group .menu-item a:hover .h5 {
  color: #2A2069 !important;
}
header#navigation .navbar .mobile-menu-opener a svg {
  height: 20px;
  width: auto;
}
header#navigation #mobile-menu {
  display: none;
}

.mm-ocd .mm-ocd__content::before {
  content: "";
  position: absolute;
  top: 13px;
  left: 50%;
  width: 58px;
  height: 25px;
  transform: translate(-50%, 0);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 100;
}
.mm-ocd .mm-ocd__content #mobile-menu {
  background-color: #ffffff;
}
.mm-ocd .mm-ocd__content #mobile-menu ul {
  top: 50px;
}
.mm-ocd .mm-ocd__content #mobile-menu ul li::after {
  margin-left: 0px;
}
.mm-ocd .mm-ocd__content #mobile-menu .menu-item span {
  font-weight: bold;
  padding: 0px;
}
.mm-ocd .mm-ocd__content #mobile-menu .dropdown-toggle::after {
  border-left: none;
}
.mm-ocd .mm-ocd__content #mobile-menu::after {
  top: 0px;
  opacity: 1;
  padding: 0px;
  font-size: 16px;
  font-weight: 700;
  color: #2A2069;
}
.mm-ocd .mm-ocd__content #mobile-menu a.btn {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: calc(100% - 30px) !important;
  z-index: 100;
}
.mm-ocd .mm-ocd__content #mobile-menu a.btn span {
  padding: 0px !important;
}
.mm-ocd .mm-ocd__content #mobile-menu a.btn:after {
  content: none;
}
.mm-ocd .mm-ocd__content #mobile-menu a.btn-book-demo {
  bottom: 20px;
}
.mm-ocd .mm-ocd__content #mobile-menu .logo svg {
  position: absolute;
  left: 50%;
  top: 0.6rem;
  transform: translate(-50%, 0);
  width: auto;
  height: 30px;
  z-index: 100;
}

.bg-off-white {
  background-color: #FBFAF9;
}

@media (max-width: 1199px) {
  nav.navbar {
    height: auto !important;
    padding: 0 0.75rem;
  }
  nav.navbar .container-fluid, nav.navbar .container-xxl, nav.navbar .container-xl, nav.navbar .container-lg, nav.navbar .container-md, nav.navbar .container-sm, nav.navbar .wp-block[data-align=full] {
    background-color: rgba(255, 255, 255, 0.95) !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 20px;
  }
  nav.navbar .size-hamburger {
    width: 2rem !important;
    height: 2rem !important;
  }
}
@media (max-width: 420px) {
  nav.navbar #button_book-a-demo {
    display: none !important;
  }
}
.mm-ocd--open .mm-ocd__content {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 8px;
}
.mm-ocd--open .mm-ocd__content .btn:hover:not(.btn-naked, .btn-), form .mm-ocd--open .mm-ocd__content input[type=submit]:hover:not(.btn-naked, .btn-), .mm-ocd--open .mm-ocd .mm-ocd__content #mobile-menu a.btn:hover:not(.btn-naked, .btn-), .mm-ocd .mm-ocd--open .mm-ocd__content #mobile-menu a.btn:hover:not(.btn-naked, .btn-), .mm-ocd--open .mm-ocd__content .mm-ocd .mm-ocd__content #mobile-menu a.btn:hover:not(.btn-naked, .btn-), .mm-ocd--open .mm-ocd__content form input[type=submit]:hover:not(.btn-naked, .btn-) {
  transform: translate(-50%) !important;
  width: calc(100% - 30px) !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(29, 29, 27, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h6, .h6, .block-hero__subtitle, .block-herocenter__subtitle, h5, .h5, h4, .h4, h3, .h3, .form-wrapper.inline .submitted-message, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: "Athletics", sans-serif;
  font-weight: 700;
  line-height: calc(1em + 0.5rem);
}

h1, .h1 {
  font-size: calc(1.525rem + 3.3vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 4rem;
  }
}

h2, .h2 {
  font-size: calc(1.425rem + 2.1vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 3rem;
  }
}

h3, .h3, .form-wrapper.inline .submitted-message {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h3, .h3, .form-wrapper.inline .submitted-message {
    font-size: 2rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.125rem;
}

h6, .h6, .block-hero__subtitle, .block-herocenter__subtitle {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: none;
}
a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  line-height: inherit;
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-black: #1d1d1b;
  --bs-white: #ffffff;
  --bs-border-grey: #566069;
  --bs-grey: #D6CCC2;
  --bs-light-grey: #EFEBE7;
  --bs-ultra-light-grey: white;
  --bs-midnight: #2A2069;
  --bs-purple: #5644d8;
  --bs-light-purple: #E1DBF7;
  --bs-ultra-light-purple: #F3F1FC;
  --bs-red: #F25F5C;
  --bs-pink: #F3C1C0;
  --bs-light-pink: #FAE6E6;
  --bs-amber: #FFB840;
  --bs-bisque: #FFE6C1;
  --bs-light-bisque: #FFF5E6;
  --bs-black-rgb: 29, 29, 27;
  --bs-white-rgb: 255, 255, 255;
  --bs-border-grey-rgb: 86, 96, 105;
  --bs-grey-rgb: 214, 204, 194;
  --bs-light-grey-rgb: 239, 235, 231;
  --bs-ultra-light-grey-rgb: 255, 255, 255;
  --bs-midnight-rgb: 42, 32, 105;
  --bs-purple-rgb: 86, 68, 216;
  --bs-light-purple-rgb: 225, 219, 247;
  --bs-ultra-light-purple-rgb: 243, 241, 252;
  --bs-red-rgb: 242, 95, 92;
  --bs-pink-rgb: 243, 193, 192;
  --bs-light-pink-rgb: 250, 230, 230;
  --bs-amber-rgb: 255, 184, 64;
  --bs-bisque-rgb: 255, 230, 193;
  --bs-light-bisque-rgb: 255, 245, 230;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 29, 29, 27;
  --bs-body-color-rgb: 29, 29, 27;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: Eina02, sans-serif;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #1d1d1b;
  --bs-body-bg: #ffffff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #2A2069;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 1rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #5644d8;
  --bs-link-hover-color: rgb(10.4, 88, 202.4);
  --bs-code-color: #d63384;
  --bs-highlight-bg: rgb(255, 242.6, 205.4);
}

.btn, form input[type=submit], .mm-ocd .mm-ocd__content #mobile-menu a.btn {
  --bs-btn-padding-x: 13px 20px;
  --bs-btn-padding-y: 14px 20px;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 700;
  --bs-btn-line-height: 1;
  --bs-btn-color: #1d1d1b;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 0px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 1rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(29, 29, 27, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn, form input[type=submit], .mm-ocd .mm-ocd__content #mobile-menu a.btn {
    transition: none;
  }
}
.btn:hover, form input[type=submit]:hover, .mm-ocd .mm-ocd__content #mobile-menu a.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover, form .btn-check + input[type=submit]:hover, .mm-ocd .mm-ocd__content #mobile-menu .btn-check + a.btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible, form input[type=submit]:focus-visible, .mm-ocd .mm-ocd__content #mobile-menu a.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn, form .btn-check:focus-visible + input[type=submit], .mm-ocd .mm-ocd__content #mobile-menu .btn-check:focus-visible + a.btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, form .btn-check:checked + input[type=submit], .mm-ocd .mm-ocd__content #mobile-menu .btn-check:checked + a.btn, :not(.btn-check) + .btn:active, form :not(.btn-check) + input[type=submit]:active, .mm-ocd .mm-ocd__content #mobile-menu :not(.btn-check) + a.btn:active, .btn:first-child:active, form input[type=submit]:first-child:active, .mm-ocd .mm-ocd__content #mobile-menu a.btn:first-child:active, .btn.active, form input.active[type=submit], .mm-ocd .mm-ocd__content #mobile-menu a.active.btn, .btn.show, form input.show[type=submit], .mm-ocd .mm-ocd__content #mobile-menu a.show.btn {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, form .btn-check:checked + input[type=submit]:focus-visible, .mm-ocd .mm-ocd__content #mobile-menu .btn-check:checked + a.btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, form :not(.btn-check) + input[type=submit]:active:focus-visible, .mm-ocd .mm-ocd__content #mobile-menu :not(.btn-check) + a.btn:active:focus-visible, .btn:first-child:active:focus-visible, form input[type=submit]:first-child:active:focus-visible, .mm-ocd .mm-ocd__content #mobile-menu a.btn:first-child:active:focus-visible, .btn.active:focus-visible, form input.active[type=submit]:focus-visible, .mm-ocd .mm-ocd__content #mobile-menu a.active.btn:focus-visible, .btn.show:focus-visible, form input.show[type=submit]:focus-visible, .mm-ocd .mm-ocd__content #mobile-menu a.show.btn:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, form input[type=submit]:disabled, .mm-ocd .mm-ocd__content #mobile-menu a.btn:disabled, .btn.disabled, form input.disabled[type=submit], .mm-ocd .mm-ocd__content #mobile-menu a.disabled.btn, fieldset:disabled .btn, fieldset:disabled form input[type=submit], form fieldset:disabled input[type=submit], fieldset:disabled .mm-ocd .mm-ocd__content #mobile-menu a.btn, .mm-ocd .mm-ocd__content #mobile-menu fieldset:disabled a.btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-black, .form-wrapper.submit-button-color-black input[type=submit] {
  --bs-btn-color: #fff;
  --bs-btn-bg: #1d1d1b;
  --bs-btn-border-color: #1d1d1b;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(24.65, 24.65, 22.95);
  --bs-btn-hover-border-color: rgb(23.2, 23.2, 21.6);
  --bs-btn-focus-shadow-rgb: 63, 63, 61;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(23.2, 23.2, 21.6);
  --bs-btn-active-border-color: rgb(21.75, 21.75, 20.25);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #1d1d1b;
  --bs-btn-disabled-border-color: #1d1d1b;
}

.btn-white, .form-wrapper.submit-button-color-white input[type=submit] {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffffff;
  --bs-btn-disabled-border-color: #ffffff;
}

.btn-border-grey, .form-wrapper.submit-button-color-border-grey input[type=submit] {
  --bs-btn-color: #fff;
  --bs-btn-bg: #566069;
  --bs-btn-border-color: #566069;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(73.1, 81.6, 89.25);
  --bs-btn-hover-border-color: rgb(68.8, 76.8, 84);
  --bs-btn-focus-shadow-rgb: 111, 120, 128;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(68.8, 76.8, 84);
  --bs-btn-active-border-color: rgb(64.5, 72, 78.75);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #566069;
  --bs-btn-disabled-border-color: #566069;
}

.btn-grey, .form-wrapper.submit-button-color-grey input[type=submit] {
  --bs-btn-color: #000;
  --bs-btn-bg: #D6CCC2;
  --bs-btn-border-color: #D6CCC2;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(220.15, 211.65, 203.15);
  --bs-btn-hover-border-color: rgb(218.1, 209.1, 200.1);
  --bs-btn-focus-shadow-rgb: 182, 173, 165;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgb(222.2, 214.2, 206.2);
  --bs-btn-active-border-color: rgb(218.1, 209.1, 200.1);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #D6CCC2;
  --bs-btn-disabled-border-color: #D6CCC2;
}

.btn-light-grey, .form-wrapper.submit-button-color-light-grey input[type=submit] {
  --bs-btn-color: #000;
  --bs-btn-bg: #EFEBE7;
  --bs-btn-border-color: #EFEBE7;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(241.4, 238, 234.6);
  --bs-btn-hover-border-color: rgb(240.6, 237, 233.4);
  --bs-btn-focus-shadow-rgb: 203, 200, 196;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgb(242.2, 239, 235.8);
  --bs-btn-active-border-color: rgb(240.6, 237, 233.4);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #EFEBE7;
  --bs-btn-disabled-border-color: #EFEBE7;
}

.btn-ultra-light-grey, .form-wrapper.submit-button-color-ultra-light-grey input[type=submit] {
  --bs-btn-color: #000;
  --bs-btn-bg: white;
  --bs-btn-border-color: white;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: white;
  --bs-btn-disabled-border-color: white;
}

.btn-midnight, .form-wrapper.submit-button-color-midnight input[type=submit] {
  --bs-btn-color: #fff;
  --bs-btn-bg: #2A2069;
  --bs-btn-border-color: #2A2069;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(35.7, 27.2, 89.25);
  --bs-btn-hover-border-color: rgb(33.6, 25.6, 84);
  --bs-btn-focus-shadow-rgb: 74, 65, 128;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(33.6, 25.6, 84);
  --bs-btn-active-border-color: rgb(31.5, 24, 78.75);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #2A2069;
  --bs-btn-disabled-border-color: #2A2069;
}

.btn-purple, .form-wrapper.submit-button-color-purple input[type=submit], form input[type=submit], .mm-ocd .mm-ocd__content #mobile-menu a.btn-book-demo {
  --bs-btn-color: #fff;
  --bs-btn-bg: #5644d8;
  --bs-btn-border-color: #5644d8;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(73.1, 57.8, 183.6);
  --bs-btn-hover-border-color: rgb(68.8, 54.4, 172.8);
  --bs-btn-focus-shadow-rgb: 111, 96, 222;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(68.8, 54.4, 172.8);
  --bs-btn-active-border-color: rgb(64.5, 51, 162);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #5644d8;
  --bs-btn-disabled-border-color: #5644d8;
}

.btn-light-purple, .form-wrapper.submit-button-color-light-purple input[type=submit] {
  --bs-btn-color: #000;
  --bs-btn-bg: #E1DBF7;
  --bs-btn-border-color: #E1DBF7;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(229.5, 224.4, 248.2);
  --bs-btn-hover-border-color: rgb(228, 222.6, 247.8);
  --bs-btn-focus-shadow-rgb: 191, 186, 210;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgb(231, 226.2, 248.6);
  --bs-btn-active-border-color: rgb(228, 222.6, 247.8);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #E1DBF7;
  --bs-btn-disabled-border-color: #E1DBF7;
}

.btn-ultra-light-purple, .form-wrapper.submit-button-color-ultra-light-purple input[type=submit] {
  --bs-btn-color: #000;
  --bs-btn-bg: #F3F1FC;
  --bs-btn-border-color: #F3F1FC;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(244.8, 243.1, 252.45);
  --bs-btn-hover-border-color: rgb(244.2, 242.4, 252.3);
  --bs-btn-focus-shadow-rgb: 207, 205, 214;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgb(245.4, 243.8, 252.6);
  --bs-btn-active-border-color: rgb(244.2, 242.4, 252.3);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #F3F1FC;
  --bs-btn-disabled-border-color: #F3F1FC;
}

.btn-red, .form-wrapper.submit-button-color-red input[type=submit] {
  --bs-btn-color: #000;
  --bs-btn-bg: #F25F5C;
  --bs-btn-border-color: #F25F5C;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(243.95, 119, 116.45);
  --bs-btn-hover-border-color: rgb(243.3, 111, 108.3);
  --bs-btn-focus-shadow-rgb: 206, 81, 78;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgb(244.6, 127, 124.6);
  --bs-btn-active-border-color: rgb(243.3, 111, 108.3);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #F25F5C;
  --bs-btn-disabled-border-color: #F25F5C;
}

.btn-pink, .form-wrapper.submit-button-color-pink input[type=submit] {
  --bs-btn-color: #000;
  --bs-btn-bg: #F3C1C0;
  --bs-btn-border-color: #F3C1C0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(244.8, 202.3, 201.45);
  --bs-btn-hover-border-color: rgb(244.2, 199.2, 198.3);
  --bs-btn-focus-shadow-rgb: 207, 164, 163;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgb(245.4, 205.4, 204.6);
  --bs-btn-active-border-color: rgb(244.2, 199.2, 198.3);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #F3C1C0;
  --bs-btn-disabled-border-color: #F3C1C0;
}

.btn-light-pink, .form-wrapper.submit-button-color-light-pink input[type=submit] {
  --bs-btn-color: #000;
  --bs-btn-bg: #FAE6E6;
  --bs-btn-border-color: #FAE6E6;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(250.75, 233.75, 233.75);
  --bs-btn-hover-border-color: rgb(250.5, 232.5, 232.5);
  --bs-btn-focus-shadow-rgb: 213, 196, 196;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fbebeb;
  --bs-btn-active-border-color: rgb(250.5, 232.5, 232.5);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #FAE6E6;
  --bs-btn-disabled-border-color: #FAE6E6;
}

.btn-amber, .form-wrapper.submit-button-color-amber input[type=submit] {
  --bs-btn-color: #000;
  --bs-btn-bg: #FFB840;
  --bs-btn-border-color: #FFB840;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(255, 194.65, 92.65);
  --bs-btn-hover-border-color: rgb(255, 191.1, 83.1);
  --bs-btn-focus-shadow-rgb: 217, 156, 54;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgb(255, 198.2, 102.2);
  --bs-btn-active-border-color: rgb(255, 191.1, 83.1);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #FFB840;
  --bs-btn-disabled-border-color: #FFB840;
}

.btn-bisque, .form-wrapper.submit-button-color-bisque input[type=submit] {
  --bs-btn-color: #000;
  --bs-btn-bg: #FFE6C1;
  --bs-btn-border-color: #FFE6C1;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(255, 233.75, 202.3);
  --bs-btn-hover-border-color: rgb(255, 232.5, 199.2);
  --bs-btn-focus-shadow-rgb: 217, 196, 164;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgb(255, 235, 205.4);
  --bs-btn-active-border-color: rgb(255, 232.5, 199.2);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #FFE6C1;
  --bs-btn-disabled-border-color: #FFE6C1;
}

.btn-light-bisque, .form-wrapper.submit-button-color-light-bisque input[type=submit] {
  --bs-btn-color: #000;
  --bs-btn-bg: #FFF5E6;
  --bs-btn-border-color: #FFF5E6;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(255, 246.5, 233.75);
  --bs-btn-hover-border-color: rgb(255, 246, 232.5);
  --bs-btn-focus-shadow-rgb: 217, 208, 196;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fff7eb;
  --bs-btn-active-border-color: rgb(255, 246, 232.5);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #FFF5E6;
  --bs-btn-disabled-border-color: #FFF5E6;
}

.btn-outline-black {
  --bs-btn-color: #1d1d1b;
  --bs-btn-border-color: #1d1d1b;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1d1d1b;
  --bs-btn-hover-border-color: #1d1d1b;
  --bs-btn-focus-shadow-rgb: 29, 29, 27;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1d1d1b;
  --bs-btn-active-border-color: #1d1d1b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1d1d1b;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1d1d1b;
  --bs-gradient: none;
}

.btn-outline-white {
  --bs-btn-color: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffffff;
  --bs-btn-hover-border-color: #ffffff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffffff;
  --bs-btn-active-border-color: #ffffff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffffff;
  --bs-gradient: none;
}

.btn-outline-border-grey {
  --bs-btn-color: #566069;
  --bs-btn-border-color: #566069;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #566069;
  --bs-btn-hover-border-color: #566069;
  --bs-btn-focus-shadow-rgb: 86, 96, 105;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #566069;
  --bs-btn-active-border-color: #566069;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #566069;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #566069;
  --bs-gradient: none;
}

.btn-outline-grey {
  --bs-btn-color: #D6CCC2;
  --bs-btn-border-color: #D6CCC2;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #D6CCC2;
  --bs-btn-hover-border-color: #D6CCC2;
  --bs-btn-focus-shadow-rgb: 214, 204, 194;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #D6CCC2;
  --bs-btn-active-border-color: #D6CCC2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #D6CCC2;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #D6CCC2;
  --bs-gradient: none;
}

.btn-outline-light-grey {
  --bs-btn-color: #EFEBE7;
  --bs-btn-border-color: #EFEBE7;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #EFEBE7;
  --bs-btn-hover-border-color: #EFEBE7;
  --bs-btn-focus-shadow-rgb: 239, 235, 231;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #EFEBE7;
  --bs-btn-active-border-color: #EFEBE7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #EFEBE7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #EFEBE7;
  --bs-gradient: none;
}

.btn-outline-ultra-light-grey {
  --bs-btn-color: white;
  --bs-btn-border-color: white;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: white;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: white;
  --bs-gradient: none;
}

.btn-outline-midnight {
  --bs-btn-color: #2A2069;
  --bs-btn-border-color: #2A2069;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2A2069;
  --bs-btn-hover-border-color: #2A2069;
  --bs-btn-focus-shadow-rgb: 42, 32, 105;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2A2069;
  --bs-btn-active-border-color: #2A2069;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2A2069;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2A2069;
  --bs-gradient: none;
}

.btn-outline-purple {
  --bs-btn-color: #5644d8;
  --bs-btn-border-color: #5644d8;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5644d8;
  --bs-btn-hover-border-color: #5644d8;
  --bs-btn-focus-shadow-rgb: 86, 68, 216;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5644d8;
  --bs-btn-active-border-color: #5644d8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #5644d8;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #5644d8;
  --bs-gradient: none;
}

.btn-outline-light-purple {
  --bs-btn-color: #E1DBF7;
  --bs-btn-border-color: #E1DBF7;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #E1DBF7;
  --bs-btn-hover-border-color: #E1DBF7;
  --bs-btn-focus-shadow-rgb: 225, 219, 247;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #E1DBF7;
  --bs-btn-active-border-color: #E1DBF7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #E1DBF7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #E1DBF7;
  --bs-gradient: none;
}

.btn-outline-ultra-light-purple {
  --bs-btn-color: #F3F1FC;
  --bs-btn-border-color: #F3F1FC;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #F3F1FC;
  --bs-btn-hover-border-color: #F3F1FC;
  --bs-btn-focus-shadow-rgb: 243, 241, 252;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #F3F1FC;
  --bs-btn-active-border-color: #F3F1FC;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #F3F1FC;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #F3F1FC;
  --bs-gradient: none;
}

.btn-outline-red {
  --bs-btn-color: #F25F5C;
  --bs-btn-border-color: #F25F5C;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #F25F5C;
  --bs-btn-hover-border-color: #F25F5C;
  --bs-btn-focus-shadow-rgb: 242, 95, 92;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #F25F5C;
  --bs-btn-active-border-color: #F25F5C;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #F25F5C;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #F25F5C;
  --bs-gradient: none;
}

.btn-outline-pink {
  --bs-btn-color: #F3C1C0;
  --bs-btn-border-color: #F3C1C0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #F3C1C0;
  --bs-btn-hover-border-color: #F3C1C0;
  --bs-btn-focus-shadow-rgb: 243, 193, 192;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #F3C1C0;
  --bs-btn-active-border-color: #F3C1C0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #F3C1C0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #F3C1C0;
  --bs-gradient: none;
}

.btn-outline-light-pink {
  --bs-btn-color: #FAE6E6;
  --bs-btn-border-color: #FAE6E6;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #FAE6E6;
  --bs-btn-hover-border-color: #FAE6E6;
  --bs-btn-focus-shadow-rgb: 250, 230, 230;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #FAE6E6;
  --bs-btn-active-border-color: #FAE6E6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FAE6E6;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FAE6E6;
  --bs-gradient: none;
}

.btn-outline-amber {
  --bs-btn-color: #FFB840;
  --bs-btn-border-color: #FFB840;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #FFB840;
  --bs-btn-hover-border-color: #FFB840;
  --bs-btn-focus-shadow-rgb: 255, 184, 64;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #FFB840;
  --bs-btn-active-border-color: #FFB840;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFB840;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FFB840;
  --bs-gradient: none;
}

.btn-outline-bisque {
  --bs-btn-color: #FFE6C1;
  --bs-btn-border-color: #FFE6C1;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #FFE6C1;
  --bs-btn-hover-border-color: #FFE6C1;
  --bs-btn-focus-shadow-rgb: 255, 230, 193;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #FFE6C1;
  --bs-btn-active-border-color: #FFE6C1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFE6C1;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FFE6C1;
  --bs-gradient: none;
}

.btn-outline-light-bisque {
  --bs-btn-color: #FFF5E6;
  --bs-btn-border-color: #FFF5E6;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #FFF5E6;
  --bs-btn-hover-border-color: #FFF5E6;
  --bs-btn-focus-shadow-rgb: 255, 245, 230;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #FFF5E6;
  --bs-btn-active-border-color: #FFF5E6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFF5E6;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FFF5E6;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: #1d1d1b;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #1d1d1b;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: #1d1d1b;
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 111, 96, 222;
  text-decoration: none;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg {
  --bs-btn-padding-y: 17px 24px;
  --bs-btn-padding-x: 17px 24px;
  --bs-btn-font-size: 1.125rem;
  --bs-btn-border-radius: 1rem;
}

.btn-sm {
  --bs-btn-padding-y: 11px 1rem;
  --bs-btn-padding-x: 11px 1rem 10px;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 1rem;
}

.container, .single-customers main > article > .wp-block-group, .alignwide,
.wp-block-columns, .wp-block.editor-post-title, .wp-block[data-align=wide], .single-podcasts main > article > .wp-block-group,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.wp-block[data-align=full] {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container, .single-customers main > article > .wp-block-group, .alignwide,
  .wp-block-columns, .wp-block.editor-post-title, .wp-block[data-align=wide], .single-podcasts main > article > .wp-block-group {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container, .single-customers main > article > .wp-block-group, .alignwide,
  .wp-block-columns, .wp-block.editor-post-title, .wp-block[data-align=wide], .single-podcasts main > article > .wp-block-group {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container, .single-customers main > article > .wp-block-group, .alignwide,
  .wp-block-columns, .wp-block.editor-post-title, .wp-block[data-align=wide], .single-podcasts main > article > .wp-block-group {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container, .single-customers main > article > .wp-block-group, .alignwide,
  .wp-block-columns, .wp-block.editor-post-title, .wp-block[data-align=wide], .single-podcasts main > article > .wp-block-group {
    max-width: 1140px;
  }
}
@media (min-width: 1500px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container, .single-customers main > article > .wp-block-group, .alignwide,
  .wp-block-columns, .wp-block.editor-post-title, .wp-block[data-align=wide], .single-podcasts main > article > .wp-block-group {
    max-width: 1320px;
  }
}
.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 0.375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.375rem - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10, .single-customers main > article > .wp-block-group > *, .single-podcasts main > article > .wp-block-group > * {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1, .single-customers main > article > .wp-block-group > *, .single-podcasts main > article > .wp-block-group > * {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1500px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-black {
  color: #fff !important;
  background-color: RGBA(29, 29, 27, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-white {
  color: #000 !important;
  background-color: RGBA(255, 255, 255, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-border-grey {
  color: #fff !important;
  background-color: RGBA(86, 96, 105, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-grey {
  color: #000 !important;
  background-color: RGBA(214, 204, 194, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light-grey {
  color: #000 !important;
  background-color: RGBA(239, 235, 231, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-ultra-light-grey {
  color: #000 !important;
  background-color: RGBA(255, 255, 255, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-midnight {
  color: #fff !important;
  background-color: RGBA(42, 32, 105, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-purple {
  color: #fff !important;
  background-color: RGBA(86, 68, 216, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light-purple {
  color: #000 !important;
  background-color: RGBA(225, 219, 247, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-ultra-light-purple {
  color: #000 !important;
  background-color: RGBA(243, 241, 252, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-red {
  color: #000 !important;
  background-color: RGBA(242, 95, 92, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-pink {
  color: #000 !important;
  background-color: RGBA(243, 193, 192, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light-pink {
  color: #000 !important;
  background-color: RGBA(250, 230, 230, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-amber {
  color: #000 !important;
  background-color: RGBA(255, 184, 64, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-bisque {
  color: #000 !important;
  background-color: RGBA(255, 230, 193, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light-bisque {
  color: #000 !important;
  background-color: RGBA(255, 245, 230, var(--bs-bg-opacity, 1)) !important;
}

.link-black {
  color: #1d1d1b !important;
}
.link-black:hover, .link-black:focus {
  color: rgb(23.2, 23.2, 21.6) !important;
}

.link-white {
  color: #ffffff !important;
}
.link-white:hover, .link-white:focus {
  color: white !important;
}

.link-border-grey {
  color: #566069 !important;
}
.link-border-grey:hover, .link-border-grey:focus {
  color: rgb(68.8, 76.8, 84) !important;
}

.link-grey {
  color: #D6CCC2 !important;
}
.link-grey:hover, .link-grey:focus {
  color: rgb(222.2, 214.2, 206.2) !important;
}

.link-light-grey {
  color: #EFEBE7 !important;
}
.link-light-grey:hover, .link-light-grey:focus {
  color: rgb(242.2, 239, 235.8) !important;
}

.link-ultra-light-grey {
  color: white !important;
}
.link-ultra-light-grey:hover, .link-ultra-light-grey:focus {
  color: white !important;
}

.link-midnight {
  color: #2A2069 !important;
}
.link-midnight:hover, .link-midnight:focus {
  color: rgb(33.6, 25.6, 84) !important;
}

.link-purple {
  color: #5644d8 !important;
}
.link-purple:hover, .link-purple:focus {
  color: rgb(68.8, 54.4, 172.8) !important;
}

.link-light-purple {
  color: #E1DBF7 !important;
}
.link-light-purple:hover, .link-light-purple:focus {
  color: rgb(231, 226.2, 248.6) !important;
}

.link-ultra-light-purple {
  color: #F3F1FC !important;
}
.link-ultra-light-purple:hover, .link-ultra-light-purple:focus {
  color: rgb(245.4, 243.8, 252.6) !important;
}

.link-red {
  color: #F25F5C !important;
}
.link-red:hover, .link-red:focus {
  color: rgb(244.6, 127, 124.6) !important;
}

.link-pink {
  color: #F3C1C0 !important;
}
.link-pink:hover, .link-pink:focus {
  color: rgb(245.4, 205.4, 204.6) !important;
}

.link-light-pink {
  color: #FAE6E6 !important;
}
.link-light-pink:hover, .link-light-pink:focus {
  color: #fbebeb !important;
}

.link-amber {
  color: #FFB840 !important;
}
.link-amber:hover, .link-amber:focus {
  color: rgb(255, 198.2, 102.2) !important;
}

.link-bisque {
  color: #FFE6C1 !important;
}
.link-bisque:hover, .link-bisque:focus {
  color: rgb(255, 235, 205.4) !important;
}

.link-light-bisque {
  color: #FFF5E6 !important;
}
.link-light-bisque:hover, .link-light-bisque:focus {
  color: #fff7eb !important;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1500px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 1.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 0px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: 0.375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item .page-link {
  border-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: 0.5rem;
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: 0.25rem;
}

.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1500px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-black {
  color: rgb(17.4, 17.4, 16.2);
  background-color: rgb(209.8, 209.8, 209.4);
}
.list-group-item-black.list-group-item-action:hover, .list-group-item-black.list-group-item-action:focus {
  color: rgb(17.4, 17.4, 16.2);
  background-color: rgb(188.82, 188.82, 188.46);
}
.list-group-item-black.list-group-item-action.active {
  color: #ffffff;
  background-color: rgb(17.4, 17.4, 16.2);
  border-color: rgb(17.4, 17.4, 16.2);
}

.list-group-item-white {
  color: #666666;
  background-color: white;
}
.list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
  color: #666666;
  background-color: rgb(229.5, 229.5, 229.5);
}
.list-group-item-white.list-group-item-action.active {
  color: #ffffff;
  background-color: #666666;
  border-color: #666666;
}

.list-group-item-border-grey {
  color: rgb(51.6, 57.6, 63);
  background-color: rgb(221.2, 223.2, 225);
}
.list-group-item-border-grey.list-group-item-action:hover, .list-group-item-border-grey.list-group-item-action:focus {
  color: rgb(51.6, 57.6, 63);
  background-color: rgb(199.08, 200.88, 202.5);
}
.list-group-item-border-grey.list-group-item-action.active {
  color: #ffffff;
  background-color: rgb(51.6, 57.6, 63);
  border-color: rgb(51.6, 57.6, 63);
}

.list-group-item-grey {
  color: rgb(85.6, 81.6, 77.6);
  background-color: rgb(246.8, 244.8, 242.8);
}
.list-group-item-grey.list-group-item-action:hover, .list-group-item-grey.list-group-item-action:focus {
  color: rgb(85.6, 81.6, 77.6);
  background-color: rgb(222.12, 220.32, 218.52);
}
.list-group-item-grey.list-group-item-action.active {
  color: #ffffff;
  background-color: rgb(85.6, 81.6, 77.6);
  border-color: rgb(85.6, 81.6, 77.6);
}

.list-group-item-light-grey {
  color: rgb(95.6, 94, 92.4);
  background-color: rgb(251.8, 251, 250.2);
}
.list-group-item-light-grey.list-group-item-action:hover, .list-group-item-light-grey.list-group-item-action:focus {
  color: rgb(95.6, 94, 92.4);
  background-color: rgb(226.62, 225.9, 225.18);
}
.list-group-item-light-grey.list-group-item-action.active {
  color: #ffffff;
  background-color: rgb(95.6, 94, 92.4);
  border-color: rgb(95.6, 94, 92.4);
}

.list-group-item-ultra-light-grey {
  color: #666666;
  background-color: white;
}
.list-group-item-ultra-light-grey.list-group-item-action:hover, .list-group-item-ultra-light-grey.list-group-item-action:focus {
  color: #666666;
  background-color: rgb(229.5, 229.5, 229.5);
}
.list-group-item-ultra-light-grey.list-group-item-action.active {
  color: #ffffff;
  background-color: #666666;
  border-color: #666666;
}

.list-group-item-midnight {
  color: rgb(25.2, 19.2, 63);
  background-color: rgb(212.4, 210.4, 225);
}
.list-group-item-midnight.list-group-item-action:hover, .list-group-item-midnight.list-group-item-action:focus {
  color: rgb(25.2, 19.2, 63);
  background-color: rgb(191.16, 189.36, 202.5);
}
.list-group-item-midnight.list-group-item-action.active {
  color: #ffffff;
  background-color: rgb(25.2, 19.2, 63);
  border-color: rgb(25.2, 19.2, 63);
}

.list-group-item-purple {
  color: rgb(51.6, 40.8, 129.6);
  background-color: rgb(221.2, 217.6, 247.2);
}
.list-group-item-purple.list-group-item-action:hover, .list-group-item-purple.list-group-item-action:focus {
  color: rgb(51.6, 40.8, 129.6);
  background-color: rgb(199.08, 195.84, 222.48);
}
.list-group-item-purple.list-group-item-action.active {
  color: #ffffff;
  background-color: rgb(51.6, 40.8, 129.6);
  border-color: rgb(51.6, 40.8, 129.6);
}

.list-group-item-light-purple {
  color: rgb(90, 87.6, 98.8);
  background-color: rgb(249, 247.8, 253.4);
}
.list-group-item-light-purple.list-group-item-action:hover, .list-group-item-light-purple.list-group-item-action:focus {
  color: rgb(90, 87.6, 98.8);
  background-color: rgb(224.1, 223.02, 228.06);
}
.list-group-item-light-purple.list-group-item-action.active {
  color: #ffffff;
  background-color: rgb(90, 87.6, 98.8);
  border-color: rgb(90, 87.6, 98.8);
}

.list-group-item-ultra-light-purple {
  color: rgb(97.2, 96.4, 100.8);
  background-color: rgb(252.6, 252.2, 254.4);
}
.list-group-item-ultra-light-purple.list-group-item-action:hover, .list-group-item-ultra-light-purple.list-group-item-action:focus {
  color: rgb(97.2, 96.4, 100.8);
  background-color: rgb(227.34, 226.98, 228.96);
}
.list-group-item-ultra-light-purple.list-group-item-action.active {
  color: #ffffff;
  background-color: rgb(97.2, 96.4, 100.8);
  border-color: rgb(97.2, 96.4, 100.8);
}

.list-group-item-red {
  color: rgb(145.2, 57, 55.2);
  background-color: rgb(252.4, 223, 222.4);
}
.list-group-item-red.list-group-item-action:hover, .list-group-item-red.list-group-item-action:focus {
  color: rgb(145.2, 57, 55.2);
  background-color: rgb(227.16, 200.7, 200.16);
}
.list-group-item-red.list-group-item-action.active {
  color: #ffffff;
  background-color: rgb(145.2, 57, 55.2);
  border-color: rgb(145.2, 57, 55.2);
}

.list-group-item-pink {
  color: rgb(97.2, 77.2, 76.8);
  background-color: rgb(252.6, 242.6, 242.4);
}
.list-group-item-pink.list-group-item-action:hover, .list-group-item-pink.list-group-item-action:focus {
  color: rgb(97.2, 77.2, 76.8);
  background-color: rgb(227.34, 218.34, 218.16);
}
.list-group-item-pink.list-group-item-action.active {
  color: #ffffff;
  background-color: rgb(97.2, 77.2, 76.8);
  border-color: rgb(97.2, 77.2, 76.8);
}

.list-group-item-light-pink {
  color: #645c5c;
  background-color: #fefafa;
}
.list-group-item-light-pink.list-group-item-action:hover, .list-group-item-light-pink.list-group-item-action:focus {
  color: #645c5c;
  background-color: rgb(228.6, 225, 225);
}
.list-group-item-light-pink.list-group-item-action.active {
  color: #ffffff;
  background-color: #645c5c;
  border-color: #645c5c;
}

.list-group-item-amber {
  color: rgb(102, 73.6, 25.6);
  background-color: rgb(255, 240.8, 216.8);
}
.list-group-item-amber.list-group-item-action:hover, .list-group-item-amber.list-group-item-action:focus {
  color: rgb(102, 73.6, 25.6);
  background-color: rgb(229.5, 216.72, 195.12);
}
.list-group-item-amber.list-group-item-action.active {
  color: #ffffff;
  background-color: rgb(102, 73.6, 25.6);
  border-color: rgb(102, 73.6, 25.6);
}

.list-group-item-bisque {
  color: rgb(102, 92, 77.2);
  background-color: rgb(255, 250, 242.6);
}
.list-group-item-bisque.list-group-item-action:hover, .list-group-item-bisque.list-group-item-action:focus {
  color: rgb(102, 92, 77.2);
  background-color: rgb(229.5, 225, 218.34);
}
.list-group-item-bisque.list-group-item-action.active {
  color: #ffffff;
  background-color: rgb(102, 92, 77.2);
  border-color: rgb(102, 92, 77.2);
}

.list-group-item-light-bisque {
  color: #66625c;
  background-color: #fffdfa;
}
.list-group-item-light-bisque.list-group-item-action:hover, .list-group-item-light-bisque.list-group-item-action:focus {
  color: #66625c;
  background-color: rgb(229.5, 227.7, 225);
}
.list-group-item-light-bisque.list-group-item-action.active {
  color: #ffffff;
  background-color: #66625c;
  border-color: #66625c;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: rgb(206.6, 226, 254.6);
  --bs-table-border-color: rgb(185.94, 203.4, 229.14);
  --bs-table-striped-bg: rgb(196.27, 214.7, 241.87);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(185.94, 203.4, 229.14);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(191.105, 209.05, 235.505);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: rgb(225.6, 227.4, 229);
  --bs-table-border-color: rgb(203.04, 204.66, 206.1);
  --bs-table-striped-bg: rgb(214.32, 216.03, 217.55);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(203.04, 204.66, 206.1);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(208.68, 210.345, 211.825);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: rgb(209, 231, 220.8);
  --bs-table-border-color: rgb(188.1, 207.9, 198.72);
  --bs-table-striped-bg: rgb(198.55, 219.45, 209.76);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(188.1, 207.9, 198.72);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(193.325, 213.675, 204.24);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: rgb(206.6, 244.4, 252);
  --bs-table-border-color: rgb(185.94, 219.96, 226.8);
  --bs-table-striped-bg: rgb(196.27, 232.18, 239.4);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(185.94, 219.96, 226.8);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(191.105, 226.07, 233.1);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: rgb(255, 242.6, 205.4);
  --bs-table-border-color: rgb(229.5, 218.34, 184.86);
  --bs-table-striped-bg: rgb(242.25, 230.47, 195.13);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(229.5, 218.34, 184.86);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(235.875, 224.405, 189.995);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: rgb(248, 214.6, 217.8);
  --bs-table-border-color: rgb(223.2, 193.14, 196.02);
  --bs-table-striped-bg: rgb(235.6, 203.87, 206.91);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(223.2, 193.14, 196.02);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(229.4, 198.505, 201.465);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: rgb(223.2, 224.1, 225);
  --bs-table-striped-bg: rgb(235.6, 236.55, 237.5);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(223.2, 224.1, 225);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(229.4, 230.325, 231.25);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: rgb(55.2, 58.8, 62.4);
  --bs-table-striped-bg: rgb(44.1, 47.9, 51.7);
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: rgb(55.2, 58.8, 62.4);
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: rgb(49.65, 53.35, 57.05);
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1499.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1499.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0px;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.375rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container, .single-customers main > article.navbar > .wp-block-group, .navbar > .alignwide,
.navbar > .wp-block-columns, .navbar > .wp-block.editor-post-title, .navbar > .wp-block[data-align=wide], .single-podcasts main > article.navbar > .wp-block-group,
.navbar > .container-fluid,
.navbar > .wp-block[data-align=full],
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 1.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1500px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: rgb(29.7, 33.3, 36.9);
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1500px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.badge {
  --bs-badge-padding-x: 1rem;
  --bs-badge-padding-y: 0.375rem;
  --bs-badge-font-size: 1rem;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #ffffff;
  --bs-badge-border-radius: 0.5rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge, form input[type=submit] .badge, .mm-ocd .mm-ocd__content #mobile-menu a.btn .badge {
  position: relative;
  top: -1px;
}

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 300px;
  --bs-tooltip-padding-x: 0.75rem;
  --bs-tooltip-padding-y: 0.75rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #2A2069;
  --bs-tooltip-border-radius: 0.5rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: "Eina02", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}