.post-slider {
    overflow: hidden;

    .glide {
        &__slide {
            align-self: stretch;
            height: auto;
        }
    }

}