.block-logorow {
    .glide {
        opacity: 0;
        transition: opacity 500ms ease;
        
        &.glide--swipeable {
            opacity: 1;
        }

        .logo-elem {
            max-height: 120px;
        }
    }
}