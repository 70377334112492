.demo-right-bg {
    background: linear-gradient(180deg, #FFB840 0%, #FFE6C1 100%);
    min-height: 100vh;
    position: relative;

    @include media-breakpoint-down(md) { 
        min-height: auto;
        padding-bottom: 3rem;
    }

    .demo-right-container {
        max-width: 40rem;
        margin: auto;

        blockquote {
            margin-top: 4rem;
            position: relative;
            padding: 0;
            border: none;
            color: $midnight;

            svg {
                display: block;
                width: 40px;
                margin-bottom: 1rem;
            }
        }

        span {
            color: $midnight;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.5rem;
            letter-spacing: 0.01rem;

            .demo-quote-rating {
                height: 1rem;
                width: auto;
                margin-top: -0.25rem;
            }
        }

        .demo-g2-badges {
            .demo-g2-badge-item {
                float: left;
                width: 25%;
                padding: 1rem;

                svg {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}

.demo-left-container {
    max-width: 32rem;
    margin: auto;

    .demo-brand {
        margin: auto;
        display: block;
        width: 78px;
    }

    .demo-avatar-stack {
        float: none;
        display: block;
        margin: auto;
        overflow: hidden;
        width: 7.5rem;
        height: 2.75rem;
        position: relative;

        picture {
            position: absolute;
            width: 2.75rem;
            height: 2.75rem;
        }

        picture:nth-child(1) {
            left: 0;
        }

        picture:nth-child(2) {
            left: 2.40rem;
        }

        picture:nth-child(3) {
            left: 4.80rem;
        }

        .demo-avatar {
            border-radius: 50%;
            border: 2px solid white;
            width: 2.75rem;
            height: 2.75rem;
            float: left;
        }
    }

    .demo-customer-logos {
        .demo-customer-logo-item {
            width: 33.3333%;
            float: left;

            svg {
                width: 100%;
                height: auto;
            }
        }
    }

    .submitted-message {
        background: linear-gradient(200deg, rgba(242,95,92,1) 0%, rgba(86,68,216,1) 100%);
        padding: 3rem;
        border-radius: 1rem;
        color: white;
    
        p {
            color: $white!important;
            font-size: 1rem;
            line-height: 2rem;
            text-align: center;
            font-weight: 600;
            margin: 0;
        }

        p:first-child {
            color: $white!important;
            font-size: 1.5rem;
            line-height: 2.5rem;
            text-align: center;
            font-weight: 600;
            margin-bottom: 1rem;
        }
    }

    form {
        label, legend {
            display: none!important;
        }

        .hs-button {
            background: linear-gradient(-45deg, $midnight, $purple, $red);
            background-size: 400% 400%;
            animation: gradient 14s ease infinite;        
        }
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}