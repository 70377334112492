html {
    margin-top: 0px !important;
}
body {
    position: relative;

    &.edit-post-visual-editor,
    &.mce-content-body {
        margin-top: 0px !important;
        padding: 0 !important;
    }

    $herobg: transparent;
    $columnsbg: transparent;

    &.is-purple-theme {
        header#navigation {
            nav.navbar {
                margin-top: 1rem;
                position: absolute!important;
                width: 100%;
                background-color: transparent !important;
            }

            &.scrolling {
                nav.navbar {
                    background-color: transparent !important;
                }
            }
        }

        section.block-hero, section.customer-hero, section.podcast-hero {
            background-color: $light-purple !important;
        }
        section.block-three-col-icon, section.customer-bio {
            background-color: $ultra-light-purple !important;
        }
    }

    &.is-yellow-theme {
        header#navigation {
            nav.navbar {
                margin-top: 1rem;
                position: absolute!important;
                width: 100%;
                background-color: transparent !important;
            }

            &.scrolling {
                nav.navbar {
                    background-color: transparent !important;
                }
            }
        }

        section.block-hero, section.customer-hero, section.podcast-hero {
            background-color: $bisque !important;
        }
        section.block-three-col-icon, section.customer-bio {
            background-color: $light-bisque !important;
        }
    }

    &.is-sand-theme {
        header#navigation {
            nav.navbar {
                margin-top: 1rem;
                position: absolute!important;
                width: 100%;
                background-color: transparent !important;
            }

            &.scrolling {
                nav.navbar {
                    background-color: transparent !important;
                }
            }
        }

        section.block-hero, section.customer-hero, section.podcast-hero {
            background-color: $grey !important;
        }
        section.block-three-col-icon, section.customer-bio {
            background-color: $light-grey !important;
        }
    }

    &.is-pink-theme {
        header#navigation {
            nav.navbar {
                margin-top: 1rem;
                position: absolute!important;
                width: 100%;
                background-color: transparent !important;
            }

            &.scrolling {
                nav.navbar {
                    background-color: transparent !important;
                }
            }
        }

        section.block-hero, section.customer-hero, section.podcast-hero {
            background-color: $pink !important;
        }
        section.block-three-col-icon, section.customer-bio {
            background-color: $light-pink !important;
        }
    }

    &.is-home-theme {
        header#navigation {
            nav.navbar {
                margin-top: 1rem;
                position: absolute!important;
                width: 100%;
                background-color: transparent !important;
            }

            &.scrolling {
                nav.navbar {
                    background-color: transparent !important;
                }
            }
        }

        section.block-hero, section.customer-hero, section.podcast-hero {
            background-color: $pink !important;
        }
        section.block-three-col-icon, section.customer-bio {
            background-color: $light-pink !important;
        }
    }
}

// Events Pages
.single-events, 
.single-on-demand, 
.single-customer-events, 
.single-customer-on-demand,
.single-podcasts,
.single-ebooks-and-reports {
    &.is-purple-theme {
        background-color: $ultra-light-purple !important;
    }

    &.is-yellow-theme {
        background-color: $light-bisque !important;
    }

    &.is-sand-theme {
        background-color: $ultra-light-grey !important;
    }

    &.is-pink-theme {
        background-color: $light-pink !important;
    }
}

#press-release-hero,
#press-release-content {
    .wp-block-columns {
        max-width: 863px;

        p, ul li {
            font-size: 20px;
            line-height: 1.7;

            .icon {
                margin-top: 6px!important;
            }
        }

        p  {
            margin-bottom: 2rem;
        }
    }
}