div.block-bigsplitcard {
    $radius: 20px;
    .card {
        border-radius: $radius;
        .image {
            border-top-left-radius: $radius;
            border-bottom-left-radius: $radius;
            @include media-breakpoint-down(md) {
                border-top-left-radius: $radius;
                border-top-right-radius: $radius;
                border-bottom-left-radius: 0px !important;
                border-bottom-right-radius: 0px;
            }
            &.cover {
                img {
                    object-fit: cover;
                    border-top-left-radius: $radius;
                    border-bottom-left-radius: $radius;
                    @include media-breakpoint-down(md) {
                        border-top-left-radius: $radius;
                        border-top-right-radius: $radius;
                        border-bottom-left-radius: 0px !important;
                        border-bottom-right-radius: 0px;
                    }
                }
            }
        }
    }
}
