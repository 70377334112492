.about-text-column {
    position: relative;
    z-index: 1;
    width: 835px;
    max-width: 69%;
    flex: 0 0 auto;
    @media screen and (max-width: 991px) {
        width: 100%;
        max-width: 100%;
    }
    @media screen and (max-width: 767px) {
        padding-bottom: 17px;
    }
    @media screen and (max-width: 479px) {
        max-width: 100%;
    }
}

.about-triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 190px 112px 0;
    float: right;
    @media screen and (max-width: 992px) {
        border-width: 0 138px 82px 0;
    }
}

.about-subhead {
    max-width: 474px;
    @media screen and (max-width: 991px) {
        max-width: none;
    }
}