.podcast-hero {

    @include media-breakpoint-up(lg){
        margin-bottom: 0;
    }

    .container {
        height: 80vh;

        @include media-breakpoint-up(lg){
            height: 65vh;
        }

    }

    .lead {
        font-size: 1.5rem;
    }

    .btn {
        svg {
            width: 2rem;
            height: 2rem;
            margin-left: 1rem;
            margin-top: .5rem;
            margin-bottom: .5rem;
        }
        svg path {
            fill: $purple !important;
        }

        &:hover {
            svg path {
                fill: $white !important;
            }
        }
    }

    &__player {
        position: relative;
        width: 100%;
    }
}