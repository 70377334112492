picture {
    height: auto;
    width: 100%;
}

img {
    display: inline-block;
    max-width: 100%;
    height: auto;
}

.background-size-cover {
    background-size: cover;
    background-position: center center;
}

.wp-block-image {
    margin-bottom: 1rem;
}
.wp-block-image {
    display: flex !important;
    flex-direction: column;
}

.wp-block-image.aligncenter,
.wp-block-image .aligncenter {
    align-items: center;
}

.wp-block-image.alignright,
.wp-block-image .alignright {
    align-items: flex-end;
}

.wp-block-image.alignleft,
.wp-block-image .alignleft {
    align-items: flex-start;
}
