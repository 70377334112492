.text-truncate-3 {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.text-truncate-2 {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.text-truncate-1 {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.cursor-pointer {
    cursor: pointer;
}

.centered-content {
    text-align: center;
}

.link-overlay,
.tooltip-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: block;
}

.without-ml {
    display: inline-block;
    margin: 0;
    > a {
        margin: 0 !important;
    }
}

.mt-button-height {
    margin-top: -80px;
    @media only screen and (max-device-width: 450px) {
        margin-top: 0 !important;
    }
    @media screen and (max-width: 991px) {
        margin-top: 0 !important;
    }
    @media screen and (max-width: 767px) {
        margin-top: 0 !important;
    }
    @media screen and (max-width: 376px) {
        margin-top: 0 !important;
    }
}

.text-decoration-none {
    text-decoration: none;
    &.absolute {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }
}

.wp-embedded-content {
    @media screen and (max-width: 767px) {
        width: 100%;
    }
}

.no-mobile {
    @media screen and (max-width: 479px) {
        display: none;
    }
}
// Down
.d-sm-down {
    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.d-md-down {
    @include media-breakpoint-down(md) {
        display: none;
    }
}

.d-lg-down {
    @include media-breakpoint-down(lg) {
        display: none;
    }
}

// Up
.d-sm-up {
    @include media-breakpoint-up(sm) {
        display: none;
    }
}

.d-md-up {
    @include media-breakpoint-up(md) {
        display: none;
    }
}

.d-lg-up {
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

// Order
.order-md-1-down {
    @include media-breakpoint-down(md) {
        order: 1;
    }
}

.order-md-2-down {
    @include media-breakpoint-down(md) {
        order: 2;
    }
}

.order-md-3-down {
    @include media-breakpoint-down(md) {
        order: 3;
    }
}

.order-md-4-down {
    @include media-breakpoint-down(md) {
        order: 4;
    }
}

.order-md-5-down {
    @include media-breakpoint-down(md) {
        order: 5;
    }
}

.order-md-6-down {
    @include media-breakpoint-down(md) {
        order: 6;
    }
}

.svg-container {
    svg {
        width: 100%;
        height: auto;
    }
}

.size-16 {
    width: 16px;
    height: 16px;
}

.size-24 {
    width: 24px;
    height: 24px;
}

.size-32 {
    width: 32px;
    height: 32px;
}

.size-48 {
    width: 48px;
    height: 48px;
}

.size-64 {
    width: 64px;
    height: 64px;
}

.size-96 {
    width: 96px;
    height: 96px;
}

.size-128 {
    width: 128px;
    height: 128px;
}

.size-256 {
    width: 256px;
    height: 256px;
}

.size-512 {
    width: 512px;
    height: 512px;
}

.rotate-0 {
    transform: translate(0, 0) rotate(0deg);
}

.rotate-90 {
    transform: translate(0, 0) rotate(90deg);
}

.rotate-180 {
    transform: translate(0, 0) rotate(180deg);
}

.rotate-270 {
    transform: translate(0, 0) rotate(270deg);
}

.is-rounded {
    border-radius: 2rem;
}