.border-dashed {
    border-style: dashed !important;
}

.border-dotted {
    border-style: dotted !important;
}

.border-groove {
    border-style: groove !important;
}

.border-solid {
    border-style: solid !important;
}
