.modal {
    @media (min-width: 576px) {
        .modal-dialog {
            margin: 1.75rem auto;
            &.more-top-space {
                margin: 5rem auto;
            }
        }
    }
    .modal-dialog {
        width: 90vw;
        height: fit-content;

        &.more-top-space {
            margin: auto;
        }
    }

    .modal-xl {
        //@extend .container-xxl;
        @include media-breakpoint-down(md) {
            padding: 0 !important;
            .modal-content {
                border-radius: 0 !important;
            }
        }
    }

    .close {
        &:hover {
            cursor: pointer;
        }
    }

    &.show {
        @extend .d-flex;
        @extend .justify-content-center;
    }
}
