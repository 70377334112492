.check-icon-row {
    display: flex;
    padding-bottom: 10px;
    align-items: center;
    &.last {
        padding-bottom: 0;
    }
    &.top {
        align-items: flex-start;
    }
}

.product-check-icons {
    padding-top: 30px;
    padding-bottom: 15px;
    @media screen and (max-width: 479px) {
        padding-top: 25px;
    }
}
