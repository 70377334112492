.customer-bio {
    padding-top: 25vh;

    @include media-breakpoint-up(lg){

    }

    &__infos {

    }

    &__numbers {
        border-radius: 10px;
    }

}