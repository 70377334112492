.block-hero {
    position: relative;
    overflow: hidden;


    @include media-breakpoint-up(lg) {
        height: fit-content;
    }

    video.cover {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        object-fit: cover;
        background-color: $grey;
        background-size: cover;
        transition: all 500ms ease;
    }
    .container {
        position: relative;
    }

    &__subtitle {
        @extend .h6;
        opacity: .7;

        @include media-breakpoint-up(md) {
            font-size: $h6-font-size !important;
        }

    }

    .hero-book-form {
        label, legend {
            display: none!important; 
        }
        
        .hs-email {
            float: left;

            input {
                border-radius: 1rem 0 0 1rem;
                border: 2px solid $purple;
                padding-top: .5rem;

                &.invalid.error::placeholder,
                &.invalid.error
                {
                    color: $red;
                }
            }
        }
        .hs-submit {
            float:left;

            .hs-button {
                margin-top: 0!important;
                border-radius: 0 1rem 1rem 0;
                padding-top: 1rem!important;

                &:hover {
                    transform: scale(1);
                }
            }
        }
        .hs-error-msgs {
            display: none;
        }
    }

}

.hero-banner {
    min-height: 156px;
    background-repeat: no-repeat;
    border-radius: 2rem;
    overflow: hidden;
    background-color: $white;
    color: $black;

    .image-holder {
        min-height: 156px;
        background-repeat: no-repeat;
        background-position-y: bottom;
        background-position-x: center;
    }

    @include media-breakpoint-down(md) {
        background: rgb(242,95,92);
        background: linear-gradient(263deg, rgba(242,95,92,1) 0%, rgba(86,68,216,1) 100%);
        color: $white;

        .btn-naked-purple {
            color: $white!important;
        }
    }
} 

@include media-breakpoint-down(lg) {
    .media-side:has(img) {
        background-color: rgba(0,0,0,.05);
        border-radius: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        overflow: hidden;
    }
}

section.template-bff {
    height: 450px;
    position: relative;
    @include media-breakpoint-down(xl) {
        display: flex;
        flex-wrap: wrap;
        height: auto;
    }
    @include media-breakpoint-down(lg) {
        margin-top: 3rem;
    }
    @include media-breakpoint-down(sm) {
        margin-top: 0rem;
    }
    .person {
        position: absolute;
        @include media-breakpoint-down(xl) {
            position: relative;
            flex: 0 0 auto;
            width: 50% !important;
            height: auto !important;
            bottom: auto !important;
            top: auto !important;
            right: auto !important;
            left: auto !important;
            margin-bottom: 1rem;
        }
        .text {
            width: 120px;
            text-align: center;
            font-size: 12px;
            h4 {
                margin-bottom: 0.5rem;
            }
            @include media-breakpoint-down(xl) {
                position: relative !important;
                width: 100%;
                top: auto !important;
                right: auto !important;
                left: auto !important;
                bottom: auto !important;
            }
            @include media-breakpoint-down(sm) {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
        .image {
            width: 170px;
            height: 170px;
            position: relative;
            @include media-breakpoint-down(xxl) {
                width: 130px;
                height: 130px;
            }
            @include media-breakpoint-down(xl) {
                position: relative !important;
                width: 150px;
                height: 150px;
                bottom: auto !important;
                top: auto !important;
                right: auto !important;
                left: 50% !important;
                transform: translateX(-50%);
                margin-bottom: 1rem;
            }
            @include media-breakpoint-down(md) {
                position: relative !important;
                width: 170px;
                height: 170px;
                bottom: auto !important;
                top: auto !important;
                right: auto !important;
                left: 50% !important;
                transform: translateX(-50%);
                margin-bottom: 1rem;
            }
            @include media-breakpoint-down(sm) {
                position: relative !important;
                width: 110px;
                height: 110px;
                bottom: auto !important;
                top: auto !important;
                right: auto !important;
                left: 50% !important;
                transform: translateX(-50%);
                margin-bottom: 1rem;
            }
            img {
                @include media-breakpoint-down(xl) {
                    position: relative !important;
                    width: 100%;
                    height: auto;
                    bottom: auto !important;
                    top: auto !important;
                    right: auto !important;
                    left: auto !important;
                }
            }
            .hover-wrapper {
                overflow: hidden;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 173px;
                height: 173px;
                @include media-breakpoint-down(xxl) {
                    width: 133px;
                    height: 133px;
                }
                @include media-breakpoint-down(xl) {
                    width: 150px;
                    height: 150px;
                }
                @include media-breakpoint-down(lg) {
                    display: none;
                }
                transform: translate(-50%, -50%);
                clip-path: circle(0%);
                transition: 200ms clip-path ease;
                svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    height: 100%;
                }
            }
            &:hover {
                .hover-wrapper {
                    transition: 500ms clip-path cubic-bezier(0.5, 0.25, 0, 1);
                    clip-path: circle(100%);
                }
            }
        }
        &.person-1 {
            top: 70px;
            left: 0px;
            width: 300px;
            height: 188px;
            @include media-breakpoint-down(xxl) {
                width: 240px;
                top: 70px;
                left: 0px;
            }
            .image {
                position: absolute;
                right: 0px;
                top: 0px;
            }
            .text {
                position: absolute;
                left: 0px;
                top: 70px;
            }
        }

        &.person-2 {
            top: 0px;
            right: 0px;
            width: 290px;
            height: 188px;
            @include media-breakpoint-down(xxl) {
                top: 0px;
                right: 0px;
                width: 260px;
                height: 188px;
            }
            .image {
                position: absolute;
                left: 0px;
                top: 0px;
            }
            .text {
                position: absolute;
                right: 0px;
                top: 0px;
            }
        }

        &.person-3 {
            bottom: 0px;
            left: 120px;
            width: 290px;
            height: 188px;
            @include media-breakpoint-down(xxl) {
                width: 230px;
                bottom: 60px;
                left: 100px;
            }
            .image {
                position: absolute;
                right: 0px;
                top: 0px;
            }
            .text {
                position: absolute;
                left: 0px;
                top: 100px;
            }
        }

        &.person-4 {
            bottom: 20px;
            right: 0px;
            width: 170px;
            height: 250px;
            @include media-breakpoint-down(xxl) {
                bottom: 90px;
                right: 20px;
                width: 130px;
                height: 210px;
            }
            .image {
                position: absolute;
                right: 0px;
                top: 0px;
            }
            .text {
                position: absolute;
                left: 27px;
                bottom: 0px;
                @include media-breakpoint-down(xxl) {
                    left: 13px;
                }
            }
        }
    }

    .hands {
        position: absolute;
        top: 110px;
        right: 80px;
        @include media-breakpoint-down(xxl) {
            top: 110px;
            right: 113px;
            svg {
                width: 60px;
                height: auto;
            }
        }
        @include media-breakpoint-down(xl) {
            top: 50px;
            right: auto;
            left: 50%;
            transform: translateX(-50%);
        }
        @include media-breakpoint-down(sm) {
            top: 30px;
            svg {
                width: 50px;
                height: auto;
            }
        }
    }

    .hands-2 {
        position: absolute;
        top: 210px;
        left: 240px;
        @include media-breakpoint-down(xxl) {
            top: 180px;
            left: 190px;
            svg {
                width: 60px;
                height: auto;
            }
        }
        @include media-breakpoint-down(xl) {
            top: 300px;
            right: auto;
            left: 50%;
            transform: translateX(-50%);
        }
        @include media-breakpoint-down(sm) {
            top: 250px;
            svg {
                width: 50px;
                height: auto;
            }
        }
    }
}
