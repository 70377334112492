.table-responsive {
    thead {
        h4 {
            font-size: $font-size-sm;
        }
    }
    td {
        font-size: 0.75rem;
    }
}

table {
    td {
        border-bottom: 0;
    }
}