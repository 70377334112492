.glide {
    position: relative;

    &__slides {
        margin-bottom: 0;

    }

    &__arrows {
        position: absolute;
        top: calc(50% - 27px);
        left: 0;
        width: 100%;
        user-select: none;
    }

    &__arrow {
        position: absolute;
        cursor: pointer;
        z-index: 2;
        appearance: none;
        background: none;
        border: none;


        &--right {
            right: -10px;
        }

        &--left {
            left: -10px;
        }

        svg {
            fill: $midnight;
        }
    }
}
