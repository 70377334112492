.single-solutions {
    $herobg: transparent;
    $columnsbg: transparent;

    &.is-purple-theme {
        header#navigation {
            nav.navbar {
                margin-top: 1rem;
                position: absolute!important;
                width: 100%;
                background-color: transparent !important;
            }

            &.scrolling {
                nav.navbar {
                    background-color: transparent !important;
                }
            }
        }

        section.block-hero {
            background-color: $light-purple;
        }
        section.block-three-col-icon {
            background-color: $ultra-light-purple;
        }
    }

    &.is-yellow-theme {
        header#navigation {
            nav.navbar {
                margin-top: 1rem;
                position: absolute!important;
                width: 100%;
                background-color: transparent !important;
            }

            &.scrolling {
                nav.navbar {
                    background-color: transparent !important;
                }
            }
        }

        section.block-hero {
            background-color: $bisque;
        }
        section.block-three-col-icon {
            background-color: $light-bisque;
        }
    }

    &.is-sand-theme {
        header#navigation {
            nav.navbar {
                margin-top: 1rem;
                position: absolute!important;
                width: 100%;
                background-color: transparent !important;
            }

            &.scrolling {
                nav.navbar {
                    background-color: transparent !important;
                }
            }
        }

        section.block-hero {
            background-color: $grey;
        }
        section.block-three-col-icon {
            background-color: $light-grey;
        }
    }

    &.is-pink-theme {
        header#navigation {
            nav.navbar {
                margin-top: 1rem;
                position: absolute!important;
                width: 100%;
                background-color: transparent !important;
            }

            &.scrolling {
                nav.navbar {
                    background-color: transparent !important;
                }
            }
        }

        section.block-hero {
            background-color: $pink;
        }
        section.block-three-col-icon {
            background-color: $light-pink;
        }
    }

}