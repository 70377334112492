.customer-hero {

    @include media-breakpoint-up(lg){
        margin-bottom: 0;
    }

    h1 {
        font-weight: 900;
    }

    .lead {
        font-size: 1.5rem;
        font-weight: 400;
    }

    &__image {
        position: relative;
        width: 100%;
        height: 20vh;

        @include media-breakpoint-up(lg){
            height: 50vh;
        }

        img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 40vh;
            object-fit: cover;
            border-radius: 10px;

            @include media-breakpoint-up(lg){
                top: 6%;
                height: 112%;
            }
            @include media-breakpoint-up(xl){
                top: 6%;
                height: 120%;
            }
        }
    }
}