section.home-customers {
    figure {
        blockquote {
            position: relative;
            padding: 0;
            margin-top: 40px;
            border: none;

            svg {
                display: block;
                width: 40px;
                position: absolute;
                top: -40px;
                left: 0;
            }
        }

        figcaption {
            span {
                display: block;
            }
        }
    }

    .logo {
        display: block;
        width: 100%;
        height: 50px;
        object-fit: contain;
        filter: grayscale(0%) invert(100%) brightness(2);

        @include media-breakpoint-up(lg) {
            height: 70px;
        }
    }
}

@media (max-width: 1000px) and (min-width: 769px) {
    section.home-customers {
        .container {
            max-width: 100%!important;
        }
    }
}
