.check-icon {
    width: 30px;
    margin-right: 19px;
    &.top {
        margin-top: 5px;
    }
}

svg.duotone {
    fill: $midnight;
}