
.block-pricingcurrencyswitcher {
    position: sticky;
    top: 80px;

    &.is-light {
        .currency {
            background-color: rgba(255,255,255,.5);
            color: $light-bisque;
            transition: all .5s;
            &:hover {
                background-color: rgba(255,255,255,.75);
                color: $purple;
            }
            &.active {
                background-color: $white;
                color: $purple;
            }
            z-index: 100;
            div {
                pointer-events: none;
            }
        }

        .billing {

            input:checked + label {
                background: $bisque;
            }

            label {
                background: $light-bisque;
            }

            span {
                color: $light-bisque;

                &.active {
                    color: $bisque;
                }
            }
        }
    }

    &.is-dark {
        .currency {
            background-color: $ultra-light-purple;
            color: $black;
            transition: all .5s;
            &:hover {
                background-color: $light-purple;
                color: $black;
            }
            &.active {
                background-color: $purple;
                color: $white;
            }
            z-index: 100;
            div {
                pointer-events: none;
            }
        }
    }

    .billing {

        input.switch-billing {
            height: 0;
            width: 0;
            visibility: hidden;
        }

        input:checked + label {
            background: $purple;
        }

        input:checked + label::after {
            left: 100%;
            transform: translateX(-100%);
            background-color: $light-bisque;
        }

        label {
            display: block;
            cursor: pointer;
            text-indent: -9999px;
            width: 70px;
            height: 35px;
            background: $light-purple;
            border-radius: 50px;
            border: 1px solid $purple;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                top: 0px;
                left: 0.5px;
                width: 32.5px;
                height: 32.5px;
                background: #ffffff;
                border-radius: 50px;
                transition: 0.3s;
                border: 2px solid $purple;
            }

            &:active::after {
                width: 60px;
            }
        }

        span {
            transition: all 0.3s ease-in-out;

            &.active {
                font-weight: bold;
                color: $purple;
            }
        }
    }
}
