.single-customers {
    main > article > .wp-block-group {
        @extend .container;

        > * {
            @extend .col-md-10;
            @extend .offset-md-1;
        }

        > p {
            @extend .fs-5;
            font-size: 1.25rem!important;
            line-height: 2rem;
        }
    }
}